import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Timer, AccessTime, Laptop, EditLocation, Description, Payment } from '@material-ui/icons'

import Tab from '../../../components/Home/Tab'
import { TimeTrackingImg, AutomatedPayrollImg, GpsTrackingImg, OnlineTimesheetsImg, ProductivityMonitoringImg, ReportingImg } from '../../../components/Common/Images'
import * as S from './styles'

const tabs = [
  {
    id: 'time_tracking',
    label: 'Time Tracking',
    icon: <Timer />
  },
  {
    id: 'productivity_monitoring',
    label: 'Productivity Monitoring',
    icon: <AccessTime />
  },
  {
    id: 'reporting',
    label: 'Reporting',
    icon: <Laptop />
  },
  {
    id: 'gps_tracking',
    label: 'GPS Tracking',
    icon: <EditLocation />
  },
  {
    id: 'online_timesheets',
    label: 'Online timesheets',
    icon: <Description />
  },
  {
    id: 'automated_payroll',
    label: 'Automated Payroll',
    icon: <Payment />
  }
]

const TabSection = () => {
  const [activeTabId, setActiveTabId] = useState('time_tracking')

  return (
    <S.Container>
      <div className='container'>
        <Tab
          tabs={tabs}
          activeTabId={activeTabId}
          onChangeTab={(id: string) => setActiveTabId(id)}
        />
        <div className='tab-panel-container'>
          <S.TabPanel active={activeTabId === 'time_tracking'}>
            <h1>Easy and accurate time tracking</h1>
            <p>Clean, lightweight apps for desktop, web, mobile, and Chrome. <Link to='#'>Learn more about time tracking.</Link></p>
            <div className='img-container'><img src={TimeTrackingImg} alt='Automated Payroll' /></div>
          </S.TabPanel>
          <S.TabPanel active={activeTabId === 'productivity_monitoring'}>
            <h1>Track and improve employee productivity</h1>
            <p>Keep your team productive without constant check-ins. <Link to='#'>Learn more about productivity monitoring.</Link></p>
            <div className='img-container'><img src={ProductivityMonitoringImg} alt='Productivity Monitoring' /></div>
          </S.TabPanel>
          <S.TabPanel active={activeTabId === 'reporting'}>
            <h1>Detailed reporting for your business</h1>
            <p>Clean,Uncover where time (and money) is being wasted. <Link to='#'> Learn more about reporting.</Link></p>
            <div className='img-container'><img src={ReportingImg} alt='Reporting' /></div>
          </S.TabPanel>
          <S.TabPanel active={activeTabId === 'gps_tracking'}>
            <h1>Smarter GPS tracking</h1>
            <p>Manage your fleet with a simple GPS app. <Link to='#'>Learn more about GPS tracking.</Link></p>
            <div className='img-container'><img src={GpsTrackingImg} alt='GPS Tracking' /></div>
          </S.TabPanel>
          <S.TabPanel active={activeTabId === 'online_timesheets'}>
            <h1>Never chase down timesheets again</h1>
            <p>Simple timesheets that give you more control. <Link to='#'>Learn more about online timesheets.</Link></p>
            <div className='img-container'><img src={OnlineTimesheetsImg} alt='Online Timesheets' /></div>
          </S.TabPanel>
          <S.TabPanel active={activeTabId === 'automated_payroll'}>
            <h1>Automate payroll and invoicing</h1>
            <p>Save yourself hours each week. <Link to='#'>Learn more about automated payroll.</Link></p>
            <div className='img-container'><img src={AutomatedPayrollImg} alt='Automated Payroll' /></div>
          </S.TabPanel>
        </div>
        <div className='monthly-info'>
          <h5>Each month</h5>
          <div className='info-group'>
            <div className='info-item'>
              <h1>70K+</h1>
              <p>Active users</p>
            </div>
            <div className='info-item'>
              <h1>8.5M+</h1>
              <p>Total hours tracked</p>
            </div>
            <div className='info-item'>
              <h1>80K+</h1>
              <p>Tasks completed</p>
            </div>
          </div>
        </div>
      </div>
    </S.Container>
  )
}

export default TabSection
