import React from 'react'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import * as S from './styles'

const Container = styled(Box)`

  h3 {
    font-weight: bold;
    font-size: 20px;
    color: ${props => props.theme.palette.dashboard.textLight};
  }
`

const Integrations = () => {
  return (
    <Container pt={4}>
      <S.TableHeader>
        <Box width='50%'>Organization</Box>
        <Box width='50%'>Integrations</Box>
      </S.TableHeader>
      <Box mt={2} ml={2}>
        <h3>No integrations</h3>
      </Box>
    </Container>
  )
}

export default Integrations