import React from 'react'
import { Rate } from 'antd'

import BlockQuoteCarousel from '../../../components/Home/BlockQuoteCareousel'
import CustomerSayCarousel from '../../../components/Home/CustomerSayCarousel'
import * as S from './styles'
import {
  EncddLogo,
  EncddThumbnailImg,
  FrontRunnerLogo,
  FrontRunnerThumbnailImg,
  LinkupLogo,
  LinkupThumbnailImg,
  TallyfyLogo,
  TallyfyThumbnailImg
} from '../../../components/Common/Images'
import {
  GetAppLogo,
  CapterraLogo,
  CrowdLogo
} from '../../../components/Common/Icons'

const data = [
  {
    id: 1,
    mediaType: 'video',
    mediaSource: TallyfyThumbnailImg,
    quote: <p>We believe - just like TimeGo - that <b>talent is everywhere and people should be able to work any time, anywhere, even in their pajamas</b>. As a company, we believe in the same future of work as TimeGo, which is great because our values align.</p>,
    logo: TallyfyLogo,
    author: 'Amit Kothari',
    job: 'Co-founder of Tallyfy'
  },
  {
    id: 2,
    mediaType: 'image',
    mediaSource: EncddThumbnailImg,
    quote: <p>Knowing how long workers are taking to <b>move from one job to another or exactly where your workers</b> are at all times is vital in construction.</p>,
    logo: EncddLogo,
    author: 'Javier Enciso Jr.',
    job: 'Founder and President of Enc Construction'
  },
  {
    id: 3,
    mediaType: 'image',
    mediaSource: LinkupThumbnailImg,
    quote: <p>TimeGo solved our pain point the moment we started using it. Compared to other tools we'd tested, <b>TimeGo is simple, easy and synchronizes with the other applications we use.</b></p>,
    logo: LinkupLogo,
    author: 'Andriy Sambir',
    job: 'CEO of LinkUp Studio'
  },
  {
    id: 4,
    mediaType: 'video',
    mediaSource: FrontRunnerThumbnailImg,
    quote: <p>Once we implemented TimeGo I was amazed to see that <b>some of the projects I thought were going to be profitable, we were blowing through hours</b> that I wasn't aware of.</p>,
    logo: FrontRunnerLogo,
    author: 'Tyler Mimmons',
    job: 'CEO of Front Runner Films'
  }
]

const customerSayData = [
  {
    id: 1,
    title: 'It does help many companies keep track of their employees.',
    speech: 'As a time tracking, payment management, and employee monitoring software, TimeGo does a great job. It does help many companies keep track of their employees and is pretty comprehensive and even fun to use (when you look at it from the browser).',
    name: 'Benjamin T.'
  },
  {
    id: 2,
    title: 'I always ended up coming back to TimeGo!',
    speech: 'When I started working as a freelancer I tested most of the most famous time tracking software services out there. I always ended up coming back to TimeGo!',
    name: 'Arman S.'
  },
  {
    id: 3,
    title: 'Def helps you stay motivated.',
    speech: 'The app is super stable and on the user end of things, always works as expected. My favorite teature is the productivity score especially as it notifies you via email when your productivity is consistently high. Def helps you stay motivated.',
    name: 'Moseph M.'
  },
  {
    id: 4,
    title: 'It does help many companies keep track of their employees.',
    speech: 'As a time tracking, payment management, and employee monitoring software, TimeGo does a great job. It does help many companies keep track of their employees and is pretty comprehensive and even fun to use (when you look at it from the browser).',
    name: 'Benjamin T.'
  },
  {
    id: 5,
    title: 'I always ended up coming back to TimeGo!',
    speech: 'When I started working as a freelancer I tested most of the most famous time tracking software services out there. I always ended up coming back to TimeGo!',
    name: 'Arman S.'
  },
]

const CustomerSection = () => {
  return (
    <S.Container>
      <div className='container'>
        <div className='customer-stories'>
          <p className='sub-title'>Customer Stories</p>
          <h1>Over 40,000 businesses have increased productivity and profit with TimeGo</h1>
          <BlockQuoteCarousel data={data} />
        </div>
        <div className='customers-say'>
          <h1>What our customers say</h1>
          <CustomerSayCarousel data={customerSayData}/>
        </div>
        <div className='reviews'>
          <S.ReviewItem>
            <div className='logo'><GetAppLogo /></div>
            <p>4.58 out of 5 stars from 1,045 reviews</p>
            <Rate allowHalf defaultValue={4.5} disabled />
          </S.ReviewItem>
          <S.ReviewItem>
            <div className='logo'><CapterraLogo /></div>
            <p>4.6 out of 5 stars from 1,045 reviews</p>
            <Rate allowHalf defaultValue={4.5} disabled />
          </S.ReviewItem>
          <S.ReviewItem>
            <div className='logo'><CrowdLogo /></div>
            <p>4.4 out of 5 stars from 319 reviews</p>
            <Rate allowHalf defaultValue={4.5} disabled />
          </S.ReviewItem>
        </div>
      </div>
    </S.Container>
  )
}

export default CustomerSection
