import React from 'react'
import { Box } from '@material-ui/core'

import { AppPage } from '../../../components/Dashboard/CommonStyled'
import TeamTable from './TeamTable'

const Team = () => {
  return (
    <AppPage>
      <Box mb={2}>
        <h1>Teams</h1>
      </Box>
      <Box>
        <TeamTable />
      </Box>
    </AppPage>
  )
}

export default Team