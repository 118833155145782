import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 430px;

  .card {
    width: 340px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(47,83,151,0.1);
    padding: 30px;

    .profile {
      max-width: 250px;

      p {
        font-size: 13px;
        margin-bottom: 0;

        &.bold {
          font-weight: 600;
        }
      }

      .header {
        display: flex;

        .avatar {
          position: relative;
          margin-right: 10px;

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }

          ::after {
            content: '';
            position: absolute;
            top: 3px;
            right: 0;
            width: 10px;
            height: 10px;
            border: 1px solid white;
            border-radius: 50%;
            background-color: #00d855;
          }
        }

        .user-name {
          margin-top: 5px;
        }
      }

      .body {
        margin-top: 25px;

        .user-info-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;

          .gusto {
            font: 16px "Proxima Nova Bold";
            color: #e57158;
          }
        }
      }
    }

    &:first-child {
      position: absolute;
      left: -100px;
      top: 0;
    }

    &:last-child {
      position: absolute;
      right: 50px;
      bottom: -100px;
    }

    ${(props) => props.theme.breakpoints.down('lg')} {
      width: 300px;

        &:last-child {
          right: -30px;
        }
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      width: 280px;

      &:first-child {
        left: -50px;
      }

      &:last-child {
        right: -30px;
      }
    }
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    height: fit-content;

    .card {
      position: relative !important;
      left: 0 !important;
      bottom: 0 !important;
      width: 100%;
      margin-top: 30px;

      .profile {
        max-width: unset;
      }
    }
  }
`

const ProgressChart = styled.div`
  .label {
    font-size: 12px;
    margin-bottom: 5px;
  }

  span {
    font-size: 12px;
  }

  .percent-label {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }
`

const ProgressBar = styled.div<{value: number}>`
  position: relative;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: #e6e6e8;
  overflow: hidden;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => props.value}%;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props) => props.value > 50 ? '#f87375' : '#17bc66'};
  }
`

export { Container, ProgressChart, ProgressBar }
