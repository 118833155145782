import React from 'react'

import Card from './Card'
import TimesheetImg from '../../../../assets/images/dashboard/timesheet.png'

const TimeSheet = (props) => {
  const { index } = props
  return (
    <Card
      title='Timesheet'
      index={index}
    >
      <div className='card-body'>
        <img src={TimesheetImg} alt='Timesheet' />
        <p>No time tracked today.</p>
      </div>
    </Card>
  )
}

export default TimeSheet