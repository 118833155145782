import styled from 'styled-components'
import { Drawer as MuiDrawer } from '@material-ui/core'
import { Link } from 'react-router-dom'

const Drawer = styled(MuiDrawer)`
	border-right: 0;
	overflow-x: hidden;
  border-right: 1px solid #dddddd;

	> div {
		border-right: 0;
    background: #f2f5f8;
    z-index: 1 !important;
	}
`;

const Logo = styled(Link)`
  padding: 15px 25px 12px;

  svg {
    width: inherit;
    height: 28px;
  }
`

export { Drawer, Logo }