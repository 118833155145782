import React, { FC } from 'react'
import moment from 'moment'
import { addDays } from 'date-fns';
import { Calendar } from 'react-date-range'
import styled from 'styled-components'
import { CalendarToday as CalendarIcon, ArrowForward, ArrowBack } from '@material-ui/icons'
import { Dropdown, Menu } from 'antd'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

export interface IDatePickerProps {
  date: Date
  setDate: (value: Date) => void
  showActionButtons?: boolean
}

const useStyles = makeStyles(() => ({
  dropdown: {
    marginTop: 15,
    width: 'fit-content'
  }
}))

const Container = styled.div`
  display: inline-flex;
  height: 38px;

  .button {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${props => props.theme.colors.gray100};
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    transition: all ease 0.3s;

    svg {
      color: ${props => props.theme.palette.dashboard.textPrimary};
      transition: all ease 0.3s;
      font-size: 20px;
    }

    &:hover {
      svg {
        color: ${props => props.theme.colors.blue50};
      }
    }

    &.disabled {
      background-color: #f4f5f8;
      pointer-events: none;
    }
  }

  .show-date {
    display: flex;
    align-items: center;
    width: 200px;
    border: 1px solid ${props => props.theme.colors.gray100};
    border-radius: 5px;

    .content {
      position: relative;
      display: flex;
      flex: 1;
      align-items: center;
      padding-left: 10px;
      padding-right: 30px;
      user-select: none;
      cursor: pointer;

      span {
        font-size: 13px;
        color: ${props => props.theme.palette.dashboard.textPrimary};
      }

      svg {
        position: absolute;
        right: 10px;
        color: ${props => props.theme.colors.blue50};
        font-size: 17px;
      }
    }

    ${props => props.theme.breakpoints.down(500)} {
      width: fit-content;
    }
  }
`

const DateRange: FC<IDatePickerProps> = ({ date, setDate, showActionButtons }) => {
  const classes = useStyles()

  const onPrev = () => {
    setDate(addDays(date, -1))
  }

  const onNext = () => {
    setDate(addDays(date, 1))
  }

  return (
    <Container>
      {
        showActionButtons &&
        <>
          <div onClick={onPrev} className='button prev'>
            <ArrowBack />
          </div>
          <div onClick={onNext} className={clsx('button next', moment().isSame(date, 'day') && 'disabled')}>
            <ArrowForward />
          </div>
        </>
      }
      <div className='show-date'>
        <Dropdown
          overlay={
            <Menu className={classes.dropdown}>
              <Calendar
                date={date}
                onChange={date => setDate(date)}
              />
            </Menu>
          }
          trigger={['click']}
        >
          <div className="content">
            <span className="date-text">
              {moment(date).format('ddd, MMM DD, YYYY')}
            </span>
            <CalendarIcon fontSize='small'/>
          </div>
        </Dropdown>
      </div>
    </Container>
  )
}

export default DateRange