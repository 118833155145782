import styled from 'styled-components'

const Container = styled.div`
  display: inline-flex;
  border-radius: 30px;
  overflow: hidden;
  background-color: #e9eef5;
  text-align: center;

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const TabButton = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 25px;
  border-radius: 30px;
  background-color: transparent;
  transition: all ease 0.3s;
  cursor: pointer;
  font: 14px 'Proxima Nova Bold';

  svg {
    margin-right: 5px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }

  ${(props) => props.active && `
    background: white;
  `}

  ${(props) => props.theme.breakpoints.down('sm')} {
    white-space: nowrap;
  }
`

export {
  Container,
  TabButton
}
