import React from 'react'
import moment from 'moment-timezone'

import * as S from './styles'

const dailyWorkedTime = [
  {
    id: 1,
    project: 'Frontend',
    from: '7:00',
    to: '9:00',
    color: '#2f53b5'
  },
  {
    id: 2,
    project: 'Backend',
    from: '10:00',
    to: '10:40',
    color: '#9003ff'
  },
  {
    id: 3,
    project: 'Frontend',
    from: '10:40',
    to: '11:00',
    color: '#2f53b5'
  },
  {
    id: 4,
    project: 'Backend',
    from: '11:00',
    to: '12:20',
    color: '#9003ff'
  },
  {
    id: 5,
    project: 'Project design',
    from: '13:05',
    to: '17:02',
    color: '#0fd781',
    showToolTip: true
  },
]

const weeklyWorkedTime = [
  {
    name: 'Mon',
    time: 319
  },
  {
    name: 'Tue',
    time: 420
  },
  {
    name: 'Wed',
    time: 400
  },
  {
    name: 'Thu',
    time: 370
  },
  {
    name: 'Fri',
    time: 0
  },
  {
    name: 'Sat',
    time: 0
  },
  {
    name: 'Sun',
    time: 0
  },
]

interface IProgressChartItem {
  id: number
  project: string
  from: string
  to: string
  color: string
  showToolTip?: boolean
}

const ProgressChartItem = (data: IProgressChartItem) => {
  const from = moment(data.from, 'hh:mm')
  const to = moment(data.to, 'hh:mm')
  const width = to.diff(from, 'minutes') / 720 * 100
  const positionX = from.diff(moment('6:00', 'hh:mm'), 'minutes') / 720 * 100

  return (
    <S.ProgressChartItem
      width={width}
      color={data.color}
      positionX={positionX}
    >
      {
        data.showToolTip &&
        <div className='tooltip'>
          <div className='tooltip-container'>
            <div className='mark'>
              <span>{data.project[0]}</span>
            </div>
            <div className='content'>
              <p className='project-name'>{data.project}</p>
              <p>{from.format('h:mm a')} - {to.format('h:mm a')}</p>
            </div>
          </div>
        </div>
      }
    </S.ProgressChartItem>
  )
}

const WorkedTimeChart = () => {
  return (
    <S.Container>
      <div className='daily-chart'>
        <span className='label'>Worked Today</span>
        <h3 className='time'>7:49:15</h3>
        <div className='chart-wrapper'>
          <div className='chart-basic-line'>
            {
              dailyWorkedTime.map((data) => (
                <ProgressChartItem
                  key={data.id}
                  {...data}
                />
              ))
            }
          </div>
          <div className='time-labels'>
            <span>6am</span>
            <span>12am</span>
            <span>6pm</span>
          </div>
        </div>
      </div>
      <div className='weekly-chart'>
        <span className='label'>Worked This Week</span>
        <h3 className='time'>32:35:20</h3>
        <div className='chart-wrapper'>
          <div className='line-chart'>
            {
              weeklyWorkedTime.map((data) => {
                const height = data.time / 720 * 100
                const workedTimeText = moment('00:00', 'hh:mm').add(data.time, 'm').format('h:mm')
                return (
                  <S.LineChartItem key={data.name}>
                    <span>{data.time > 0 ? workedTimeText : ''}</span>
                    <div className='chart-line-container'>
                      <div className='chart-line-item' style={{height: `${height}%`}} />
                    </div>
                    <span>{data.name}</span>
                  </S.LineChartItem>
                )
              })
            }
          </div>
        </div>
      </div>
    </S.Container>
  )
}

export default WorkedTimeChart
