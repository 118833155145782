import * as types from '../actionTypes'

const initialState = {
  scrollPosY: 0
}

const homeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_SCROLL_POS:
      return {
        ...state,
        scrollPosY: action.payload
      };
    default:
      return state;
  }
}

export default homeReducer
