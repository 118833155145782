import styled from 'styled-components'

const Container = styled.section`
  overflow: hidden;
  position: relative;
  padding-bottom: 50px;

  .container {
    padding-top: 84px;
    padding-bottom: 12px;
    text-align: center;

    .tab-panel-container {
      position: relative;
      min-height: 750px;
    }

    .monthly-info {

      h5 {
        font: 18px 'Proxima Nova Bold';
      }

      .info-group {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 35px;

        .info-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-width: 150px;

          h1 {
            font: 40px 'Proxima Nova Bold';
            margin-bottom: 15px;
          }

          p {
            font: 16px 'Lato-Regular';
          }

          &:nth-child(2) {
            border-left: 1px solid ${props => props.theme.colors.gray100};
            border-right: 1px solid ${props => props.theme.colors.gray100};
          }
        }
      }
    }
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    .tab-panel-container {
      min-height: 630px !important;
    }
  }

  ${(props) => props.theme.breakpoints.down(500)} {
    .tab-panel-container {
      min-height: 500px !important;
    }

    .info-item {
      min-width: 130px !important ;

      h1 {
        font-size: 30px !important;
      }

      p {
        font-size: 14px !important;
      }
    }
  }

  ::before {
    background: #F2F8FF;
    border-radius: 50%;
    content: '';
    display: block;
    height: 200%;
    left: calc(50% - 1500px);
    position: absolute;
    top: calc(-100% - 64px);
    width: 3000px;
    z-index: -1;

    @media screen and (min-width: 992px) {
      height: 1800px;
      left: calc(50% - 2000px);
      top: -1000px;
      width: 4000px;
    }
  }
`

const TabPanel = styled.div<{active: boolean}>`
  padding-top: 50px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all ease 0.3s;
  z-index: 2;

  ${(props) => props.active && `
    opacity: 1;
    z-index: 5;
  `}

  h1 {
    font: 45px 'Proxima Nova Bold';
    margin-bottom: 30px;
    color: ${(props) => props.theme.palette.text.primary};
  }

  p {
    font: 20px 'Lato-Regular';
    margin-bottom: 40px;
    color: ${(props) => props.theme.palette.text.primary};

    a {
      color: ${(props) => props.theme.palette.text.primary};
      text-decoration: underline;
      text-decoration-color: ${(props) => props.theme.colors.blue};

      &:hover {
        color: ${(props) => props.theme.colors.blue};
      }
    }
  }

  .img-container {
    max-width: 850px;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    h1 {
      font-size: 30px;
    }

    p {
      font-size: 16px;
    }
  }
`

export {
  Container,
  TabPanel
}
