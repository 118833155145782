import React, { useState } from 'react'
import { Select as ASelect } from 'antd'
import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Close } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import * as S from './styles'
import Tab from '../../../../components/Dashboard/Tab'
import DatePicker from '../../../../components/Dashboard/DatePicker'
import Select from '../../../../components/Dashboard/Select'
import Button from '../../../../components/Dashboard/Button'
import InsightsIcon from '../../../../assets/images/dashboard/insights-icon.png'
import InsightsLogo from '../../../../assets/images/dashboard/insights-logo.png'
import NoActivity from '../../../../assets/images/dashboard/no-activity.svg'
import UserIcon from '../../../../assets/images/dashboard/user-avatar.png'
import { AppPage, Tabs, TabContainer } from '../../../../components/Dashboard/CommonStyled'
import Input from '../../../../components/Dashboard/Input'

const { Option } = ASelect
const { TabPane } = Tabs

const useStyles = makeStyles(() => ({
  iconButton: {
    '& img': {
      width: 15
    }
  }
}));

const tabs = [
  {
    id: '1',
    label: 'Every 10 min'
  },
  {
    id: '2',
    label: 'All screenshots'
  }
]

const Screenshots = () => {
  const classes = useStyles()
  const theme = useTheme()
  const xxsDown = useMediaQuery(theme.breakpoints.down(450))
  const [activeTabId, setActiveTabId] = useState('1')
  const [date, setDate] = useState(new Date())
  const [filterOpen, setFilterOpen] = useState(false)

  return (
    <AppPage>
      <Box mb={2}>
        <h1>Screenshots</h1>
      </Box>
      <TabContainer>
        <Tab
          tabs={tabs}
          activeTabId={activeTabId}
          onChangeTab={(id: string) => setActiveTabId(id)}
        />
      </TabContainer>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
        flexDirection={['column', 'row']}
        mt={2}
      >
        <Box
          display='flex'
          alignItems={xxsDown ? 'flex-start' : 'center'}
          width={xxsDown ? '100%' : 'fit-content'}
        >
          <DatePicker
            date={date}
            setDate={setDate}
            showActionButtons={true}
          />
          <Box ml={2}>
            <Select defaultValue="cest" bordered={false}>
              <Option value="cest">CEST</Option>
              <Option value="cest1">CEST1</Option>
              <Option value="cest2">CEST2</Option>
            </Select>
          </Box>
        </Box>
        <Box
          display='flex'
          mt={['20px', 0]}
          width={['100%', 'fit-content']}
        >
          <Box
            mr={2}
            flex={1}
          >
            <Select defaultValue="nikita-alexandar">
              <Option value="nikita-alexandar"><img src={UserIcon} alt='Nikita' /> Nikita Alexandar</Option>
            </Select>
          </Box>
          <Button
            className='primary-outlined'
            onClick={() => setFilterOpen(true)}
          >
            Filters
          </Button>
        </Box>
      </Box>
      <Box display='flex' justifyContent='flex-end' mt={3}>
        <IconButton className={classes.iconButton}>
          <img src={InsightsIcon} alt='Insights' />
        </IconButton>
      </Box>
      {
        activeTabId === '1' &&
        <>
          <S.InsightsWrapper>
            <div className='insights-item'>
              <span className='label'>Time</span>
              <div className='info-list'>
                <div className='info-item'>
                  <h1>0:00</h1>
                  <span>Total Worked</span>
                </div>
                <div className='info-item'>
                  <h1>=</h1>
                  <span>To Prev day</span>
                </div>
              </div>
            </div>
            <div className='insights-item'>
              <span className='label'>Activity</span>
              <div className='info-list'>
                <div className='info-item'>
                  <h1>-</h1>
                  <span>Average</span>
                </div>
                <div className='info-item'>
                  <h1>-</h1>
                  <span>To Prev day</span>
                </div>
                <div className='info-item'>
                  <h1>-</h1>
                  <span>To Org avg</span>
                </div>
              </div>
            </div>
            <div className='insights-item'>
              <span className='label'>To-dos</span>
              <div className='info-list'>
                <div className='info-item'>
                  <h1>0</h1>
                  <span>Completed</span>
                </div>
                <div className='info-item'>
                  <h1>=</h1>
                  <span>To Prev day</span>
                </div>
              </div>
            </div>
          </S.InsightsWrapper>
          <S.NoDataContainer>
            <img src={NoActivity} alt='No Activity' />
            <p>There isn't any activity tracked yet for this day. To track time <Link to='#'> use one of our apps.</Link></p>
          </S.NoDataContainer>
        </>
      }
      {
        activeTabId === '2' &&
        <S.NoDataContainer>
          <img src={NoActivity} alt='No Activity' />
          <p>There isn't any activity tracked yet for this day. To track time <Link to='#'> use one of our apps.</Link></p>
        </S.NoDataContainer>
      }
      <S.Drawer
        variant='temporary'
        anchor='right'
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        style={{ zIndex: 99 }}
      >
        <S.DrawerContainer>
          <Tabs defaultActiveKey='1'>
            <TabPane tab='Filters' key='1'>
              <Box mt={2}>
                <Select label='Member' defaultValue='nikita'>
                  <Option value='nikita'>Nikita Alexandar</Option>
                </Select>
              </Box>
              <Box mt={2}>
                <Input
                  label='Project'
                  placeholder='All projects'
                />
              </Box>
              <Box mt={2}>
                <Input
                  label='Time Type'
                  placeholder='All types'
                />
              </Box>
              <Box mt={2}>
                <Input
                  label='Source'
                  placeholder='All sources'
                />
              </Box>
              <Box mt={2}>
                <Select label='Activity level' defaultValue=''>
                  <Option value=''>All levels</Option>
                </Select>
              </Box>
              <Box mt={2}>
                <Button className='full-width'>Clear filters</Button>
              </Box>
            </TabPane>
          </Tabs>
          <IconButton
            className='close-icon'
            size='small'
            onClick={() => setFilterOpen(false)}
          >
            <Close />
          </IconButton>
        </S.DrawerContainer>
      </S.Drawer>
    </AppPage>
  )
}

export default Screenshots