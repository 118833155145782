import React from 'react'
import { ArrowForward, ArrowForwardIos } from '@material-ui/icons'
import { Link } from 'react-router-dom'

import WorkedTimeChart from '../../../components/Home/WorkedTimeChart'
import MessagePopupAnimation from '../../../components/Home/MessagePopupAnimation'
import FindCharts from '../../../components/Home/FindCharts'
import * as S from './styles'
import {
  ProductivityHelpImg,
  ManagementHelpImg,
  FixHelpImg,
  ProductivityIcon,
  ManagementIcon,
  FixIcon,
  IndustryImg,
  SoftwareDevelopmentIcon,
  AgencyIcon,
  AccountantsIcon,
  ConstructionIcon,
  HealthcareIcon,
  EcommerceIcon
} from '../../../components/Common/Images'

const BusinessSection = () => {
  return (
    <S.Container>
      <div className='container'>
        <div className='title'>
          <span>3 Ways TimeGo helps you</span>
          <h1>Run a more profitable<br/> business</h1>
        </div>
        <div className='help-way-list'>
          <div className='help-way-item productivity'>
            <div className='content'>
              <img className='icon' src={ProductivityIcon} alt='Productivity Icon' />
              <h1>Instantly improve productivity</h1>
              <p>It’s simple psychology. When your team tracks time with TimeGo, everyone is more aware of how they're spending each minute of their day. This improves focus and keeps your team on task.</p>
              <div className='divider' />
              <h4>One-minute setup</h4>
              <p>There’s zero learning curve, so your team can start tracking right away.</p>
              <h4>Over 40 integrations</h4>
              <p>Connect TimeGo to your favorite business apps.</p>
              <div className='link'>
                <Link to='/'>More about productivity benchmarks <ArrowForward /></Link>
              </div>
            </div>
            <div className='img-container'>
              <div className='brand-image' style={{ backgroundImage: `url(${ProductivityHelpImg})` }} />
              <div className='chart-container'>
                <WorkedTimeChart />
              </div>
            </div>
          </div>
          <div className='help-way-item row-reverse team-management'>
            <div className='content'>
              <img className='icon' src={ManagementIcon} alt='Management Icon' />
              <h1>Automate team management</h1>
              <p>TimeGo tackles time-draining admin work for you. Like chasing down project updates. Or messing with timesheets. Or ensuring deadlines are met and work stays within budget.</p>
              <div className='divider' />
              <h4>Goodbye check-ins (hello alerts)</h4>
              <p>See what sites and apps they’ve used and get productivity stats.</p>
              <h4>Spot workers who need help</h4>
              <p>Activity reports tell you who is struggling. Optional screenshots show how they’re struggling.</p>
              <div className='link'>
                <Link to='/'>More about employee monitoring <ArrowForward /></Link>
              </div>
            </div>
            <div className='img-container'>
              <div className='brand-image management' style={{ backgroundImage: `url(${ManagementHelpImg})`, height: 730 }} />
              <div className='message-container'>
                <MessagePopupAnimation />
              </div>
            </div>
          </div>
          <div className='help-way-item find-and-fix'>
            <div className='content'>
              <img className='icon' src={FixIcon} alt='Fix Icon' />
              <h1>Find and fix money leaks</h1>
              <p>Is a project or client draining your profit margin? TimeGo will let you know. It gives you reports on where your company's billable hours are going and how that impacts revenue.</p>
              <div className='divider' />
              <h4>Know what to prioritize</h4>
              <p>Reports show you which projects are most profitable and which ones drain your team’s time.</p>
              <h4>Control expenses</h4>
              <p>Track expenses and set weekly budgets or time limits for teams. Get alerts when projects go over.</p>
              <div className='link'>
                <Link to='/'>More about cost management <ArrowForward /></Link>
              </div>
            </div>
            <div className='img-container'>
              <div className='brand-image' style={{ backgroundImage: `url(${FixHelpImg})` }} />
              <div className='chart-container'>
                <FindCharts />
              </div>
            </div>
          </div>
          <div className='help-way-item industry'>
            <div className='content'>
              <h1>Desk work or field work, we’ve got you covered</h1>
              <p>Businesses in more than 100 different industries track time with TimeGo.</p>
              <S.IndustryMenu>
                <div className='menu-item'>
                  <img className='menu-icon' src={SoftwareDevelopmentIcon} alt='Software Development' />
                  <p className='label'>Software development</p>
                  <ArrowForwardIos />
                </div>
                <div className='menu-item'>
                  <img className='menu-icon' src={AgencyIcon} alt='Software Development' />
                  <p className='label'>Agency</p>
                  <ArrowForwardIos />
                </div>
                <div className='menu-item'>
                  <img className='menu-icon' src={AccountantsIcon} alt='Software Development' />
                  <p className='label'>Accountants</p>
                  <ArrowForwardIos />
                </div>
                <div className='menu-item'>
                  <img className='menu-icon' src={ConstructionIcon} alt='Software Development' />
                  <p className='label'>Construction</p>
                  <ArrowForwardIos />
                </div>
                <div className='menu-item'>
                  <img className='menu-icon' src={HealthcareIcon} alt='Software Development' />
                  <p className='label'>Health care</p>
                  <ArrowForwardIos />
                </div>
                <div className='menu-item'>
                  <img className='menu-icon' src={EcommerceIcon} alt='Software Development' />
                  <p className='label'>E-commerce</p>
                  <ArrowForwardIos />
                </div>
              </S.IndustryMenu>
              <div className='link'>
                <Link to='/'>Select your industry <ArrowForward /></Link>
              </div>
            </div>
            <div className='img-container'>
              <div className='brand-image' style={{ backgroundImage: `url(${IndustryImg})` }} />
            </div>
          </div>
        </div>
      </div>
    </S.Container>
  )
}

export default BusinessSection
