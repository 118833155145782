import React from 'react'
import { Button as AButton, ButtonProps } from 'antd'
import styled from 'styled-components'

interface Props extends ButtonProps {

}

const StyledButton = styled(AButton)`
  padding: 7px 22px !important;
  border-radius: 5px !important;
  height: unset !important;
  border: 1px solid ${props => props.theme.colors.gray100} !important;

  span {
    font-weight: bold;
    color: #617083;
    transition: all ease 0.3s;
  }

  &:hover {
    span {
      color: ${props => props.theme.colors.blue50};
    }
  }

  &.primary-outlined {
    border-color: ${props => props.theme.colors.blue50};

    span, svg {
      color: ${props => props.theme.colors.blue50};
    }
  }

  &.primary-contained {
    border-color: ${props => props.theme.colors.blue50};
    background-color: ${props => props.theme.colors.blue50};

    span, svg {
      color: white;
    }

    &:hover {
      opacity: 0.8;
      border-color: ${props => props.theme.colors.blue50};
      background-color: ${props => props.theme.colors.blue50};

      span, svg {
        color: white;
      }
    }

    &:focus {
      background-color: ${props => props.theme.colors.blue50};
    }
  }

  &.full-width {
    width: 100%;
  }
`

const Button = (props: Props) => {
  return (
    <StyledButton {...props} />
  )
}

export default Button