import React from 'react'
import { Close } from '@material-ui/icons'
import { Select as ASelect } from 'antd'

import * as S from '../Activity/Screenshots/styles'
import { Tabs } from '../../../components/Dashboard/CommonStyled'
import { Box, IconButton } from '@material-ui/core'
import Select from '../../../components/Dashboard/Select'
import Input from '../../../components/Dashboard/Input'
import Button from '../../../components/Dashboard/Button'

const { TabPane } = Tabs
const { Option } = ASelect

interface Props {
  open: boolean
  onClose: () => void
}

const FilterSideMenu = ({open, onClose}: Props) => {
  return (
    <S.Drawer
      variant='temporary'
      anchor='right'
      open={open}
      onClose={onClose}
      style={{ zIndex: 99 }}
    >
      <S.DrawerContainer>
        <Tabs defaultActiveKey='1'>
          <TabPane tab='Filters' key='1'>
            <Box mt={2}>
              <Select label='Member' defaultValue='nikita'>
                <Option value='nikita'>Nikita Alexandar</Option>
              </Select>
            </Box>
            <Box mt={2}>
              <Input
                label='Project'
                placeholder='All projects'
              />
            </Box>
            <Box mt={2}>
              <Input
                label='Time Type'
                placeholder='All types'
              />
            </Box>
            <Box mt={2}>
              <Input
                label='Source'
                placeholder='All sources'
              />
            </Box>
            <Box mt={2}>
              <Select label='Activity level' defaultValue=''>
                <Option value=''>All levels</Option>
              </Select>
            </Box>
            <Box mt={2}>
              <Button className='full-width'>Clear filters</Button>
            </Box>
          </TabPane>
        </Tabs>
        <IconButton
          className='close-icon'
          size='small'
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </S.DrawerContainer>
    </S.Drawer>
  )
}

export default FilterSideMenu