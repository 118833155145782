import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { Info } from '@material-ui/icons'

import * as S from './styles'
import Tab from '../../../../components/Dashboard/Tab'
import DateRange from '../../../../components/Dashboard/DateRange'
import Button from '../../../../components/Dashboard/Button'
import Select, { Option } from '../../../../components/Dashboard/Select'
import Members from './Memebers'
import WeekSchedule from './Week'
import { TabContainer } from '../../../../components/Dashboard/CommonStyled'

const useStyles = makeStyles(() => ({
  multiSelect: {
    '& .ant-select-selector': {
      paddingLeft: '5px !important'
    }
  }
}))

const tabs = [
  {
    id: '1',
    label: 'Members'
  },
  {
    id: '2',
    label: 'Week'
  },
  {
    id: '3',
    label: 'Day'
  },
]

function tagRender(props) {
  const { label, closable, onClose } = props;

  return (
    <S.Tag closable={closable} onClose={onClose}>
      {label}
    </S.Tag>
  );
}

const Calendar = () => {
  const classes = useStyles()
  const [activeTabId, setActiveTabId] = useState('1')
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate()
  })

  return (
    <S.Container>
      <Box mb={2}>
        <h1>Schedules</h1>
      </Box>
      <TabContainer>
        <Tab
          tabs={tabs}
          activeTabId={activeTabId}
          onChangeTab={(id: string) => setActiveTabId(id)}
        />
      </TabContainer>
      <Box className='filter'>
        <Box className='date-range' display='flex' alignItems='center'>
          <DateRange
            showActionButtons={true}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
          <Box ml='10px'>
            <Button className='outlined'>Today</Button>
          </Box>
        </Box>
        <Box display='flex'>
          <Box width='300px'>
            <Select label='Timezone' defaultValue='my-time-zone'>
              <Option value='my-time-zone'>My time zone (Europe - Berlin)</Option>
            </Select>
          </Box>
          <Box width='300px' ml='10px'>
            <Select
              label='Members'
              defaultValue='nikita'
              mode='multiple'
              showArrow
              tagRender={tagRender}
              className={classes.multiSelect}
            >
              <Option value='nikita'>Nikita Alexandar</Option>
              <Option value='harry'>Harry Potter</Option>
            </Select>
          </Box>
        </Box>
      </Box>
      {
        activeTabId === '1' &&
          <Box mt={5} overflow='auto'>
            <Members dateRange={dateRange}/>
          </Box>
      }
      {
        activeTabId === '2' &&
        <Box overflow='auto'>
          <Box display='flex' justifyContent='flex-end'>
            <div className='max-member'>
              <Info />
              <span>A max of 20 members are shown in this view</span>
            </div>
          </Box>
          <Box mt={5}>
            <WeekSchedule dateRange={dateRange}/>
          </Box>
        </Box>
      }
      {
        activeTabId === '3' &&
        <>
          <Box display='flex' justifyContent='flex-end'>
            <div className='max-member'>
              <Info />
              <span>A max of 20 members are shown in this view</span>
            </div>
          </Box>
          <Box mt={5}>
            <div className='no-events'>
              <span>No events to display</span>
            </div>
          </Box>
        </>
      }
    </S.Container>
  )
}

export default Calendar