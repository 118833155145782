import React from 'react'
import { Box } from '@material-ui/core'
import { Col, Row } from 'antd'

import * as S from './styles'
import TimeOffPolicyImg from '../../../../assets/images/dashboard/time-off-policy.svg'
import PublicHolidayImg from '../../../../assets/images/dashboard/public-holiday.svg'

const TimeOffRequests = () => {
  return (
    <S.Container>
      <Box mb={2}>
        <h1>Time off requests</h1>
      </Box>
      <Box mt={7}>
        <Row>
          <Col xs={24} sm={12}>
            <Box display='flex' justifyContent='center'>
              <div className='card'>
                <img src={TimeOffPolicyImg} alt='Time Off Policy' />
                <h3>Time off policy</h3>
                <p>Time off policies can be used for vacations, sic days, or whatever else works best for the learn</p>
              </div>
            </Box>
          </Col>
          <Col xs={24} sm={12}>
            <Box display='flex' justifyContent='center'>
              <div className='card'>
                <img src={PublicHolidayImg} alt='Public Holiday' />
                <h3>Public holiday</h3>
                <p>Holidays can be added to the schedule calendar to give team members the day off</p>
              </div>
            </Box>
          </Col>
        </Row>
      </Box>
      <Box display='flex' justifyContent='center' mt={5}>
        <Box maxWidth='280px' textAlign='center'>
          <p>An organization owner or manager needs to get up time off policies and holidays for the team.</p>
        </Box>
      </Box>
    </S.Container>
  )
}

export default TimeOffRequests