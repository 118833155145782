import React from 'react'
import { DownOutlined } from '@ant-design/icons'
import { Box, ClickAwayListener, Fade } from '@material-ui/core'

import { MenuButton, SubMenuContainer } from '../styles'
import * as S from './styles'
import { QuestionMarkIcon } from '../../../Common/Images'

interface Props {
  active: boolean
  setActive: (menu: string) => void
}

const menu = [
  {
    title: 'What TimeGo tracks',
    description: 'Transparency, control and insightful data.'
  },
  {
    title: 'Integrations',
    description: 'Working together with your favorite tools.'
  },
  {
    title: 'Customer stories',
    description: 'See how teams succeed in these case studies.'
  },
  {
    title: 'Reviews',
    description: 'What customers love about TimeGo.'
  },
  {
    title: 'Our blog',
    description: 'Tools and resources for managing teams.'
  },
  {
    title: 'About TimeGo',
    description: 'The story and team behind our company.'
  }
]

const WhyHubstaffMenu = ({ active, setActive }: Props) => {

  return (
    <Box position='relative'>
      <MenuButton onClick={() => setActive(!active ? 'why_hubstaff' : '')} isOpen={active}>
        <span>Why TimeGo?</span>
        <DownOutlined />
      </MenuButton>
      <Fade in={active}>
        <Box>
          {
            active &&
            <ClickAwayListener onClickAway={() => setActive('')}>
              <SubMenuContainer left={-10}>
                <S.Body>
                  {
                    menu.map((item) => (
                      <S.MenuItem key={item.title}>
                        <h3>{ item.title }</h3>
                        <p>{ item.description }</p>
                      </S.MenuItem>
                    ))
                  }
                </S.Body>
                <S.Footer>
                  <Box display='flex' alignItems='flex-end'>
                    <img src={QuestionMarkIcon} alt='question-icon' />&nbsp;&nbsp;<h3>24/7 Support</h3>
                  </Box>
                  <p>Readily available help and guidance.</p>
                </S.Footer>
              </SubMenuContainer>
            </ClickAwayListener>
          }
        </Box>
      </Fade>
    </Box>
  )
}

export default WhyHubstaffMenu
