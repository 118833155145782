import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { Input } from 'antd'

import { AppPage } from '../../../../../components/Dashboard/CommonStyled'

const { TextArea } = Input

// @ts-ignore
const Container = styled(AppPage)`

  .form-control {
    display: flex;
    flex-direction: column;
    min-width: 300px;

    .label {
      font-size: 11px;
      color: ${props => props.theme.palette.dashboard.textLight};
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    .show-date {
      width: 100%;
    }
  }

  ${props => props.theme.breakpoints.down('xs')} {
    .filter {
      .form-control {
        min-width: unset;
        flex: 1;
        max-width: 50%;
      }
    }
  }

  ${props => props.theme.breakpoints.down(500)} {
    .filter {
      > div:not(:first-child) {
        flex-direction: column;

        > div:first-child {
          margin-bottom: 16px;
        }

        .form-control {
          margin-right: 0;
          max-width: 100%;
        }
      }
    }

    .button-group {
      flex-direction: column;

      button {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    .add-btn-group {
      button {
        flex: 1;
      }
    }
  }
`

const StyledTextArea = styled(TextArea)`
  border: 1px solid ${props => props.theme.colors.gray100} !important;
  border-radius: 5px !important;
  padding: 7px 11px !important;
`

const InfoList = styled(Box)`
  min-width: 490px;

  .info-item {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-top: 1px solid ${props => props.theme.colors.gray100};

    .label {
      width: 150px;
      font-size: 16px;
      font-weight: bold;
    }

    .content {
      flex: 1;
      font-size: 16px;
      font-weight: bold;
      color: ${props => props.theme.palette.dashboard.textPrimary};

      input {
        margin-bottom: 20px;
      }
    }

    &:first-child {
      border-top: none;
    }
  }

  ${props => props.theme.breakpoints.down('xs')} {
    width: 100%;
    min-width: unset;
  }
`

export { Container, StyledTextArea, InfoList }