import React from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'

const Container = styled.div`
  .basic-line {
    position: relative;
    width: 100%;
    height: 17px;
    border-radius: 10px;
    background-color: #dfe6f1;
  }

  .time-labels {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 7px;

    span {
      font-size: 10px;
    }
  }
`

const DurationItem = styled.div<{ positionX: number, width: number }>`
  position: absolute;
  top: 0;
  left: ${(props) => props.positionX || 0}%;
  width: ${(props) => props.width}%;
  height: 100%;
  background-color: #afbbca;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  ::before {
    position: absolute;
    left: 0;
    top: -3px;
    height: calc(100% + 6px);
    width: 1px;
    background-color: #afbbca;
    content: '';
  }
`

const dailyWorkedTime = [
  {
    id: 1,
    from: '20:00',
    to: '24:00'
  }
]

interface IProgressChartItem {
  id: number
  from: string
  to: string
}

const WorkedItemItem = (data: IProgressChartItem) => {
  const from = moment(data.from, 'hh:mm')
  const to = moment(data.to, 'hh:mm')
  const width = to.diff(from, 'minutes') / 1440 * 100
  const positionX = from.diff(moment('0:00', 'hh:mm'), 'minutes') / 1440 * 100

  return <DurationItem width={width} positionX={positionX} />
}

const TimeLine = () => {
  return (
    <Container>
      <div className='basic-line'>
        {
          dailyWorkedTime.map((data) => (
            <WorkedItemItem
              key={data.id}
              {...data}
            />
          ))
        }
      </div>
      <div className='time-labels'>
        <span> </span>
        <span>6am</span>
        <span>12am</span>
        <span>6pm</span>
        <span> </span>
      </div>
    </Container>
  )
}

export default TimeLine