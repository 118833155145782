import React from 'react'
import styled from 'styled-components'
import { Dropdown, Menu } from 'antd'
import { MoreVert, InfoOutlined } from '@material-ui/icons'
import { Box, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTheme } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc'

import { getWidgets } from '../../../store/selectors'
import { setWidgets } from '../../../store/actions/dashboard.actions'
import { IconButton } from './styles'

const useStyles = makeStyles(() => ({
  dropdown: {
    '& .ant-dropdown-menu-item': {
      padding: '8px 16px'
    }
  }
}));

const Container = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.gray100};
  border-radius: 3px;
  margin-bottom: 24px;
  background-color: white;

  ${props => props.theme.breakpoints.down('sm')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  ${props => props.theme.breakpoints.down(500)} {
    grid-template-columns: 1fr;
  }
`

const InfoItem = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  padding: 20px;
  background: white;

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .indicator {
      width: 80px;
      height: 2px;
    }
  }

  ::after {
    position: absolute;
    right: 0;
    top: 0;
    content: '';
    width: 1px;
    height: calc(100% - 40px);
    background-color: ${props => props.theme.colors.gray100};
    margin: 20px 0;
  }

  &:nth-child(2n) {
    .indicator {
      background-color: #1dccff;
    }
  }

  &:nth-child(2n+1) {
    .indicator {
      background-color: #6c5dff;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    ::before {
      position: absolute;
      right: 0;
      top: 0;
      content: '';
      width: calc(100% - 40px);
      height: 1px;
      background-color: ${props => props.theme.colors.gray100};
      margin: 0 20px;
    }

    &:first-child, :nth-child(2) {
      &::before {
        display: none;
      }
    }

    :nth-child(2n) {
      &::after {
        display: none;
      }
    }
  }

  ${props => props.theme.breakpoints.down(500)} {
    :nth-child(2n) {
      &::before {
        display: block;
      }

      &::after {
        display: none;
      }
    }
  }
}`

const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: -10px;

  .title {
    font-size: 11px;
    text-transform: uppercase;
    color: ${props => props.theme.palette.dashboard.textLight};

    svg {
      font-size: 11px;
      margin-right: -10px;
    }
  }
`

const data = [
  {
    name: 'Earned this week',
    value: '$0.00',
    showTooltip: true
  },
  {
    name: 'Earned today',
    value: '$0.00',
    showTooltip: true
  },
  {
    name: 'Projects worked',
    value: '0',
  },
  {
    name: 'Today\'s activity',
    value: '-',
    showTooltip: true
  },
  {
    name: 'Weekly activity',
    value: '-',
    showTooltip: true
  },
  {
    name: 'Worked this week',
    value: '0.0',
  },
  {
    name: 'Worked today',
    value: '0.0',
  }
]

const SortContainer = SortableContainer(props => <Container {...props} />)
const SortItem = SortableElement(props => <InfoItem {...props} />)
const DragHandle = SortableHandle(props => <Header {...props} />)

const SmallWidgetList = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const widgets = useSelector(getWidgets)
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const isXsDown = useMediaQuery(theme.breakpoints.down(500))
  const classes = useStyles()

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const emptyArray = [] as string[]
      const newData = arrayMove(emptyArray.concat(widgets.smallWidgets), oldIndex, newIndex).filter(el => !!el);
      const updatedWidgets = {...widgets, smallWidgets: newData}
      dispatch(setWidgets(updatedWidgets));
    }
  }

  const DraggableContainer = props => (
    <SortContainer
      axis={isXsDown ? 'y' : isSmDown ? 'xy' : 'x'}
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  )

  const handleRemove = (index: number) => {
    const smallWidgets = [...widgets.smallWidgets]
    smallWidgets.splice(index, 1)
    dispatch(setWidgets({...widgets, smallWidgets}))
  }

  return (
    <DraggableContainer>
      {
        widgets.smallWidgets.map((widget, index) => {
          const item = data.find(item => item.name === widget)
          return (
            <SortItem index={index} key={index}>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <DragHandle>
                  <span className='title'>
                    {item?.name} {item?.showTooltip && <InfoOutlined />}
                  </span>
                </DragHandle>
                <Dropdown
                  overlay={
                    <Menu className={classes.dropdown}>
                      <Menu.Item onClick={() => handleRemove(index)}>
                        Remove
                      </Menu.Item>
                    </Menu>
                  }
                  placement='bottomRight'
                  trigger={['click']}
                >
                  <IconButton size='small'><MoreVert /></IconButton>
                </Dropdown>
              </Box>
              <div className='body'>
                <h1>{item?.value}</h1>
              </div>
              <div className='footer'>
                <span>-</span>
                <div className='indicator' />
              </div>
            </SortItem>
          )
        })
      }
    </DraggableContainer>
  )
}

export default SmallWidgetList