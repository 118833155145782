import React from 'react'
import { Table, Tag } from 'antd'
import styled from 'styled-components'
import { Box } from '@material-ui/core'

import AsanaIcon from '../../../../assets/images/dashboard/asana-icon.png'
import { TableContainer } from '../../../../components/Dashboard/CommonStyled'
import TableActionMenu from '../../../../components/Dashboard/TableActionMenu'

interface DataType {
  key: React.Key
  name: string
  teams: string[]
  members: string
  budget: string
  track: string
  status: string
  icon?: string | React.ReactNode
}

const TeamList = styled.div`
  display: flex;

  .team {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #549a58;
    color: white;
    margin-right: 10px;
  }
`

const Name = styled.div`
  display: flex;
  align-items: center;
  color: #53aaff;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    background-color: #82b55e;
    margin-right: 10px;
    text-transform: uppercase;
  }

  img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name, row) => <Name>
      <div className='avatar'>
        {name[0]}
      </div>
      {name}
      { row.icon &&
        <img src={row.icon} alt={name} />
      }
    </Name>
  },
  {
    title: 'Teams',
    dataIndex: 'teams',
    render: teams => (
      <TeamList>
        { teams.map((team) => (
            <div className='team' key={team}>{team[0]}</div>
          ))
        }
      </TeamList>
    )
  },
  {
    title: 'Members',
    dataIndex: 'members',
  },
  {
    title: 'Budget',
    dataIndex: 'budget',
  },
  {
    title: 'Trackable by me',
    dataIndex: 'track',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: status => <Tag color="green-inverse">{status}</Tag>
  },
  {
    title: '',
    key: 'operation',
    width: 100,
    render: () => <TableActionMenu />
  }
]

const data: DataType[] = [
  {
    key: '1',
    name: 'Arbitage - BE',
    icon: AsanaIcon,
    teams: ['A'],
    members: '',
    budget: '',
    track: '',
    status: 'Active',
  },
  {
    key: '2',
    name: 'Arbitage - UI',
    icon: AsanaIcon,
    teams: ['B'],
    members: '',
    budget: '',
    track: '',
    status: 'Active',
  },
  {
    key: '3',
    name: 'backend-missions',
    icon: AsanaIcon,
    teams: ['A', 'B'],
    members: '',
    budget: '',
    track: '',
    status: 'Active',
  },
  {
    key: '4',
    name: 'DB Admin Utility',
    icon: AsanaIcon,
    teams: ['A'],
    members: '',
    budget: '',
    track: '',
    status: 'Active',
  },
  {
    key: '5',
    name: 'z_Dormant EH (Do Not Use)',
    teams: ['S', 'A'],
    members: '',
    budget: '',
    track: '',
    status: 'Active',
  },
]

const ApprovalTable = () => {
  return (
    <TableContainer>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{x: 'max-content'}}
      />
      <Box mt={2}>
        <p>Showing 5 of 5 projects</p>
      </Box>
    </TableContainer>
  )
}

export default ApprovalTable