import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'
import {
  authLayoutRoutes,
  landingLayoutRoutes,
  dashboardLayoutRoutes,
} from './Routes'

import LandingLayout from '../layouts/LandingLayout'
import AuthLayout from '../layouts/AuthLayout'
import DashboardLayout from '../layouts/DashboardLayout'
import ProtectedRoute from './ProtectedRoute'

const childRoutes = (routeType, Layout, routes) =>
  routes.map(({ component: Component, guard, children, path }, index) => {
    const Guard = guard || React.Fragment

    return children ? (
      children.map((element, index) => {
        const Guard = element.guard || React.Fragment
        const ElementComponent = element.component || React.Fragment

        if (routeType === 'private') {
          return (
            <ProtectedRoute
              key={index}
              path={element.path}
              exact
              Guard={Guard}
              Layout={Layout}
              component={ElementComponent}
            />
          )
        }
        return (
          <Route
            key={index}
            path={element.path}
            exact
            render={(props) => (
              <Layout>
                <Guard>
                  <ElementComponent {...props} />
                </Guard>
              </Layout>
            )}
          />
        )
      })
    ) : Component ? (
      (routeType === 'private') ? (
        <ProtectedRoute
          key={index}
          path={path}
          exact
          Guard={Guard}
          Layout={Layout}
          component={Component}
        />
      ) : (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Guard>
                <Component {...props} />
              </Guard>
            </Layout>
          )}
        />
      )
    ) : null
  })

const Routes = () => (
  <Router>
    <Switch>
      {childRoutes('public', AuthLayout, authLayoutRoutes)}
      {childRoutes('public', LandingLayout, landingLayoutRoutes)}
      {childRoutes('private', DashboardLayout, dashboardLayoutRoutes)}
    </Switch>
  </Router>
)

export default Routes
