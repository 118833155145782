import React from 'react'
import { Table, Tag } from 'antd'
import moment from 'moment'
import styled from 'styled-components'
import { User } from 'react-feather'
import { Box } from '@material-ui/core'

import { TableContainer } from '../../../../components/Dashboard/CommonStyled'
import TableActionMenu from '../../../../components/Dashboard/TableActionMenu'

interface DataType {
  key: React.Key
  name: string
  pay_period: {
    start: string,
    to: string
  }
  total: number
  activity: string
  status: string
  submitted_on: string | undefined,
}

const ActivityView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e1e4e8;
  color: white;
`

const Member = styled.div`
  display: flex;
  align-items: center;
  color: #53aaff;

  .avatar {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    background-color: #53aaff;
    margin-right: 10px;

    svg {
      color: white;
      width: 20px;
      height: 20px;
    }
  }
`

const columns = [
  {
    title: 'Member',
    dataIndex: 'name',
    render: name => <Member><div className='avatar'><User /></div> {name}</Member>
  },
  {
    title: 'Pay period',
    dataIndex: 'pay_period',
    render: period => (
      <span>{moment(period.start).format('ddd, MMM DD, YYYY ')} - {moment(period.end).format('ddd, MMM dd, YYYY ')}</span>
    )
  },
  {
    title: 'Total',
    dataIndex: 'total',
    render: total => <span>{total.toFixed(1)}</span>
  },
  {
    title: 'Activity',
    dataIndex: 'activity',
    render: activity => (
      <ActivityView>{activity || '-'}</ActivityView>
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: status => <Tag color="gold-inverse">{status}</Tag>
  },
  {
    title: 'Submitted on',
    dataIndex: 'submitted_on',
    render: submitted_on => <span>{submitted_on ? moment(submitted_on).format('ddd, MMM DD, YYYY ') : '-'}</span>
  },
  {
    title: '',
    key: 'operation',
    width: 100,
    render: () => <TableActionMenu />
  }
]

const data: DataType[] = [
  {
    key: '1',
    name: 'Nikita Alexandar',
    pay_period: {
      start: '2021-9-13',
      to: '2021-9-19'
    },
    total: 0.0,
    activity: '',
    status: 'Open',
    submitted_on: undefined,
  },
  {
    key: '2',
    name: 'Nikita Alexandar',
    pay_period: {
      start: '2021-9-13',
      to: '2021-9-19'
    },
    total: 0.0,
    activity: '',
    status: 'Open',
    submitted_on: undefined,
  },
  {
    key: '3',
    name: 'Nikita Alexandar',
    pay_period: {
      start: '2021-9-13',
      to: '2021-9-19'
    },
    total: 0.0,
    activity: '',
    status: 'Open',
    submitted_on: undefined,
  },
]

const ApprovalTable = () => {
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({
      name: record.name,
    }),
  };

  return (
    <TableContainer>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{x: 'max-content'}}
      />
      <Box mt={2}>
        <p>Showing 6 of 6 timesheets</p>
      </Box>
    </TableContainer>
  )
}

export default ApprovalTable