import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { Button as AButton } from 'antd'

import { Button, OutlinedButton } from '../../Common/Button'

const HeaderSection = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 85px;
  z-index: 10 !important;
  transition: background-color ease-in-out 0.3s;
  background-color: white;
  border-bottom: 1px solid ${props => props.theme.colors.gray100};
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 85px;

  .right {
    display: flex;
    align-items: center;
    padding-right: 35px;

    ${(props) => props.theme.breakpoints.down('lg')} {
      padding-right: 0;

      .free-trial-button {
        display: none;
      }
    }
  }
`

const LinkButton = styled(Link)`
  padding: 5px 15px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.dark};
  font-weight: 600;
  font-size: 16px;

  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: 5px;
    font-size: 15px;
  }
`

const MenuButton = styled.div<{isOpen: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  cursor: pointer;
  user-select: none;

  span {
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.dark};
    margin-right: 5px;
    transition: all ease 0.3s;

    ${(props) => props.isOpen && `
      color: ${props.theme.colors.blue};
    `}
  }

  svg {
    color: ${(props) => props.theme.colors.blue};
    transition: all ease 0.3s;
    width: 14px;
    height: 14px;

    ${(props) => props.isOpen && `
      transform: rotate(180deg);
    `}
  }

  &:hover {
    span {
      color: ${(props) => props.theme.colors.blue};
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: 5px;

    span {
      font-size: 15px;
    }
  }
`

const SubMenuContainer = styled(Box)`
  position: absolute;
  top: 45px;
  left: 0;
  box-shadow: 0 0 10px 5px rgba(47,83,151,0.1);
  background-color: white;

  &:after {
    content: '';
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: -10px;
    left: 80px;
  }
`

const FreeTrialButton = styled(Button)`
  width: 180px;
`

const SignInButton = styled(OutlinedButton)`
  width: 135px;
`

const MobileContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
`

const MobileMenuButton = styled(AButton)`
  background: transparent !important;
  border: none !important;
  padding: 0 !important;

  svg {
    fill: ${props => props.theme.colors.dark};
    width: 25px;
    height: 25px;
    transition: 0.2s;

    &:hover {
      fill: ${props => props.theme.colors.blue};
    }
  }
`

export {
  HeaderSection,
  Content,
  LinkButton,
  MenuButton,
  SubMenuContainer,
  FreeTrialButton,
  SignInButton,
  MobileContainer,
  MobileMenuButton
}
