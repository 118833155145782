import styled from 'styled-components'

import { AppPage } from '../../../../components/Dashboard/CommonStyled'

// @ts-ignore
const Container = styled(AppPage)`
  .card {
    max-width: 280px;
    text-align: center;

    img {
      height: 260px;
    }

    h3 {
      font-weight: bold;
      margin: 15px 0 10px;
      color: ${props => props.theme.palette.dashboard.textDark};
    }
  }

  p {
    font-size: 12px;
  }
`

export { Container }