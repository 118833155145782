import styled, { keyframes } from 'styled-components'

const growAnimation = keyframes`
  0% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(0);
  }
  52% {
    transform: scaleY(1);
  }
  93% {
    transform: scaleY(1);
  }
  95% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(0);
  }
`;

const shrinkAnimation = keyframes`
  0% {
    transform: scaleY(1);
  }
  48% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0);
  }
  95% {
    transform: scaleY(0);
  }
  97% {
    transform: scaleY(1);
  }
`;

const Container = styled.div`
  position: relative;
  width: 560px;
  height: 150px;

  .daily-chart {
    animation: ${shrinkAnimation} 8s;
    animation-iteration-count: infinite;
  }

  .weekly-chart {
    animation: ${growAnimation} 8s;
    animation-iteration-count: infinite;

    .line-chart {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
    }
  }

  > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(47,83,151,0.1);
    padding: 10px 20px 15px;

    ${(props) => props.theme.breakpoints.down('md')} {
      width: 90%;
      left: 50px;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
      width: 90%;
      left: 5%;
    }
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }

  span.label {
    font-size: 11px;
    text-transform: uppercase;
  }

  h3.time {
    font-size: 24px;
    line-height: 1;
    margin: 0;
  }

  .chart-wrapper {
    height: 60px;
    max-width: 430px;
    width: 100%;

    .chart-basic-line {
      position: relative;
      width: 100%;
      height: 10px;
      border-radius: 10px;
      background-color: ${props => props.theme.colors.gray100};
      margin-top: 50px;
    }

    .time-labels {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 7px;

      span {
        font-size: 10px;
      }
    }
  }
`
interface IProgressChartItem {
  width: number
  color: string
  positionX: number
}

const ProgressChartItem = styled.div<IProgressChartItem>`
  position: absolute;
  top: 0;
  left: ${(props) => props.positionX || 0}%;
  width: ${(props) => props.width}%;
  height: 100%;
  background-color: ${(props) => props.color};
  border-radius: 10px;

  .tooltip {
    position: relative;
    width: 100%;
    height: 100%;

    .tooltip-container {
      position: absolute;
      left: calc(50% - 60px);
      bottom: 20px;
      width: 120px;
      height: 40px;
      display: flex;
      background-color: white;
      box-shadow: 0 2px 6px rgba(47,83,151,0.1);
      border-radius: 5px;
      padding: 5px;

      .mark {
        width: 15px;
        height: 15px;
        background-color: ${(props) => props.color};
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-size: 10px;
          line-height: 1;
          text-transform: capitalize;
          color: white;
        }
      }

      .content {
        flex: 1;
        margin-left: 5px;

        .project-name {
          font-weight: 600;
        }

        p {
          font-size: 10px !important;
          font-weight: 500;
          margin-bottom: 0 !important
        }
      }

      ::after {
        content: '';
        border-top: 5px solid white;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        bottom: -5px;
        left: calc(50% - 5px);
      }
    }
  }
`

const LineChartItem = styled.div`
  width: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .chart-line-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    background-color: white;

    .chart-line-item {
      width: 100%;
      min-height: 2px;
      background-color: #2284df;
    }
  }

  span {
    font-size: 10px;
    margin-top: 5px;
  }
`

export { Container, ProgressChartItem, LineChartItem }
