import styled from 'styled-components'
import { Box, IconButton as MuiIconButton } from '@material-ui/core'

import { AppPage } from '../../../components/Dashboard/CommonStyled'

// @ts-ignore
const Container = styled(AppPage)`
  width: 100%;
  min-height: 100%;
  background-color: #f7f7f7;

  .date-text {
    color: ${props => props.theme.palette.dashboard.textPrimary};
  }
`

const Card = styled(Box)`
  width: 100%;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.gray100};
  border-radius: 3px;
  padding: 20px;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 12px;
      text-transform: uppercase;
      color: ${props => props.theme.palette.dashboard.textLight};
    }

    .sub-title {
      font-size: 10px;
      color: ${props => props.theme.palette.dashboard.textLight};
    }
  }

  .card-body {
    text-align: center;

    img {
      max-width: 180px;
      margin: 25px 0;
    }

    p {
      font-size: 12px;
      color: ${props => props.theme.palette.dashboard.textLight};

      span {
        color: ${props => props.theme.colors.blue50};
        cursor: pointer;
      }
    }
  }

  .view-more {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 12px;
      color: ${props => props.theme.colors.blue50};
    }

    svg {
      color: ${props => props.theme.colors.blue50};
      font-size: 15px;
    }
  }
`

const IconButton = styled(MuiIconButton)`
  padding: 5px;
  flex-shrink: 0;

  svg {
    font-size: 15px;
    color: ${props => props.theme.palette.dashboard.textLight};
  }

  &.plus-btn {
    width: 38px;
    height: 38px;
    padding: 5px;
    border-radius: 5px;
    background-color: white;

    svg {
      font-size: 20px;
      color: ${props => props.theme.colors.blue50};
    }
  }
`

export { Container, Card, IconButton }