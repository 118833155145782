import React from 'react'
import { Box } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'

import Card from './Card'
import TumbleWeedImg from '../../../../assets/images/dashboard/tumbleweed.svg'

const RecentActivity = (props) => {
  const { index } = props
  return (
    <Card
      title='Recent Activity'
      index={index}
    >
      <div className='card-body'>
        <img src={TumbleWeedImg} alt='Recent Activity' />
        <p>No recent activity, use one of our apps to track time</p>
      </div>
      <Box mt={4} display='flex' justifyContent='center'>
        <div className='view-more'>
          <span>View Activity</span>
          <ChevronRight />
        </div>
      </Box>
    </Card>
  )
}

export default RecentActivity