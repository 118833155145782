import React from 'react'

import Card from './Card'
import AppsImg from '../../../../assets/images/dashboard/apps_urls.png'

const Apps = (props) => {
  const { index } = props
  return (
    <Card
      title='Apps & urls'
      index={index}
    >
      <div className='card-body'>
        <img src={AppsImg} alt='Recent Activity' />
        <p>No apps or URLs visited this week</p>
      </div>
    </Card>
  )
}

export default Apps