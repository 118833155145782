import styled from 'styled-components'
import { Box } from '@material-ui/core'

import { AppPage } from '../../../../components/Dashboard/CommonStyled'

// @ts-ignore
const Container = styled(AppPage)`
  .show-date {
    width: 500px;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    .date-range {
      .show-date {
        width: 100%;
      }
    }

    .status {
      > div:first-child {
        flex: 1;
        width: 100%;
      }

      input {
        min-width: unset;
      }
    }
  }

  ${props => props.theme.breakpoints.down(500)} {
    .date-range {
      flex-direction: column;

      > div {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    .status {
      margin-top: 0;

      button {
        margin-left: 20px;
      }
    }

    .new-invoice {
      button, > div {
        flex: 1;
        max-width: 50%;
      }
    }
  }
`

const Info = styled(Box)`
  .label {
    font-size: 11px;
    text-transform: uppercase;
    color: ${props => props.theme.palette.dashboard.textLight};
  }

  .value {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    color: ${props => props.theme.palette.dashboard.textDark};

    &.paid {
      color: ${props => props.theme.colors.green100};
    }
  }
`

export { Container, Info }