import React from 'react'

import * as S from './styles'
import { Button } from '../../../components/Common/Button'

const FreeTrialSection = () => {
  return (
    <S.Container>
      <div className='blue-circle left' />
      <div className='blue-circle right' />
      <div className='container'>
        <h1>Try TimeGo free for 14 days</h1>
        <Button>Start my free trial</Button>
      </div>
    </S.Container>
  )
}

export default FreeTrialSection
