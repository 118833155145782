import styled from 'styled-components'
import { Drawer as ADrawer } from 'antd'

import { Button, OutlinedButton } from '../../Common/Button'

const Drawer = styled(ADrawer)`

  .ant-drawer-close {
    background: white !important;
  }

  .ant-drawer-body {
    max-height: 100vh;
    padding: 20px 1px !important;
    display: flex;
    flex-direction: column;
  }

  .ant-menu {
    .ant-menu-submenu-selected, .ant-menu-submenu-title:hover, .ant-menu-item-selected, .ant-menu-item:hover {
      color: ${(props) => props.theme.colors.blue} !important;
    }

    .ant-menu-selected::after, .ant-menu-item-selected::after {
      border-color: ${(props) => props.theme.colors.blue} !important;
    }

    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
      color: ${(props) => props.theme.colors.blue} !important;
    }
  }
`

const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 30px;

  img {
    width: 70%;
  }
`

const FreeTrialButton = styled(Button)`
  height: 40px !important;

  span {
    font-weight: 500;
    font-size: 14px !important;
  }
`

const SignInButton = styled(OutlinedButton)`
  height: 40px !important;

  span {
    font-weight: 500;
    font-size: 14px !important;
  }
`

const MenuWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
`

export { Drawer, Logo, FreeTrialButton, SignInButton, MenuWrapper }
