import React from 'react'
import styled from 'styled-components'
import { Checkbox as ACheckbox, CheckboxProps } from 'antd'

interface Props extends CheckboxProps {

}

const StyledCheckbox = styled(ACheckbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.colors.blue} !important;
    border-color: ${(props) => props.theme.colors.blue} !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.blue} !important;
  }

  .ant-checkbox-checked::after {
    border-color: ${(props) => props.theme.colors.blue} !important;
  }
`

const Checkbox = (props: Props) => {
  return (
    <StyledCheckbox {...props} />
  )
}

export default Checkbox
