import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import styled from 'styled-components'

import * as S from './styles'
import DatePicker from '../../../../components/Dashboard/DatePicker'
import Select, { Option } from '../../../../components/Dashboard/Select'
import Button from '../../../../components/Dashboard/Button'
import { AppPage } from '../../../../components/Dashboard/CommonStyled'
import LookingImg from '../../../../assets/images/dashboard/looking.svg'

const StyledSelect = styled(Select)`
  min-width: 140px;
`

const Apps = () => {
  const [date, setDate] = useState(new Date())

  return (
    <AppPage>
      <Box mb={2}>
        <h1>App activity</h1>
      </Box>
      <S.FilterGroup mt={2}>
        <Box className='left'>
          <DatePicker
            date={date}
            setDate={setDate}
          />
          <Box ml='10px'>
            <Button className='outlined'>Today</Button>
          </Box>
          <Box ml='10px'>
            <Button className='outlined'>All notes</Button>
          </Box>
        </Box>
        <Box className='right'>
          <Box display='flex'>
            <Box className='group' display='flex' mr='20px'>
              <Box>
                <StyledSelect placeholder='All types' label='Time Type'>
                  <Option value="normal">Normal</Option>
                  <Option value="idle">Idle</Option>
                  <Option value="resumed">Resumed</Option>
                  <Option value="manual">Manual</Option>
                </StyledSelect>
              </Box>
              <Box ml='20px'>
                <StyledSelect placeholder='All types' label='Source'>
                  <Option value="desktop">Desktop</Option>
                  <Option value="mobile">Mobile</Option>
                  <Option value="browser">Browser</Option>
                  <Option value="web-timer">Web timer</Option>
                </StyledSelect>
              </Box>
            </Box>
            <Box className='group' display='flex'>
              <Box>
                <StyledSelect placeholder='All levels' label='Activity Level'>
                  <Option value=">80">{`> 80%`}</Option>
                  <Option value=">60">{`> 60%`}</Option>
                  <Option value="<40>">{`< 40%`}</Option>
                  <Option value="<20>">{`< 20%`}</Option>
                </StyledSelect>
              </Box>
              <Box ml='20px'>
                <StyledSelect defaultValue="my-timezone" label='Timezone'>
                  <Option value="my-timezone">My time zone (Europe)</Option>
                </StyledSelect>
              </Box>
            </Box>
          </Box>
          <Box className='group' display='flex' justifyContent='flex-end'>
            <Box>
              <StyledSelect defaultValue="nikita-alexandar" label='Member'>
                <Option value="nikita-alexandar">Nikita Alexandar</Option>
              </StyledSelect>
            </Box>
            <Box ml={2}>
              <StyledSelect placeholder='All projects' label='Project'>
                <Option value="project">project</Option>
              </StyledSelect>
            </Box>
          </Box>
        </Box>
      </S.FilterGroup>
      <S.TableHeader>
        <Box width='20%'>Project</Box>
        <Box width='30%'>App name</Box>
        <Box width='40%'>Time spent (hrs)</Box>
        <Box>Sessions</Box>
      </S.TableHeader>
      <S.NoDataContainer>
        <img src={LookingImg} alt='No Activity' />
        <p>No data for this date range</p>
      </S.NoDataContainer>
    </AppPage>
  )
}

export default Apps