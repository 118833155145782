import React, {useMemo} from 'react'
import { useSelector } from 'react-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

import * as S from './styles'
import {
  AsanaMark,
  BreezeMark,
  ClickUpMark,
  FreshDeskMark,
  GithubMark,
  GitlabMark,
  GustoMark,
  InsightlyMark,
  JiraMark,
  PaypalMark,
  QuickBooksMark,
  SlackMark,
  TransferWiseMark,
  TrelloMark,
  ZapierMark,
  ZendeskMark
} from '../../../components/Common/Icons'
import { getScrollPosY } from '../../../store/selectors'
import { useDebounce } from '../../../utils/hooks/use-debounce'

const IntegrationsSection = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const currentScrollPosY = useSelector(getScrollPosY)

  const isShow = useMemo(() => {
    if (xsDown) {
      return currentScrollPosY > 9700
    }
    if (smDown) {
      return currentScrollPosY > 9200
    }
    return currentScrollPosY > 6300
  }, [currentScrollPosY, smDown, xsDown])

  const showSecondIcons = useDebounce(isShow, 300)
  const showThirdIcons = useDebounce(isShow, 500)

  return (
    <S.Container>
      {
        isShow &&
        <div className='left-panel'>
          <S.Icon
            top='8.9%'
            left='1.35%'
            className='size-medium'
          >
            <JiraMark width={40} height={40} />
          </S.Icon>
          {
            showSecondIcons &&
            <S.Icon
              top='14.2%'
              left='32.43%'
              className='size-small'
            >
              <GitlabMark width={25} height={25} />
            </S.Icon>
          }
          <S.Icon
            top='16%'
            right='21%'
            className='size-large'
            background='#C8DE72'
          >
            <ZendeskMark width={45} height={45} />
          </S.Icon>
          {
            showThirdIcons &&
            <S.Icon
              top='33.92%'
              left='19.72%'
              className='size-medium'
              background='#28a037'
            >
              <QuickBooksMark width={40} height={40} />
            </S.Icon>
          }
          {
            showSecondIcons &&
            <S.Icon
              top='36.6%'
              right='11.5%'
              className='size-small'
            >
              <PaypalMark width={30} height={30} />
            </S.Icon>
          }
          {
            showSecondIcons &&
            <S.Icon
              bottom='19.64%'
              left='1.35%'
              className='size-medium'
            >
              <BreezeMark width={40} height={40} />
            </S.Icon>
          }
          {
            showThirdIcons &&
            <S.Icon
              bottom='26.78%'
              left='41.2%'
              className='size-large'
              background='#ff5b6f'
            >
              <AsanaMark width={60} height={60} />
            </S.Icon>
          }
          <S.Icon
            bottom='24.1%'
            right='2%'
            className='size-medium'
          >
            <SlackMark width={40} height={40} />
          </S.Icon>
        </div>
      }
      <div className='main-content'>
        <h1>Over 30 integrations</h1>
        <p>TimeGo syncs with the aps you're already using.</p>
        <S.Button>Create account</S.Button>
      </div>
      {
        isShow &&
        <div className='right-panel'>
          {
            showSecondIcons &&
            <S.Icon
              top='12.5%'
              left='15.5%'
              className='size-medium'
              background='#0081cc'
            >
              <TrelloMark width={35} height={35} />
            </S.Icon>
          }
          {
            showThirdIcons &&
            <S.Icon
              top='19.64%'
              left='45%'
              className='size-small'
              background='#f45d48'
            >
              <GustoMark width={35} height={35} />
            </S.Icon>
          }
          <S.Icon
            top='9%'
            right='6.75%'
            className='size-large'
            background='#00b6ed'
          >
            <TransferWiseMark width={50} height={50} />
          </S.Icon>
          <S.Icon
            top='37.5%'
            left='28.4%'
            className='size-large'
          >
            <ClickUpMark width={75} height={75} />
          </S.Icon>
          {
            showSecondIcons &&
            <S.Icon
              top='37.5%'
              right='1.35%'
              className='size-medium'
            >
              <FreshDeskMark width={35} height={35} />
            </S.Icon>
          }
          {
            showThirdIcons &&
            <S.Icon
              bottom='23.2%'
              left='6.75%'
              className='size-medium'
              background='black'
            >
              <GithubMark width={35} height={35} />
            </S.Icon>
          }
          {
            showSecondIcons &&
            <S.Icon
              bottom='23.2%'
              right='40.5%'
              className='size-small'
            >
              <ZapierMark width={25} height={25} />
            </S.Icon>
          }
          <S.Icon
            bottom='19.8%'
            right='15.3%'
            className='size-small'
            background='#ff5621'
          >
            <InsightlyMark width={45} height={25} />
          </S.Icon>
        </div>
      }
    </S.Container>
  )
}

export default IntegrationsSection
