import React from 'react'
import styled, { css } from 'styled-components'
import { Input as AInput, InputProps } from 'antd'

interface Props extends InputProps {
  label?: string
  error?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  span.label {
    color: ${props => props.theme.palette.dashboard.textLight};
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  svg {
    color: ${props => props.theme.palette.dashboard.textPrimary};
  }
`

const StyledInput = styled(AInput)<{error?: string}>`
  border: 1px solid ${props => props.theme.colors.gray100} !important;
  border-radius: 5px !important;
  padding: 7px 11px !important;

  ${(props) => props.error && css`
    border-color: red !important;
  `}
`

const ErrorText = styled.p`
  margin: 2px 0 0 0 !important;
  font-size: 12px !important;
  color: red !important;
  line-height: 20px !important;
`

const Input = (props: Props) => {
  const { label, ...rest } = props

  return (
    <Container>
      {label && <span className='label'>{label}</span>}
      <StyledInput {...rest} />
      {
        props.error &&
        <ErrorText>{props.error}</ErrorText>
      }
    </Container>
  )
}

export default Input