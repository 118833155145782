import styled from 'styled-components'
import { Box } from '@material-ui/core'

const ProductsSubMenuWrapper = styled.div`
  position: absolute;
  top: 84px;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 30px 0 70px;
  box-shadow: 0 2px 6px rgba(47,83,151,0.1);

  h2 {
    font-size: 25px;
    margin-bottom: 0 !important;

    span {
      font-family: "Proxima Nova Regular", sans-serif;
    }
  }

  h4 {
    font-size: 20px;
    margin-bottom: 0 !important;

    span {
      font-family: "Proxima Nova Regular", sans-serif;
    }
  }

  p {
    font-size: 15px;

    &.font-weight-bold {
      font-weight: 600;
    }
  }

  .step-bar {
    position: relative;
    display: flex;
    height: 70%;
    margin-bottom: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .circle {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1px solid ${props => props.theme.colors.gray100};
      background-color: white;
      z-index: 1;
    }

    ::after {
      position: absolute;
      top: 0;
      left: calc(50% - 1px);
      width: 1px;
      height: 100%;
      background: ${props => props.theme.colors.gray100};
      content: '';
      display: block;
      z-index: 0;
    }
  }
`

const BigMenuItem = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  h2, p {
    transition: all ease 0.3s;
  }

  &:hover {
    cursor: pointer;

    h2, p {
      color: ${(props) => props.theme.colors.blue};
    }
  }
`

const SmallMenuItem = styled(Box)`
  position: relative;

  h4, p {
    transition: all ease 0.3s;
  }

  ::before {
    position: absolute;
    left: -37px;
    top: calc(50% - 5px);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.gray100};
    background-color: white;
    z-index: 1;
    content: '';
    transition: all ease 0.3s;
  }

  &:hover {
    cursor: pointer;

    h4, p {
      color: ${(props) => props.theme.colors.blue};
    }

    &::before {
      background-color: ${(props) => props.theme.colors.blue};
      border-color: ${(props) => props.theme.colors.blue};
    }
  }
`

export { ProductsSubMenuWrapper, BigMenuItem, SmallMenuItem }
