import React from 'react'
import { Box } from '@material-ui/core'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import WiseIcon from '../../../assets/images/dashboard/wise-icon.png'
import Modal from '../Modal'
import * as S from './styles'
import Input from '../Input'
import Button from '../Button'

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is not valid')
    .required('Email is required.'),
  name: Yup.string()
    .required('Name is required.'),
  currency: Yup.string()
    .required('Currency is required.'),
});

const CreateWiseAccountModal = (props) => {
  const { open, onClose } = props

  const handleSubmit = (values) => {
    console.log(values)
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Create Payment Account'
    >
      <S.CreatePaymentAccountContainer>
        <Box display='flex' justifyContent='center' py={2}>
          <img src={WiseIcon} alt='Wise' />
        </Box>
        <Box py={2}>
          <h3>Instructions</h3>
          <Box px={2} mb={2}>
            <p>1. Enter the Wise email address that will receive the funds</p>
          </Box>
        </Box>
        <S.Divider />
        <Formik
          initialValues={{ email: '', name: '', currency: '', submit: false }}
          validationSchema={formSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form className="form" onSubmit={handleSubmit}>
              <S.FormControl error={Boolean(errors.email && touched.email)}>
                <span className='label'>Wise Email*</span>
                <Input
                  name='email'
                  type='email'
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email && touched.email ? errors.email : ''}
                />
              </S.FormControl>
              <S.FormControl error={Boolean(errors.name && touched.name)}>
                <span className='label'>Wise Name*</span>
                <Input
                  name='name'
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name && touched.name ? errors.name : ''}
                />
              </S.FormControl>
              <S.FormControl error={Boolean(errors.currency && touched.currency)}>
                <span className='label'>Wise Currency*</span>
                <Input
                  name='currency'
                  value={values.currency}
                  onChange={handleChange}
                  error={errors.currency && touched.currency ? errors.currency : ''}
                />
              </S.FormControl>
              <Box className='footer'>
                <Button className='outlined' onClick={onClose}>Cancel</Button>&nbsp;&nbsp;
                <Button htmlType='submit' className='primary-contained'>Connect</Button>
              </Box>
            </Form>
          )}
        </Formik>
      </S.CreatePaymentAccountContainer>
    </Modal>
  )
}

export default CreateWiseAccountModal