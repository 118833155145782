import React from 'react'

import Card from './Card'
import ProjectsImg from '../../../../assets/images/dashboard/projects.png'

const Projects = (props) => {
  const { index } = props
  return (
    <Card
      title='Projects'
      index={index}
    >
      <div className='card-body'>
        <img src={ProjectsImg} alt='Projects' />
        <p>No time tracked yet this week. <span>View projects</span></p>
      </div>
    </Card>
  )
}

export default Projects