import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { useHistory } from 'react-router'

import DateRange from '../../../../components/Dashboard/DateRange'
import Input from '../../../../components/Dashboard/Input'
import Button from '../../../../components/Dashboard/Button'
import * as S from './styles'
import Select, { Option } from '../../../../components/Dashboard/Select'
import InvoiceTable from './InvoiceTable'

const useStyles = makeStyles(() => ({
  input: {
    "minWidth": 500,
    '& .label': {
      fontSize: 20
    }
  },
  dateRange: {
    '& .show-date': {
      width: 500
    }
  },
  button: {
    padding: '7px 22px !important',
    borderColor: '#18d06b !important',
    backgroundColor: '#18d06b !important',
    marginLeft: 30,
  },
  export: {
    width: 100,
    marginLeft: 20
  }
}));

const Invoices = () => {
  const classes = useStyles()
  const history = useHistory()
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate()
  })

  return (
    <S.Container>
      <Box mb={2}>
        <h1>User invoices</h1>
      </Box>
      <Box className='date-range' display='flex' justifyContent='space-between'>
        <DateRange
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
        <Box display='flex'>
          <S.Info>
            <span className='label'>Outstanding</span>
            <h5 className='value'>$0.00</h5>
          </S.Info>
          <S.Info ml={2}>
            <span className='label'>Paid</span>
            <h5 className='value paid'>$0.00</h5>
          </S.Info>
        </Box>
      </Box>
      <Box className='status' display='flex' mt={2} alignItems='flex-end'>
        <Input className={classes.input} label='Status' />
        <Button className={clsx(classes.button, 'primary-contained')}>Apply</Button>
      </Box>
      <Box className='new-invoice' display='flex' justifyContent='flex-end' mt={2}>
        <Button className='primary-contained' onClick={() => history.push('/dashboard/financials/invoices/new')}>New invoice</Button>
        <Select placeholder="Export" className={classes.export}>
          <Option value="export">Export</Option>
        </Select>
      </Box>
      <Box mt={2}>
        <InvoiceTable />
      </Box>
    </S.Container>
  )
}

export default Invoices