import React from 'react'
import { Box } from '@material-ui/core'
import { EmojiObjectsOutlined } from '@material-ui/icons'
import styled from 'styled-components'

import * as S from './styles'

const Container = styled(Box)`
  .hint-text {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: ${props => props.theme.palette.dashboard.textLight};

    svg {
      width: 15px;
      height: 15px;
    }
  }
`

const Billing = () => {
  return (
    <Container pt={3}>
      <Box>
        <span className='hint-text'><EmojiObjectsOutlined /> Each organization is billed separately</span>
      </Box>
      <Box mt={3}>
        <S.TableHeader>
          <Box width='30%'>Organization</Box>
          <Box width='20%'>Status</Box>
          <Box width='30%'>Created on</Box>
          <Box width='20%'>Plan</Box>
        </S.TableHeader>
        <Box mt={2} ml={2}>
          <p>You do not own any organizations. Only owenrs can manage the billing for an organization.</p>
        </Box>
      </Box>
    </Container>
  )
}

export default Billing