export enum Color {
  white = '#FFFFFF',
  black = '#000000',
  dark = '#253053',
  blue = '#294DFF',
  blue50 = '#2aa7ff',
  blue100 = '#f2f8ff',
  blue200 = '#57b6ff',
  blue300 = '#3f5fff',
  blue400 = '#294dff',
  blue500 = '#253053',
  blue600 = '#1f294a',
  green = '#08BA98',
  green100 = '#18d06b',
  yellow = '#ff881d',
  gray = '#666e86',
  gray100 = '#e3e8ef',
  gray200 = '#8e9eb3'
}

const theme = {
  fontWeights: [0, 300, 400, 500, 600, 700],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
    button: 3,
  },
  colors: {
    white: Color.white,
    black: Color.black,
    blue: Color.blue,
    dark: Color.dark,
    blue50: Color.blue50,
    blue100: Color.blue100,
    blue200: Color.blue200,
    blue300: Color.blue300,
    blue400: Color.blue400,
    blue500: Color.blue500,
    blue600: Color.blue600,
    green: Color.green,
    green100: Color.green100,
    yellow: Color.yellow,
    gray: Color.gray,
    gray100: Color.gray100,
    gray200: Color.gray200
  },
  buttonSizes: {
    medium: {
      fontSize: '1rem',
      height: '2.5rem',
    },
  },
  palette: {
    primary: {
      main: Color.blue,
      light: Color.white,
      dark: Color.dark,
      contrastText: Color.white,
    },
    text: {
      primary: Color.dark,
      secondary: Color.black,
      disabled: Color.black,
      hint: Color.white,
    },
    dashboard: {
      primary: '#2aa7ff',
      textDark: '#27303c',
      textPrimary: '#617083',
      textLight: '#79899d',
      border: Color.gray100
    }
  },
}

export default theme
