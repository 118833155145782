import styled from 'styled-components'
import { Box } from '@material-ui/core'

const SelectContainer = styled(Box)`
  padding-top: 30px;

  .account-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 140px;
      margin-bottom: 10px;
    }

    p {
      max-width: 370px;
      color: ${props => props.theme.palette.dashboard.textLight};
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.gray100};
  margin: 20px 0;
`

const CreatePaymentAccountContainer = styled(Box)`
  width: 600px;

  h3 {
    font-size: 18px;
  }

  img {
    width: 200px;
  }

  p {
    color: ${props => props.theme.palette.dashboard.textPrimary};
    margin-bottom: 0;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    width: 100%;
  }
`

const DescriptionPanel = styled.div`
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.gray100};
  background-color: #f5f5f5;
  border-radius: 10px;

  p {
    font-size: 13px;
    margin-bottom: 13px;
  }
`

const FormControl = styled.div<{error?: boolean}>`
  display: flex;
  margin-bottom: 15px;

  .label {
    font-size: 11px;
    text-transform: uppercase;
    color: ${props => props.error ? 'red' : props.theme.palette.dashboard.textLight};
    width: 200px;
    margin-top: 10px;
  }

  > div {
    width: 100%;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    flex-direction: column;

    .label {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
`

export {
  SelectContainer,
  Divider,
  CreatePaymentAccountContainer,
  DescriptionPanel,
  FormControl,
}