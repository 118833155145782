import React from 'react'

import Card from './Card'
import TimeOffRequestedImg from '../../../../assets/images/dashboard/time-off-request.png'

const TimeOffRequested = (props) => {
  const { index } = props
  return (
    <Card
      title='Time off requested'
      index={index}
    >
      <div className='card-body'>
        <img src={TimeOffRequestedImg} alt='Time off requested' />
        <p>No pending time off requests.</p>
      </div>
    </Card>
  )
}

export default TimeOffRequested