import styled, { css } from 'styled-components'

const Container = styled.section`
  position: relative;
  overflow: hidden;
  height: 1120px;
  border-top: 3px solid ${(props) => props.theme.colors.blue100};
  background-color: ${(props) => props.theme.colors.dark};
  padding-bottom: 50px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 130px;
    text-align: center;

    h2.title {
      font: 45px 'Proxima Nova Bold';
      color: white;
      margin-bottom: 25px;
    }

    p.description {
      font: 18px 'Lato-Regular';
      color: white;
    }

    .card-list {
      max-width: 1210px;
      display: grid;
      grid-gap: 95px;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      margin-top: 106px;
      z-index: 3;

      ${(props) => props.theme.breakpoints.down('lg')} {
        grid-gap: 50px;
      }

      ${(props) => props.theme.breakpoints.down('sm')} {
        grid-template-columns: repeat(8, minmax(0, 1fr));

        > div:last-child {
          margin-bottom: 50px;
        }
      }

      ${(props) => props.theme.breakpoints.down('xs')} {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
  }

  .background-brand-circle {
    position: absolute;
    z-index: -1;
    border-radius: 100%;

    &.blue {
      background: ${(props) => props.theme.colors.blue};
      width: 99vw;
      height: 99vw;
      left: -68vw;
      bottom: -69vw;
    }

    &.green {
      background: ${(props) => props.theme.colors.green};
      width: 99vw;
      height: 99vw;
      right: -71vw;
      top: -76vw;
    }

    &.yellow {
      background: ${(props) => props.theme.colors.yellow};
      width: 99vw;
      height: 99vw;
      left: -81vw;
      top: -78vw;
    }

    &.white {
      background: ${(props) => props.theme.colors.white};
      width: 600vw;
      height: 300vw;
      left: -250vw;
      bottom: -296vw;
    }
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    height: fit-content;

    .container {
      padding-top: 80px;

      .title {
        font-size: 35px !important;
      }

      .card-list {
        margin-top: 40px;
      }
    }
  }
`

const CardItem = styled.div<{direction: string, image: string}>`
  grid-column: span 4;
  display: flex;
  flex-direction: column;
  height: 445px;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  transition: all ease 0.3s;
  cursor: pointer;
  box-shadow: 0 0 10px 5px rgba(47,83,151,0.1);
  max-width: 350px;

  .card-image {
    height: 285px;
    background-size: cover !important;
    background-position: 50% 50%;
    background-image: url(${(props) => props.image});
  }

  .card-body {
    padding: 30px;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 23px;

      img {
        width: 30px;
        height: 30px;
      }

      h3 {
        font: 23px 'Proxima Nova Bold';
        margin-bottom: 0;
        margin-left: 5px;

        span {
          font: 23px 'Proxima Nova Regular';
          font-weight: 400;
        }
      }
    }

    p {
      font: 16px 'Lato-Regular';
      text-align: left;
    }
  }

  &:hover {
    transform: translate(0, -10px);
    box-shadow: 0 20px 10px 5px rgba(47,83,151,0.1);
  }

  ${(props) => props.direction === 'horizontal' && css`
    grid-column: span 6;
    flex-direction: row;
    height: 200px;
    max-width: unset;

    .card-image {
      height: 100%;
      width: 160px;
      min-width: 160px;
    }
  `}

`

export { Container, CardItem }
