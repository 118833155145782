import React from 'react'
import { withStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Switch as MuiSwitch, Box } from '@material-ui/core'

import * as S from './styles'
import { UserImg } from '../../Common/Images'

interface IProgressChartData {
  label: string
  valueText: string
  percentValue: number
}

const Switch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      "padding": 2,
      "color": 'gray',
      '&$checked': {
        "transform": 'translateX(12px)',
        "color": 'white',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#54b6f9',
          borderColor: '#54b6f9',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid gray`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: 'white',
    },
    checked: {},
  }),
)(MuiSwitch);

const ProgressChart = (props: IProgressChartData) => {
  return (
    <S.ProgressChart>
      <p className='label'>{props.label}</p>
      <S.ProgressBar value={props.percentValue} />
      <div className='percent-label'>
        <span>{props.valueText}</span>
        <span>{props.percentValue}%</span>
      </div>
    </S.ProgressChart>
  )
}

const FindCharts = () => {
  return (
    <S.Container>
      <div className='card'>
        <Box mb='20px'><ProgressChart label='Development' valueText='18 hrs' percentValue={40} /></Box>
        <Box mb='20px'><ProgressChart label='Webinars' valueText='$1,200' percentValue={50} /></Box>
        <ProgressChart label='Admin' valueText='18 hrs' percentValue={133} />
      </div>
      <div className='card'>
        <div className='profile'>
          <div className='header'>
            <div className='avatar'>
              <img src={UserImg} alt="User" />
            </div>
            <div className='user-name'>
              <p className='bold'>Darrel Wells</p>
              <p>darrel@hubstaff.com</p>
            </div>
          </div>
          <div className='body'>
            <div className='user-info-item'>
              <p className='bold'>Integration</p>
              <p className='gusto'>gusto</p>
            </div>
            <div className='user-info-item'>
              <p className='bold'>Period</p>
              <p>4/29/20 - 5/01/20</p>
            </div>
            <div className='user-info-item'>
              <p className='bold'>Rate</p>
              <p>$60/hr</p>
            </div>
            <div className='user-info-item'>
              <p className='bold'>Timesheet approval</p>
              <Switch checked={true} />
            </div>
          </div>
        </div>
        <ProgressChart label='Weekly limit' valueText='20 hrs' percentValue={50} />
      </div>
    </S.Container>
  )
}

export default FindCharts
