import styled from 'styled-components'
import { AppPage } from '../../../../components/Dashboard/CommonStyled'

// @ts-ignore
const Container = styled(AppPage)`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: ${props => props.theme.palette.dashboard.textDark} !important;
    font-size: 30px;
  }

  p {
    color: ${props => props.theme.palette.dashboard.textPrimary};
    font-size: 16px;
    max-width: 475px;
    text-align: center;
    margin-top: 10px !important;
    margin-bottom: 30px !important;
  }

  button {
    min-width: 110px;
  }

  ${props => props.theme.breakpoints.down('xs')}{
    img {
      width: 100%;
    }
  }
`

export { Container }