import React from 'react'
import { Box } from '@material-ui/core'
import styled from 'styled-components'
import { SortableContainer, arrayMove } from 'react-sortable-hoc'
import { useDispatch, useSelector } from 'react-redux'

import Apps from './Apps'
import ManualTime from './ManualTime'
import Payments from './Payments'
import Projects from './Projects'
import RecentActivity from './RecentActivity'
import ThisWeek from './ThisWeek'
import TimeOffBalances from './TimeOffBalances'
import TimeOffRequested from './TimeOffRequested'
import TimeSheet from './TimeSheet'
import Todo from './Todo'
import Shifts from './Shifts'
import { getWidgets } from '../../../../store/selectors'
import { setWidgets } from '../../../../store/actions/dashboard.actions'

const Container = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  ${props => props.theme.breakpoints.down('xs')} {
    grid-template-columns: 1fr;
  }
`

const SortContainer = SortableContainer(props => <Container {...props} />)

const components = [
  {
    title: 'Apps & URLs',
    component: Apps
  },
  {
    title: 'Manual Time',
    component: ManualTime
  },
  {
    title: 'Payments',
    component: Payments
  },
  {
    title: 'Projects',
    component: Projects
  },
  {
    title: 'Recent activity',
    component: RecentActivity
  },
  {
    title: 'This week',
    component: ThisWeek
  },
  {
    title: 'Time off balances',
    component: TimeOffBalances
  },
  {
    title: 'Time off requested',
    component: TimeOffRequested
  },
  {
    title: 'Timesheet',
    component: TimeSheet
  },
  {
    title: 'To-dos',
    component: Todo
  },
  {
    title: 'Late & Missed Shifts',
    component: Shifts
  }
]

const BigWidgetList = () => {
  const dispatch = useDispatch()
  const widgets = useSelector(getWidgets)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const emptyArray = [] as string[]
      const newData = arrayMove(emptyArray.concat(widgets.largeWidgets), oldIndex, newIndex).filter(el => !!el);
      const updatedWidgets = {...widgets, largeWidgets: newData}
      dispatch(setWidgets(updatedWidgets));
    }
  };

  const DraggableContainer = props => (
    <SortContainer
      axis='xy'
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  return (
    <DraggableContainer>
      {
        widgets.largeWidgets.map((widget, index) => {
          const widgetComponent = components.find(c => c.title === widget)
          if (widgetComponent) {
            return (
              <widgetComponent.component
                key={index}
                index={index}
              />
            )
          }
          return null
        })
      }
    </DraggableContainer>
  )
}

export default BigWidgetList