import React from 'react'
import { Table } from 'antd'
import styled from 'styled-components'

import { TableContainer } from '../../../components/Dashboard/CommonStyled'
import UserImg from '../../../assets/images/dashboard/user-avatar.png'
import TableActionMenu from '../../../components/Dashboard/TableActionMenu'

interface DataType {
  key: React.Key
  name: string
  members: string[]
  projects: 3
}

const MemberList = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    margin-right: 5px;
  }
`

const Name = styled.div`
  display: flex;
  align-items: center;
  color: #53aaff;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    background-color: #82b55e;
    margin-right: 10px;
    text-transform: uppercase;
  }

  img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name) => <Name>
      <div className='avatar'>
        {name[0]}
      </div>
      {name}
    </Name>
  },
  {
    title: 'Members',
    dataIndex: 'members',
    render: members => (
      <MemberList>
        { members.map((img, index) => (
            <img key={index} src={img} alt='Member'/>
          ))
        }
      </MemberList>
    )
  },
  {
    title: 'Projects',
    dataIndex: 'projects',
  },
  {
    title: '',
    key: 'operation',
    width: 100,
    render: () => <TableActionMenu />
  }
]

const data: DataType[] = [
  {
    key: '1',
    name: 'Arbitage - BE',
    members: [UserImg, UserImg],
    projects: 3,
  }
]

const TeamTable = () => {
  return (
    <TableContainer>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{x: 'max-content'}}
      />
    </TableContainer>
  )
}

export default TeamTable