import React from 'react'
import { Box } from '@material-ui/core'

import * as S from './styles'
import ManageWidgets from './ManageWdigets'
import SmallWidgetList from './SmallWidgetList'
import BigWidgetList from './BigWidgetList'

const DefaultPage = () => {
  return (
    <S.Container>
      <Box mb={2}>
        <h1>Dashboard page</h1>
        <span className='date-text'>Mon, Oct 18, 2021 - Sun, Oct 24 2021</span>
      </Box>
      <Box display='flex' justifyContent='flex-end' width='100%' mb={2}>
        <ManageWidgets />
      </Box>
      <SmallWidgetList />
      <BigWidgetList />
    </S.Container>
  )
}

export default DefaultPage