import React from 'react'

import Card from './Card'
import TimeOffBalancesImg from '../../../../assets/images/dashboard/time-off-balance.png'

const TimeOffBalances = (props) => {
  const { index } = props
  return (
    <Card
      title='Time off balances'
      index={index}
    >
      <div className='card-body'>
        <img src={TimeOffBalancesImg} alt='Time off balances' />
        <p>No time off balances to show.</p>
      </div>
    </Card>
  )
}

export default TimeOffBalances