import React from 'react'
import { Box, ClickAwayListener, Fade } from '@material-ui/core'
import { DownOutlined } from '@ant-design/icons'

import { MenuButton, SubMenuContainer } from '../styles'
import * as S from './styles'

interface Props {
  active: boolean
  setActive: (menu: string) => void
}

const menu = {
  industries: [
    'Cleaning',
    'Construction',
    'Landscaping',
    'Health care',
    'Consultants',
    'Agency',
    'Manufacturing',
    'Architects and engineers',
    'Accountants',
    'Attorneys',
    'Software developers',
    'E-commerce',
    'Real estate',
    'Nonprofit',
    'Design'
  ],
  teams: [
    'Freelancers',
    'Field services',
    'Remote work'
  ]
}

const HowItWorksMenu = ({ active, setActive }: Props) => {
  return (
    <Box position='relative'>
      <MenuButton onClick={() => setActive(!active ? 'how_it_works' : '')} isOpen={active}>
        <span>How it works</span>
        <DownOutlined />
      </MenuButton>
      <Fade in={active}>
        <Box>
          {
            active &&
            <ClickAwayListener onClickAway={() => setActive('')}>
              <SubMenuContainer left={-20}>
                <Box display='flex' py='10px' width={700}>
                  <S.Industries>
                    <div className='header'>
                      <h3>For industries</h3>
                    </div>
                    <div className='body'>
                      {
                        menu.industries.map((item) => (
                          <S.MenuItem key={item}>{ item }</S.MenuItem>
                        ))
                      }
                    </div>
                  </S.Industries>
                  <S.Teams>
                    <div className='header'>
                      <h3>For teams</h3>
                    </div>
                    <div className='body'>
                      {
                        menu.teams.map((item) => (
                          <S.MenuItem key={item}>{ item }</S.MenuItem>
                        ))
                      }
                    </div>
                  </S.Teams>
                </Box>
              </SubMenuContainer>
            </ClickAwayListener>
          }
        </Box>
      </Fade>
    </Box>
  )
}

export default HowItWorksMenu
