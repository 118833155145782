import React from 'react'
import { Box } from '@material-ui/core'
import { Select as ASelect, Switch } from 'antd'
import { makeStyles } from '@material-ui/styles'
import { FilterNone, Launch, Search } from '@material-ui/icons'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'
import clsx from 'clsx'

import * as S from './styles'
import AsanaIcon from '../../../../assets/images/dashboard/asana-icon.png'
import EmptyFolderImg from '../../../../assets/images/dashboard/empty_folder.svg'
import HubstaffTasksImg from '../../../../assets/images/dashboard/hubstaff_tasks.png'
import Select from '../../../../components/Dashboard/Select'
import Input from '../../../../components/Dashboard/Input'
import Button from '../../../../components/Dashboard/Button'

const { Option } = ASelect

const useStyles = makeStyles(() => ({
  projectSelect: {
    "minWidth": 400,
    '& .ant-select-selector': {
      height: '38px !important'
    }
  },
  projectOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  projectImg: {
    marginRight: 30,
    marginLeft: 'auto',
    width: 20,
    height: 20
  },
  iconButton: {
    "display": 'flex',
    "alignItems": 'center',
    "justifyContent": 'center',
    '& svg': {
      width: 15,
      height: 15,
      margin: '0 5px'
    }
  },
  switch: {
    "display": 'flex',
    "alignItems": 'center',
    '& .label': {
      fontSize: 12,
      lineHeight: '12px',
      textTransform: 'uppercase',
      color: '#79899d',
      marginLeft: 5
    }
  },
  search: {
    maxWidth: 200,
    borderRadius: '30px !important'
  },
}))

const Todos = () => {
  const classes = useStyles()

  return (
    <S.Container>
      <Box mb={2}>
        <h1>Tasks | <span className='sub-title'><img src={AsanaIcon} alt='Asana' />Asana (cgblockchain.com)</span></h1>
      </Box>
      <Box className='tool-bar' display='flex' justifyContent='space-between'>
        <Box className='left' display='flex'>
          <Box mr={3}>
            <Select label='Project' className={classes.projectSelect} defaultValue='arbitage-be'>
              <Option value='arbitage-be'><span className={classes.projectOption}>Arbitage - BE <img className={classes.projectImg} src={AsanaIcon} alt='Arbitage - BE' /></span> </Option>
            </Select>
          </Box>
          <Box>
            <Input label='Assignee' placeholder='Select assignee'/>
          </Box>
        </Box>
        <Box className='right' display='flex' pt='20px' justifyContent='flex-end'>
          <Box mr={3}>
            <Button icon={<FilterNone />} className={classes.iconButton}>Duplicate project</Button>
          </Box>
          <Box>
            <Button className={clsx(classes.iconButton, 'primary-contained')}>Add a task <Launch /></Button>
          </Box>
        </Box>
      </Box>
      <Box className='search-wrapper' pt={3} display='flex' justifyContent='space-between' alignItems='flex-start'>
        <div className={classes.switch}>
          <Switch
            size='small'
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
          <span className='label'>Show complicated Tasks</span>
        </div>
        <Input
          className={classes.search}
          prefix={<Search />}
          placeholder='Search projects'
        />
      </Box>
      <S.NoContent>
        <img src={EmptyFolderImg} alt='No Tasks' />
        <h3>No tasks</h3>
        <p>Add a to-do, or link this project to an integration like <img className='hubstaff-tasks' src={HubstaffTasksImg} alt='Hubstaff Tasks' /></p>
        <Button className={clsx(classes.iconButton, 'primary-contained')}>Add a task <Launch /></Button>
      </S.NoContent>
    </S.Container>
  )
}

export default Todos