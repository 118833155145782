import React from 'react'
import { Menu } from 'antd'
import { Box } from '@material-ui/core'
import { ExportOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router'

import { Logo } from '../../Common/Images'
import * as S from './styles'

const { SubMenu } = Menu

interface Props {
  open: boolean
  onClose: (event: any) => void
}

const SideMenu = (props: Props) => {

  const history = useHistory()
  const { open, onClose } = props

  const gotoSignInPage = () => {
    history.push('/auth/sign-in')
  }

  return (
    <S.Drawer placement={'left'} visible={open} onClose={onClose}>
      <S.Logo><img src={Logo} alt='logo' /></S.Logo>
      <S.MenuWrapper>
        <Menu
          mode='inline'
          theme='light'
        >
          <SubMenu key='products' title='Products'>
            <Menu.Item key='products.hubstaff'>TimeGo</Menu.Item>
            <Menu.Item key='products.hubstaff_tasks'>TimeGo Tasks</Menu.Item>
            <Menu.Item key='products.hubstaff_talents'>TimeGo Talents</Menu.Item>
          </SubMenu>
          <SubMenu key='features' title='Features'>
            <Menu.Item key='prodcuts.time_tracking'>Time tracking</Menu.Item>
            <Menu.Item key='features.online_timesheets'>Online timesheets</Menu.Item>
            <Menu.Item key='features.geofencing'>Geofencing</Menu.Item>
            <Menu.Item key='features.payroll_software'>Payroll software</Menu.Item>
            <Menu.Item key='features.employee_monitoring'>Employee monitoring</Menu.Item>
            <Menu.Item key='features.productivity_measurement'>Productivity measurement</Menu.Item>
            <Menu.Item key='features.gps_tracking'>GPS tracking</Menu.Item>
            <Menu.Item key='features.online_invoicing'>Online invoicing</Menu.Item>
            <Menu.Item key='features.project_budgeting'>Project budgeting</Menu.Item>
            <Menu.Item key='features.employee_scheduling'>Employee scheduling</Menu.Item>
            <Menu.Item key='features.detailed_reporting'>Detailed reporting</Menu.Item>
            <Menu.Item key='features.mobile_time_tracking'>Mobile time tracking</Menu.Item>
            <Menu.Item key='features.work_orders'>Work orders & jobs</Menu.Item>
          </SubMenu>
          <SubMenu key='how_it_works' title='How it works'>
            <SubMenu key='how_it_works.industries' title='For industries'>
              <Menu.Item key='how_it_works.industries.cleaning'>Cleaning</Menu.Item>
              <Menu.Item key='how_it_works.industries.construction'>Construction</Menu.Item>
              <Menu.Item key='how_it_works.industries.landscaping'>Landscaping</Menu.Item>
              <Menu.Item key='how_it_works.industries.health_care'>Health care</Menu.Item>
              <Menu.Item key='how_it_works.industries.consultants'>Consultants</Menu.Item>
              <Menu.Item key='how_it_works.industries.agency'>Agency</Menu.Item>
              <Menu.Item key='how_it_works.industries.manufacturing'>Manufacturing</Menu.Item>
              <Menu.Item key='how_it_works.industries.architects_engineers'>Architects and engineers</Menu.Item>
              <Menu.Item key='how_it_works.industries.accountants'>Accountants</Menu.Item>
              <Menu.Item key='how_it_works.industries.attorneys'>Attorneys</Menu.Item>
              <Menu.Item key='how_it_works.industries.software_developers'>Software developers</Menu.Item>
              <Menu.Item key='how_it_works.industries.ecommerce'>E-commerce</Menu.Item>
              <Menu.Item key='how_it_works.industries.real_estate'>Real estate</Menu.Item>
              <Menu.Item key='how_it_works.industries.nonprofit'>Nonprofit</Menu.Item>
              <Menu.Item key='how_it_works.industries.design'>Design</Menu.Item>
            </SubMenu>
            <SubMenu key='how_it_works.teams' title='For teams'>
              <Menu.Item key='how_it_works.teams.freelancers'>Freelancers</Menu.Item>
              <Menu.Item key='how_it_works.teams.field_services'>Field services</Menu.Item>
              <Menu.Item key='how_it_works.teams.remote_work'>Remote work</Menu.Item>
            </SubMenu>
          </SubMenu>
          <SubMenu key='why_hubstaff' title='Why TimeGo?'>
            <Menu.Item key='why_hubstaff.tracks'>What Hubstaff tracks</Menu.Item>
            <Menu.Item key='why_hubstaff.integrations'>Integrations</Menu.Item>
            <Menu.Item key='why_hubstaff.customer_stories'>Customer stories</Menu.Item>
            <Menu.Item key='why_hubstaff.reviews'>Reviews</Menu.Item>
            <Menu.Item key='why_hubstaff.our_blog'>Our blog</Menu.Item>
            <Menu.Item key='why_hubstaff.about'>About Hubstaff</Menu.Item>
          </SubMenu>
          <Menu.Item key='pricing'>Pricing</Menu.Item>
          <Menu.Item key='demo'>Demo</Menu.Item>
        </Menu>
      </S.MenuWrapper>
      <Box mt='auto'>
        <Box mt={2} mx={2}><S.SignInButton onClick={gotoSignInPage}><ExportOutlined width={20} height={20}/> Sign in</S.SignInButton></Box>
      </Box>
    </S.Drawer>
  )
}

export default SideMenu
