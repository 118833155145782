import React from 'react'
import styled from 'styled-components'
import { Select as ASelect } from 'antd'

export const { Option } = ASelect

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  span.label {
    color: ${props => props.theme.palette.dashboard.textLight};
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
`

const StyledSelect = styled(ASelect)`
  .ant-select-selector {
    border: 1px solid ${props => props.theme.colors.gray100} !important;
    border-radius: 5px !important;
    padding: 3px 11px !important;
    height: unset !important;
  }
`

const Select = (props) => {
  const { label, className, ...rest } = props

  return (
    <Container className={className}>
      {label && <span className='label'>{label}</span>}
      <StyledSelect {...rest}/>
    </Container>
  )
}

export default Select