import React from 'react'
import { Input as AInput, InputProps } from 'antd'
import styled, { css } from 'styled-components'

interface Props extends InputProps {
  error?: string
}

const StyledInput = styled(AInput)<{error?: string}>`
  &.ant-input {
    padding: 12px 12px !important;
    color: ${(props) => props.theme.palette.text.primary} !important;
    font: 14px 'Lato-Regular' !important;
    line-height: 1.25 !important;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    outline: 0 !important;
    box-sizing: border-box !important;
    width: 100% !important;

    &:focus {
      box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.blue} !important;
      border-color: ${(props) => props.theme.colors.blue} !important;
    }

    ${(props) => props.error && css`
      border-color: red !important;
    `}
  }
`

const ErrorText = styled.p`
  margin: 2px 0 0 0 !important;
  font-size: 12px !important;
  color: red !important;
  line-height: 20px !important;
`

const Input = (props: Props) => {
  return (
    <>
      <StyledInput {...props} />
      {
        props.error &&
        <ErrorText>{props.error}</ErrorText>
      }
    </>
  )
}

export default Input
