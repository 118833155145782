import React from 'react'
import { DownOutlined } from '@ant-design/icons'
import { Row, Col } from 'antd'
import { Box, ClickAwayListener, Fade } from '@material-ui/core'

import { MenuButton } from '../styles'
import * as S from './styles'
import { HubStaffIcon, HubStaffTalentIcon, HubStaffTaskIcon } from '../../../Common/Images'

interface Props {
  active: boolean
  setActive: (menu: string) => void
}

const ProductsMenu = ({ active, setActive }: Props) => {

  return (
    <React.Fragment>
      <MenuButton onClick={() => setActive(!active ? 'products' : '')} isOpen={active}>
        <span>Products</span>
        <DownOutlined />
      </MenuButton>
      <Fade in={active}>
        <Box>
          {
            active &&
            <ClickAwayListener onClickAway={() => setActive('')}>
              <S.ProductsSubMenuWrapper>
                <div className='container'>
                  <Row gutter={20}>
                    <Col span={8}>
                      <h1>A suit of tools to help your team work smarter</h1>
                    </Col>
                    <Col span={8}>
                      <S.BigMenuItem>
                        <Box>
                          <h2>TimeGo</h2>
                          <p className='font-weight-bold'>The all-in-one work time tracker for managing field or remote teams</p>
                        </Box>
                      </S.BigMenuItem>
                      <Box display='flex'>
                        <Box width='58px' display='flex' justifyContent='center' alignItems='center'>
                          <div className='step-bar' />
                        </Box>
                        <Box>
                          <S.SmallMenuItem mb={2}>
                            <h4>TimeGo <span>Time</span></h4>
                            <p>Simple time tracking and reporting</p>
                          </S.SmallMenuItem>
                          <S.SmallMenuItem mb={2}>
                            <h4>TimeGo <span>Desk</span></h4>
                            <p>Proof of work, time tracking and team management</p>
                          </S.SmallMenuItem>
                          <S.SmallMenuItem mb={2}>
                            <h4>TimeGo <span>Field</span></h4>
                            <p>GPS location tracking and team management</p>
                          </S.SmallMenuItem>
                        </Box>
                      </Box>
                    </Col>
                    <Col span={8}>
                      <S.BigMenuItem pb='10px' ml={2} borderBottom='1px solid lightgrey'>
                        <Box>
                          <h2>TimeGo <span>Tasks</span></h2>
                          <p className='font-weight-bold'>Simple, Agile project managment for productive teams of all sizes</p>
                        </Box>
                      </S.BigMenuItem>
                      <S.BigMenuItem mb='30px' pb='10px' ml={2} borderBottom='1px solid lightgrey'>
                        <Box>
                          <h2>TimeGo <span>Talent</span></h2>
                          <p className='font-weight-bold'>The free and easy way to find remote talent across the world</p>
                        </Box>
                      </S.BigMenuItem>
                    </Col>
                  </Row>
                </div>
              </S.ProductsSubMenuWrapper>
            </ClickAwayListener>
          }
        </Box>
      </Fade>
    </React.Fragment>
  )
}

export default ProductsMenu
