import React from 'react'
import { Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Check } from '@material-ui/icons'

import * as S from './styles'
import Button from '../../../components/Dashboard/Button'

const Notifications = () => {
  return (
    <S.Container>
      <Box mb={2}>
        <h1>Notifications</h1>
      </Box>
      <h3>Needs your attention</h3>
      <S.NotificationItem>
        <span className='status' />
        <div className='content'>
          <p>It is time to <Link to='#'>submit your timesheet</Link>.</p>
        </div>
        <span className='time'>5 days ago</span>
      </S.NotificationItem>
      <S.NotificationItem>
        <span className='status' />
        <div className='content'>
          <p>It is time to <Link to='#'>submit your timesheet</Link>.</p>
        </div>
        <span className='time'>12 days ago</span>
      </S.NotificationItem>
      <S.NotificationItem>
        <span className='status' />
        <div className='content'>
          <p>It is time to <Link to='#'>submit your timesheet</Link>.</p>
        </div>
        <span className='time'>19 days ago</span>
      </S.NotificationItem>
      <S.NotificationItem>
        <span className='status' />
        <div className='content'>
          <p>It is time to <Link to='#'>submit your timesheet</Link>.</p>
        </div>
        <span className='time'>20 days ago</span>
      </S.NotificationItem>
      <S.NotificationItem>
        <span className='status' />
        <div className='content'>
          <p>It is time to <Link to='#'>submit your timesheet</Link>.</p>
        </div>
        <span className='time'>21 days ago</span>
      </S.NotificationItem>
      <S.NotificationItem>
        <span className='status' />
        <div className='content'>
          <p>It is time to <Link to='#'>submit your timesheet</Link>.</p>
        </div>
        <span className='time'>22 days ago</span>
      </S.NotificationItem>
      <S.NotificationItem>
        <span className='status' />
        <div className='content'>
          <p>It is time to <Link to='#'>submit your timesheet</Link>.</p>
        </div>
        <span className='time'>23 days ago</span>
      </S.NotificationItem>
      <S.NotificationItem>
        <span className='status' />
        <div className='content'>
          <p>It is time to <Link to='#'>submit your timesheet</Link>.</p>
        </div>
        <span className='time'>24 days ago</span>
      </S.NotificationItem>
      <S.NotificationItem>
        <span className='status' />
        <div className='content'>
          <p>It is time to <Link to='#'>submit your timesheet</Link>.</p>
        </div>
        <span className='time'>25 days ago</span>
      </S.NotificationItem>
      <S.NotificationItem>
        <span className='status' />
        <div className='content'>
          <p>It is time to <Link to='#'>submit your timesheet</Link>.</p>
        </div>
        <span className='time'>26 days ago</span>
      </S.NotificationItem>
      <S.NotificationItem>
        <span className='status' />
        <div className='content'>
          <p>It is time to <Link to='#'>submit your timesheet</Link>.</p>
        </div>
        <span className='time'>27 days ago</span>
      </S.NotificationItem>
      <S.NotificationItem>
        <span className='status' />
        <div className='content'>
          <p>It is time to <Link to='#'>submit your timesheet</Link>.</p>
        </div>
        <span className='time'>28 days ago</span>
      </S.NotificationItem>
      <div className='divider' />
      <S.NotificationItem isRead={true}>
        <span className='status'>
          <Check />
        </span>
        <div className='content'>
          <p>It is time to <Link to='#'>submit your timesheet</Link>.</p>
        </div>
        <span className='time'>2 days ago</span>
      </S.NotificationItem>
      <Box display='flex' justifyContent='center' mt={3}>
        <Button disabled>No more notifications to show</Button>
      </Box>
    </S.Container>
  )
}

export default Notifications