import styled from 'styled-components'
import { Box, Drawer as MuiDrawer } from '@material-ui/core'

const Container = styled(Box)`
  position: relative;
`

const MapHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span.time {
    color: ${props => props.theme.palette.dashboard.textLight};
  }

  ${props => props.theme.breakpoints.down('sm')} {
    margin-top: 20px;
  }
`

const Drawer = styled(MuiDrawer)`
  width: 400px;
  flex-shrink: 0;

  .MuiDrawer-paper {
    width: 400px;
    margin-top: 45px;
    overflow: visible;
    box-shadow: -3px -5px 15px 2px rgba(103, 99, 99, 0.2);
  }

  ${props => props.theme.breakpoints.down(500)} {
    width: 100%;

    .MuiDrawer-paper {
      width: 100%;
    }
  }
`

const DrawerOpenButton = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  width: 33px;
  height: 40px;
  padding-left: 12px;
  background-color: ${props => props.theme.colors.blue50};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  transition: all ease 0.3s;

  &:hover {
    opacity: 0.8;
  }

  svg {
    font-size: 20px;
    color: white;
  }
`

const DrawerContainer = styled.div`
  position: relative;
  padding: 25px 30px;
  background: white;

  .sort-select {
    span {
      color: ${props => props.theme.colors.blue50};
      font-weight: bold;
    }
  }

  .no-data-text {
    font-weight: bold;
    color: ${props => props.theme.palette.dashboard.textLight} !important;
    font-size: 15px;
  }

  .no-members {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 150px;
      margin: 40px 0 30px;
    }
  }

  .no-job-sites {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 90%;
      margin: 30px 0 0;
    }
  }

  .manage-job-sites {
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.blue50};
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;

    svg {
      color: ${props => props.theme.colors.blue50};
      margin-right: 10px;
      font-size: 20px;
    }
  }

  .ant-tabs {
    overflow: visible;
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;

    svg {
      font-size: 16px;
    }
  }

  ${props => props.theme.breakpoints.down(500)} {
    padding: 15px;
  }
`

const DrawerHideButton = styled.div`
  position: absolute;
  left: -40px;
  top: 83px;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  padding-left: 12px;
  background-color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  transition: all ease 0.3s;
  box-shadow: -6px 4px 10px -5px rgba(0,0,0,0.2);

  &:hover {
    opacity: 0.8;
  }

  svg {
    font-size: 20px;
    color: ${props => props.theme.colors.blue50};
  }
`

export {
  Container,
  MapHeader,
  Drawer,
  DrawerOpenButton,
  DrawerContainer,
  DrawerHideButton
}