import * as types from '../actionTypes'

const initialState = {
  widgets: {
    smallWidgets: [
      'Weekly activity',
      'Worked this week',
      'Earned this week',
      'Projects worked'
    ],
    largeWidgets: [
      'Recent activity',
      'Timesheet',
      'This week',
      'Projects',
      'To-dos',
      'Apps & URLs'
    ]
  },
  showTimeTrackerWidget: false
}

const dashboardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_WIDGETS:
      return {
        ...state,
        widgets: action.payload
      };
    case types.SET_SHOW_TIME_TRACKER_WIDGET:
      return {
        ...state,
        showTimeTrackerWidget: action.payload
      };
    default:
      return state;
  }
}

export default dashboardReducer
