import React, { ReactNode } from 'react'

import * as S from './styles'

interface ITab {
  id: string
  label: string
  icon: ReactNode
}

interface Props {
  tabs: ITab[]
  activeTabId: string
  onChangeTab: (id: string) => void
}

const Tab = ({ tabs, onChangeTab, activeTabId }: Props) => {
  return (
    <S.Container>
      {
        tabs.map((tab) => (
          <S.TabButton
            key={tab.id}
            active={activeTabId === tab.id}
            onClick={() => onChangeTab(tab.id)}
          >
            { tab.icon } {tab.label}
          </S.TabButton>
        ))
      }

    </S.Container>
  )
}

export default Tab
