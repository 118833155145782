import styled from 'styled-components'

const Container = styled.section`
  position: relative;
  overflow: hidden;
  padding: 175px 0 85px;
  background-color: ${(props) => props.theme.colors.blue300};

  .container {
    z-index: 3;
    text-align: center;

    h1 {
      font-size: 55px;
      line-height: 1;
      color: white;

      ${(props) => props.theme.breakpoints.down('xs')} {
        font-size: 40px;
      }
    }

    button {
      height: 70px !important;
      width: 225px !important;
      background-color: ${(props) => props.theme.colors.dark} !important;

      span {
        font-size: 17px !important;
        font-weight: 600 !important;
      }
    }

    ${(props) => props.theme.breakpoints.down(500)} {
      button {
        width: 100% !important;
      }
    }
  }

  .blue-circle {
    position: absolute;
    width: 90vw;
    height: 90vw;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.blue};
    z-index: -1;

    &.left {
      top: 5vw;
      left: -60vw;
    }

    &.right {
      bottom: -10vw;
      right: -65vw;
    }
  }

  &::before {
    position: absolute;
    bottom: calc(100% - 32px);
    left: calc(50% - 250vw);
    width: 500vw;
    height: 300vh;
    background: ${(props) => props.theme.colors.blue100};
    border-radius: 50%;
    content: '';
    display: block;
    z-index: 1;
  }
`

export { Container }
