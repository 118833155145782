import React from 'react'
import styled from 'styled-components'
import { Dropdown } from 'antd'
import { Bell } from 'react-feather'
import { Link } from 'react-router-dom'

import * as S from './styles'

const Container = styled.div`
  width: 350px;
  background: white;
  margin-top: 6px;
  margin-right: -20px;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
    color: ${props => props.theme.palette.dashboard.textPrimary};
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }

  .notification-list {
    .notification-item {
      color: ${props => props.theme.palette.dashboard.textPrimary};
      padding: 10px 20px;
      border-top: 1px solid ${props => props.theme.colors.gray100};

      &:last-child {
        border-bottom: 1px solid ${props => props.theme.colors.gray100};
      }
    }
  }

  a {
    color: ${props => props.theme.palette.dashboard.primary};
  }
`

const NotificationPopup = () => {
  return (
    <Dropdown
      overlay={
        <Container>
          <div className='header'>
            Notifications (59)
          </div>
          <div className='notification-list'>
            <div className='notification-item'>
              It is time to <Link to='#'>submit your timesheet</Link>.
            </div>
            <div className='notification-item'>
              It is time to <Link to='#'>submit your timesheet</Link>.
            </div>
            <div className='notification-item'>
              It is time to <Link to='#'>submit your timesheet</Link>.
            </div>
          </div>
          <div className='footer'>
            <Link to='/dashboard/notifications'>See all notifications</Link>
          </div>
        </Container>
      }
      placement='bottomRight'
      trigger={['click']}
    >
      <S.IconButton>
        <Bell />
        <span className='badge'>54</span>
      </S.IconButton>
    </Dropdown>
  )
}

export default NotificationPopup