import React from 'react'
import { Box } from '@material-ui/core'

import * as S from './styles'
import Button from '../../../../components/Dashboard/Button'
import ExpensesImg from '../../../../assets/images/dashboard/expenses.png'

const Expenses = () => {
  return (
    <S.Container>
      <h1>Expenses</h1>
      <p>Keep track of all your expenses. You can upload receipts, categorize expenses, connect them to projects or client budgets, and even include them in invoices.</p>
      <img src={ExpensesImg} alt='Expenses' />
      <Box mt={5}>
        <Button className='primary-contained'>
          Got it!
        </Button>
      </Box>
    </S.Container>
  )
}

export default Expenses