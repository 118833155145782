import React, { useState } from 'react'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import moment from 'moment'
import { ArrowBackIos } from '@material-ui/icons'

import { BackButton, IconButton, ItemWrapper, SubPanel } from '../styles'
import Button from '../../../../components/Dashboard/Button'
import ClockIcon from '../../../../assets/images/dashboard/clock-icon.png'
import SendIcon from '../../../../assets/images/dashboard/send-icon.png'
import ExportIcon from '../../../../assets/images/dashboard/export-icon.png'
import NoDataImg from '../../../../assets/images/dashboard/nothing.png'
import DateRange from '../../../../components/Dashboard/DateRange'
import { AppPage } from '../../../../components/Dashboard/CommonStyled'
import FilterSideMenu from '../FilterSideMenu'

// @ts-ignore
const Container = styled(AppPage)`
  .filter {
    display: flex;
    align-items: center;

    ${props => props.theme.breakpoints.down('xs')} {
      flex-direction: column;
      align-items: flex-end;

      > div:last-child {
        margin-top: 15px;
      }
    }

    ${props => props.theme.breakpoints.down(500)} {
      width: 100%;

      > div:first-child {
        width: 100%;

        .show-date {
          flex: 1;
        }
      }

      > div:last-child {
        width: 100%;

        > button, > div {
          flex: 1;
          max-width: 50%;

          button {
            width: 100%;
          }
        }
      }
    }
  }
`

const WeeklyReport = () => {
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate()
  })
  const [filterOpen, setFilterOpen] = useState(false)

  return (
    <Container>
      <Box mb={3}>
        <BackButton to='#'><ArrowBackIos /> Reports</BackButton>
      </Box>
      <Box mb={2} display='flex' justifyContent='space-between' alignItems='flex-end' flexWrap='wrap'>
        <h1>Weekly report</h1>
        <IconButton>
          <img src={ClockIcon} alt='Clock' />
          Scheduled reports
        </IconButton>
      </Box>
      <Box display='flex' justifyContent='flex-end'>
        <Box className='filter'>
          <DateRange
            showActionButtons={true}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
          <Box display='flex' ml='10px'>
            <Button className='outlined'>Today</Button>
            <Box ml={3}>
              <Button
                className='primary-contained'
                onClick={() => setFilterOpen(true)}
              >
                Filters
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <ItemWrapper gridTemplateColumns='1fr 1fr 1fr 1fr 1fr'>
        <div className='item'>
          <span className='label'>Projects worked</span>
          <h1>-</h1>
        </div>
        <div className='item'>
          <span className='label'>Break time</span>
          <h1>-</h1>
        </div>
        <div className='item'>
          <span className='label'>Avg.Hours per day</span>
          <h1>-</h1>
        </div>
        <div className='item'>
          <span className='label'>Avg.Activity</span>
          <h1>-</h1>
        </div>
        <div className='item'>
          <span className='label'>Earned</span>
          <h1>-</h1>
        </div>
      </ItemWrapper>
      <SubPanel>
        <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
          <Box display='flex' alignItems='flex-end' className='title'>
            <h2>Estey-Hoover, Inc</h2>
            <span>America - New York</span>
          </Box>
          <Box display='flex' className='actions'>
            <IconButton>
              <img src={SendIcon} alt='Send' />
              Send
            </IconButton>
            <IconButton>
              <img src={ClockIcon} alt='Schedule' />
              Schedule
            </IconButton>
            <IconButton>
              <img src={ExportIcon} alt='Export' />
              Export
            </IconButton>
          </Box>
        </Box>
        <div className='no-data-container'>
          <img src={NoDataImg} alt='Nothing' />
          <h3>Nothing to report</h3>
          <p>Expecting to see something? Try adjusting the report.</p>
        </div>
      </SubPanel>
      <FilterSideMenu
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
      />
    </Container>
  )
}

export default WeeklyReport