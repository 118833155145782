import React, { useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { InfoOutlined } from '@material-ui/icons'
import clsx from 'clsx'

import * as S from './styles'
import DatePicker from '../../../../../components/Dashboard/DatePicker'
import Input from '../../../../../components/Dashboard/Input'
import CreatInvoiceTable from './CreateInvoiceTable'
import Button from '../../../../../components/Dashboard/Button'

const useStyles = makeStyles(() => ({
  button: {
    marginRight: '10px',
  },
  draftButton: {
    borderColor: '#18d06b !important',
    backgroundColor: '#18d06b !important',
  }
}));

const CreateInvoice = () => {
  const classes = useStyles()
  const [issueDate, setIssueDate] = useState(new Date())
  const [dueDate, setDueDate] = useState(new Date())

  return (
    <S.Container>
      <Box mb={1}>
        <h1>New Invoice</h1>
      </Box>
      <Grid container>
        <Grid item xl={5} lg={12} xs={12}>
          <Box className='form-control' mb={2}>
            <span className='label'>From</span>
            <p>Nikita Alexandar</p>
          </Box>
        </Grid>
        <Grid item xl={7} lg={12} xs={12} className='filter'>
          <Box className='form-control' mb={2}>
            <span className='label'>Client</span>
            <p>Estey-Hoover, Inc</p>
          </Box>
          <Box display='flex' mb={2}>
            <Box className='form-control' mr={['15px', '30px']}>
              <span className='label'>Issue Date*</span>
              <DatePicker
                date={issueDate}
                setDate={setIssueDate}
              />
            </Box>
            <Box className='form-control'>
              <span className='label'>Due Date</span>
              <DatePicker
                date={dueDate}
                setDate={setDueDate}
              />
            </Box>
          </Box>
          <Box display='flex' mb={2}>
            <Box className='form-control' mr={['15px', '30px']}>
              <span className='label'>Invoice Number*</span>
              <Input />
            </Box>
            <Box className='form-control'>
              <span className='label'>Po Number</span>
              <Input />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box mb={2}>
        <CreatInvoiceTable />
      </Box>
      <Grid container>
        <Grid item xs={12} lg={12} xl={6} >
          <Box className='add-btn-group' display='flex' alignItems='center' mb={2} mt={[3, 0]}>
            <Button className={clsx('primary-contained', classes.button)}>Add item</Button>
            <Button className={classes.button}>Generate line items</Button>
            <InfoOutlined fontSize='small' />
          </Box>
          <Box className='form-control' pr={[0, 5]} mb={2}>
            <span className='label'>Notes (shown on invoices)</span>
            <S.StyledTextArea
              rows={4}
              placeholder='Enter notes to client'
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={12} xl={6}>
          <Box display='flex' justifyContent='flex-end' width='100%' pr={[0, '25px']}>
            <S.InfoList>
              <Box className='info-item'>
                <p className='label'>Total</p>
                <Box className='content'>
                  $0.00
                </Box>
              </Box>
              <Box className='info-item'>
                <p className='label'>Tax 1</p>
                <Box className='content'>
                  <Input placeholder='Enter tax %' />
                </Box>
              </Box>
              <Box className='info-item'>
                <p className='label'>Tax 2</p>
                <Box className='content'>
                  <Input placeholder='Enter tax %' />
                </Box>
              </Box>
              <Box className='info-item'>
                <p className='label'>Discount</p>
                <Box className='content'>
                  <Input placeholder='Enter discount %' />
                </Box>
              </Box>
              <Box className='info-item'>
                <p className='label'>Grand total</p>
                <Box className='content'>
                  $0.00
                </Box>
              </Box>
            </S.InfoList>
          </Box>
          <Box className='button-group' display='flex' justifyContent='flex-end' mt={3}>
            <Button className={classes.button}>Cancel</Button>
            <Button className={clsx(classes.button, classes.draftButton, 'primary-contained')}>Save as draft</Button>
            <Button className={clsx(classes.button, 'primary-contained')}>Save and send</Button>
          </Box>
        </Grid>
      </Grid>
    </S.Container>
  )
}

export default CreateInvoice