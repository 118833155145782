import React, { useState } from 'react'
import { Box, IconButton } from '@material-ui/core'
import { Select } from 'antd'
import { ArrowBackIos, ArrowForwardIos, Close, Search, Settings } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import * as S from './styles'
import Tab from '../../../components/Dashboard/Tab'
import Input from '../../../components/Dashboard/Input'
import Button from '../../../components/Dashboard/Button'
import { Tabs, TabContainer, AppPage } from '../../../components/Dashboard/CommonStyled'
import NoMembersImg from '../../../assets/images/dashboard/no-members.svg'
import NoJobSitesImg from '../../../assets/images/dashboard/no-job-sites.svg'

const { Option } = Select
const { TabPane } = Tabs

const tabs = [
  {
    id: '1',
    label: 'Live'
  },
  {
    id: '2',
    label: 'Past'
  }
]

const jobSiteTabs = [
  {
    id: '1',
    label: 'Visited'
  },
  {
    id: '2',
    label: 'All job sites'
  }
]

const useStyles = makeStyles(() => ({
  jobSitesButton: {
    width: '100%',
    marginTop: '20px'
  }
}));

const Map = () => {
  const classes = useStyles()
  const [activeTabId, setActiveTabId] = useState('1')
  const [activeJobSiteTabId, setActiveJobSiteTabId] = useState('1')
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <AppPage>
      <Box mb={2}>
        <h1>Map</h1>
      </Box>
      <TabContainer>
        <Tab
          tabs={tabs}
          activeTabId={activeTabId}
          onChangeTab={(id: string) => setActiveTabId(id)}
        />
      </TabContainer>
      <S.MapHeader>
        <Box ml={[0, 2]} width='100px'>
          <span className='time'><b>Update:</b> in 16s</span>
        </Box>
        <Select defaultValue="cest" bordered={false}>
          <Option value="cest">CEST</Option>
        </Select>
        <Box width='100px' />
        <S.DrawerOpenButton onClick={() => setDrawerOpen(true)}>
          <ArrowBackIos />
        </S.DrawerOpenButton>
      </S.MapHeader>
      <S.Drawer
        variant="temporary"
        anchor='right'
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        style={{ zIndex: 99 }}
      >
        <S.DrawerContainer>
          <S.DrawerHideButton onClick={() => setDrawerOpen(false)}>
            <ArrowForwardIos />
          </S.DrawerHideButton>
          <Tabs defaultActiveKey="1">
            <TabPane tab="MEMBERS" key="1">
              <Input
                prefix={<Search />}
                placeholder='Search members'
              />
              <Box mt={1}>
                <div className='sort-select'>
                  <span>Sort:</span>
                  <Select defaultValue="last-worked" bordered={false}>
                    <Option value="last-worked">Last Worked</Option>
                  </Select>
                </div>
              </Box>
              <div className='no-members'>
                <img src={NoMembersImg} alt='No Members' />
                <span className='no-data-text'>No members have recent locations</span>
              </div>
            </TabPane>
            <TabPane tab="JOB SITES" key="2">
              <Box pt={2} pb={1}>
                <Tab
                  tabs={jobSiteTabs}
                  activeTabId={activeJobSiteTabId}
                  onChangeTab={(id: string) => setActiveJobSiteTabId(id)}
                />
              </Box>
              <Box mt={1}>
                <Input
                  prefix={<Search />}
                  placeholder='Search job sites'
                />
              </Box>
              <Box mt={1}>
                <div className='sort-select'>
                  <span>Sort:</span>
                  <Select defaultValue="last-worked" bordered={false}>
                    <Option value="last-worked">Last Worked</Option>
                  </Select>
                </div>
              </Box>
              {
                activeJobSiteTabId === '1' &&
                  <Box pt={2}>
                    <p className='no-data-text'>No job sites have been visited for this day</p>
                  </Box>
              }
              {
                activeJobSiteTabId === '2' &&
                <div>
                  <div className='no-job-sites'>
                    <img src={NoJobSitesImg} alt='No Job Sites' />
                    <span className='no-data-text'>No job sites created yet</span>
                  </div>
                  <Button className={clsx('primary-contained', classes.jobSitesButton)}>
                    Add job site
                  </Button>
                </div>
              }
              <Box pt={2}>
                <div className='manage-job-sites'>
                  <Settings />
                  Manage job sites
                </div>
              </Box>
            </TabPane>
          </Tabs>
          <IconButton
            className='close-icon'
            size='small'
            onClick={() => setDrawerOpen(false)}
          >
            <Close />
          </IconButton>
        </S.DrawerContainer>
      </S.Drawer>
    </AppPage>
  )
}

export default Map