import React from 'react'
import { Dropdown } from 'antd'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Check } from '@material-ui/icons'

import Button from '../../../components/Dashboard/Button'
import { getWidgets } from '../../../store/selectors'
import { setWidgets } from '../../../store/actions/dashboard.actions'

const Menu = styled.div`
  position: relative;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .label {
    color: ${props => props.theme.palette.dashboard.textLight};
    font-size: 12px;
    margin-left: 25px;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    max-height: 400px;
    overflow-y: auto;
    margin-top: 180px;
  }
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 15px 0;
  background-color: ${props => props.theme.colors.gray100};
`

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 7px 15px;
  color: ${props => props.theme.palette.dashboard.textPrimary};
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;

  span.icon {
    display: flex;
    align-items: center;
    width: 23px;
    height: 23px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    background-color: ${props => props.theme.colors.gray100}50;
  }
`

const widgets = {
  smallWidgets: [
    'Earned this week',
    'Earned today',
    'Projects worked',
    'Today\'s activity',
    'Weekly activity',
    'Worked this week',
    'Worked today'
  ],
  largeWidgets: [
    'Apps & URLs',
    'Late & Missed Shifts',
    'Manual Time',
    'Payments',
    'Projects',
    'Recent activity',
    'This week',
    'Time off balances',
    'Time off requested',
    'Timesheet',
    'To-dos'
  ]
}

const ManageWidgets = () => {
  const dispatch = useDispatch()
  const selectedWidgets = useSelector(getWidgets)

  const handleChange = (e, group: string, name: string) => {
    e.stopPropagation()
    const index = selectedWidgets[group].indexOf(name)

    if (index > -1) {
      selectedWidgets[group].splice(index, 1)
    } else {
      selectedWidgets[group].push(name)
    }

    dispatch(setWidgets(JSON.parse(JSON.stringify(selectedWidgets))))
  }

  return (
    <Dropdown
      overlay={
        <Menu>
          <span className='label'>Small Widgets</span>
          {
            widgets.smallWidgets.map(name => (
              <MenuItem key={name} onClick={(e) => handleChange(e,'smallWidgets', name)}>
                <span className='icon'>
                  {selectedWidgets.smallWidgets.indexOf(name) > -1 ? <Check /> : ''}
                </span>
                {name}
              </MenuItem>
            ))
          }
          <Divider />
          <span className='label'>Large Widgets</span>
          {
            widgets.largeWidgets.map(name => (
              <MenuItem key={name} onClick={(e) => handleChange(e,'largeWidgets', name)}>
                <span className='icon'>
                  {selectedWidgets.largeWidgets.indexOf(name) > -1 ? <Check /> : ''}
                </span>
                {name}
              </MenuItem>
            ))
          }
        </Menu>
      }
      placement='bottomRight'
      trigger={['click']}
    >
      <Button>Manage widgets</Button>
    </Dropdown>
  )
}

export default ManageWidgets