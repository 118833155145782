import React from 'react'
import { Box } from '@material-ui/core'
import { StarRateRounded } from '@material-ui/icons'

import * as S from './styles'
import { IconButton } from '../styles'
import ClockIcon from "../../../../assets/images/dashboard/clock-icon.png";
import GeneralIcon from '../../../../assets/images/dashboard/general-icon.png'
import PaymentIcon from '../../../../assets/images/dashboard/payment-icon.png'
import BudgetsIcon from '../../../../assets/images/dashboard/calculator-icon.png'
import TimeOffIcon from '../../../../assets/images/dashboard/time-off-icon.png'
import InvoiceIcon from '../../../../assets/images/dashboard/invoice-icon.png'
import ScheduleIcon from '../../../../assets/images/dashboard/schedule-icon.png'
import JobSitesIcon from '../../../../assets/images/dashboard/job-sites-icon.png'

const data = [
  {
    title: 'General',
    icon: GeneralIcon,
    items: [
      {
        title: 'Time & activity',
        description: 'See team members\' time worked, activity levels, and amounts earned per project or to-do',
        favorite: true
      },
      {
        title: 'Weekly',
        description: 'See team members\' time worked, activity levels, and amount earned per week',
        favorite: true
      },
      {
        title: 'App & URLs',
        description: 'See team members\' apps used and URLs visited while working'
      },
      {
        title: 'Manual time edits',
        description: 'See team members\' time worked, to-do, and reason for each manual time entry'
      },
      {
        title: 'Timesheet approvals',
        description: 'See team members\' timesheets and their status'
      },
      {
        title: 'Expenses',
        description: 'See how much has been spent on expenses by member and project'
      },
      {
        title: 'Work breaks',
        description: 'See how many work breaks team members are taking'
      },
    ]
  },
  {
    title: 'Payment',
    icon: PaymentIcon,
    items: [
      {
        title: 'Amounts owed',
        description: 'See how much the hourly paid team members are currently owed',
        favorite: true,
      },
      {
        title: 'Payments',
        description: 'See how much team members were paid over a given period',
        favorite: true,
      }
    ]
  },
  {
    title: 'Budgets and limits',
    icon: BudgetsIcon,
    items: [
      {
        title: 'Weekly limits',
        description: 'See team members\' weekly limits usage'
      },
      {
        title: 'Daily limits',
        description: 'See team members\' daily limits usage'
      }
    ]
  },
  {
    title: 'Time off',
    icon: TimeOffIcon,
    items: [
      {
        title: 'Time off balances',
        description: 'See your team\'s time off balances across the organization\'s time off policies'
      },
      {
        title: 'Time off transactions',
        description: 'See your team\'s time off transactions across the organization\'s time off policies'
      }
    ]
  },
  {
    title: 'Invoice',
    icon: InvoiceIcon,
    items: [
      {
        title: 'Team invoices',
        description: 'See team member invoice totals, paid, and due amounts'
      },
      {
        title: 'Team invoices aging',
        description: 'See outstanding and past due team member invoices'
      }
    ]
  },
  {
    title: 'Schedule',
    icon: ScheduleIcon,
    items: [
      {
        title: 'Shift attendance',
        description: 'See team members\' completed, late, abandoned, and missed shifts'
      }
    ]
  },
  {
    title: 'Job sites',
    icon: JobSitesIcon,
    items: [
      {
        title: 'Visits',
        description: 'See when your team members\' entered and left a job site'
      }
    ]
  }
]

const AllReports = () => {
  return (
    <S.Container>
      <Box mb={2} display='flex' justifyContent='space-between' alignItems='flex-end'>
        <h1>Reports</h1>
        <IconButton>
          <img src={ClockIcon} alt='Clock' />
          Scheduled reports
        </IconButton>
      </Box>
      {
        data.map(block => (
          <S.Block key={block.title}>
            <div className='title'>
              <div className='icon'>
                <img src={block.icon} alt={block.title} />
              </div>
              <h2>{block.title}</h2>
            </div>
            <div className='item-list'>
              {
                block.items.map((item) => (
                  <S.Item key={item.title}>
                    <div className='header'>
                      <h3>{item.title}</h3>
                      { item.favorite && <StarRateRounded /> }
                    </div>
                    <p>{item.description}</p>
                  </S.Item>
                ))
              }
            </div>
          </S.Block>
        ))
      }
    </S.Container>
  )
}

export default AllReports