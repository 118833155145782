import React from 'react'

import * as S from './styles'
import { HubStaffIcon, MessageIcon } from '../../Common/Images'

const MessagePopupAnimation = () => {
  return (
    <S.Container>
      <div className='message-card'>
        <div className='header'>
          <div>
            <p>TimeGo</p>
          </div>
          <span>now</span>
        </div>
        <div className='body'>
          <p>Mike has arrived at the 'Safeway' job site.</p>
        </div>
      </div>
      <div className='message-card'>
        <div className='header'>
          <div>
            <img src={MessageIcon} alt='TimeGo Icon' />
            <p>Mail</p>
          </div>
          <span>now</span>
        </div>
        <div className='body'>
          <p className='bold'>TimeGo</p>
          <p>James is 30 minutes late for his shift.</p>
        </div>
      </div>
    </S.Container>
  )
}

export default MessagePopupAnimation
