import React from 'react'
import { Dropdown, Menu, Button } from 'antd'
import { makeStyles } from '@material-ui/styles'
import { DownOutlined } from '@ant-design/icons'

const useStyles = makeStyles(() => ({
  button: {
    border: '1px solid #e3e8ef !important',
    borderRadius: '5px !important',
    padding: '3px 11px !important',
    backgroundColor: 'white',
    color: 'lightgray'
  }
}));

const TableActionMenu = () => {
  const classes = useStyles()

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item>
            Edit
          </Menu.Item>
          <Menu.Item>
            Delete
          </Menu.Item>
        </Menu>
      }
      placement='bottomRight'
      trigger={['click']}
    >
      <Button
        className={classes.button}
      >
        Actions <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default TableActionMenu