import React from 'react'
import { addDays } from 'date-fns'
import moment from 'moment-timezone'
import { Box } from '@material-ui/core'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Switch } from 'antd'

import * as S from './styles'
import { IDateRange } from '../../../../components/Dashboard/DateRange'

interface Props {
  dateRange: IDateRange
}

const Members = (props: Props) => {
  const { dateRange } = props

  return (
    <S.MemberScheduleContainer>
      <div className='column members'>
        <div className='item header'>
          <Box display='flex' flexDirection='column'>
            <h4>Members</h4>
            <Box>
              <span className='label'>Show unscheduled</span>
              <Switch
                size='small'
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Box>
          </Box>
        </div>
        <Box p={1} />
      </div>
      {
        new Array(7).fill(0).map((_, index) => {
          const date = addDays(dateRange.startDate, index)
          const isToday = moment().isSame(date, 'day') ? 'today' : ''
          return (
            <React.Fragment key={index}>
              <div className={`column date ${isToday}`}>
                <div className='item date-label'>
                  <h3>{moment(date).format('DD')}</h3>
                  <div>
                    <span className='day'>{moment(date).format('ddd')}</span>
                    <span className='month'>{moment(date).format('MMM')}</span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )
        })
      }
    </S.MemberScheduleContainer>
  )
}

export default Members