import styled from 'styled-components'

const Container = styled.section`
  position: relative;
  background-color: ${(props) => props.theme.colors.blue100};
  min-height: 500px;
  padding: 75px 0;

  .container {
    overflow: hidden;

    .sub-title {
      text-align: center;
      font-size: 13px;
      font-weight: bold;
      color: ${(props) => props.theme.colors.blue};
      text-transform: uppercase;
    }

    h1 {
      font-size: 45px;
      text-align: center;
      max-width: 850px;
      line-height: 1;
      margin: 0 auto;
    }

    .customers-say {
    }

    .reviews {
      display: flex;
      justify-content: center;

      > div:nth-child(2) {
        border-left: 1px solid ${props => props.theme.colors.gray100};
        border-right: 1px solid ${props => props.theme.colors.gray100};
      }
    }
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: 40px 0;

    h1 {
      font-size: 35px !important;
    }
  }

  ${(props) => props.theme.breakpoints.down(550)} {
    .reviews {
      flex-direction: column;

      > div:nth-child(2) {
        border-left: none !important;
        border-right: none !important;
      }
    }
  }
`

const ReviewItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  text-align: center;

  .logo {
    margin-bottom: 10px;
  }

  .ant-rate {
    font-size: 16px !important;
  }
`

export { Container, ReviewItem }
