import React, { useState } from 'react'
import styled from 'styled-components'
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink, withRouter } from "react-router-dom";
import {
  List as MuiList,
  Collapse as MuiCollapse,
  ListItemText,
  ListItem,
  ListItemProps
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useLocation } from 'react-router'

import * as S from './styles'
import { Logo } from '../../Common/Icons'
import { dashboardLayoutRoutes } from '../../../routes/Routes'

const Scrollbar = styled(PerfectScrollbar)`
  background-color: transparent;
`;

const List = styled(MuiList)`
  background-color: transparent;
`;

const Items = styled.div`
  padding-top: ${props => props.theme.spacing(2.5)}px;
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`;

// const NavLink = React.forwardRef((props, ref: ForwardedRef<HTMLAnchorElement | null>) => (
//   <RouterNavLink innerRef={ref} {...props} />
// ));

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: #617083;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  transform: rotate(-90deg);
`;

const CategoryIconMore = styled(ExpandMore)`
  color: #617083;
`;

interface LinkProps extends ListItemProps {
  activeClassName: string
  [x: string]: any
}

const Link = styled(ListItem)<LinkProps>`
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px !important;
  border-radius: 20px !important;

  span {
    color: #617083;
  }

  &:hover span {
    color: ${props => props.theme.colors.blue50};
  }

  &.${props => props.activeClassName} {
    background-color: #dbdde3;

    span {
      color: ${props => props.theme.colors.blue50};
    }
  }
`;

const Category = styled(ListItem)<LinkProps>`
  position: relative;
  padding: 5px 20px 5px 25px !important;
  margin: 0 0 20px !important;
  font-weight: ${props => props.theme.typography.fontWeightRegular};

  svg {
    color: #617083;
    font-size: 20px;
    width: 20px;
    height: 20px;
  }

  span {
    margin-left: 10px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);

    span, svg {
      color: ${props => props.theme.colors.blue50};
    }
  }

  &.${props => props.activeClassName} {
    span, svg {
      color: ${props => props.theme.colors.blue50};
    }

    ::before {
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 100%;
      background-color: ${props => props.theme.colors.blue50};
      content: ''
    }
  }

  &.open {
    span, svg {
      color: ${props => props.theme.colors.blue50};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: darkblue;
  span {
    font-size: 13px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const Collapse = styled(MuiCollapse)`
  //margin-top: -10px;
  padding: 0 20px 0 40px;
`

function SidebarCategory(props) {
  const { name, icon, classes, isOpen, isCollapsable, badge, ...rest } = props
  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
    </Category>
  );
}

interface ISidebarLink {
  name: string
  to: string
}

function SidebarLink({ name, to }: ISidebarLink) {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
    </Link>
  );
}

const DashboardSidebar = (props) => {
  const location = useLocation()

  const pathname = location.pathname
  const initialMenu = {}
  dashboardLayoutRoutes.forEach((route, index) => {
    initialMenu[index] = pathname.indexOf(route.path) === 0
  })

  const [menus, setMenus] = useState(initialMenu)

  const toggle = index => {
    // Collapse all elements
    const updatedMenus = {...menus}
    Object.keys(updatedMenus).forEach(
      item => {
        if (updatedMenus[item]) {
          updatedMenus[item] = false
        }
      }
    )

    // Toggle selected element
    updatedMenus[index] = !menus[index]
    setMenus(updatedMenus)
  };

  return (
    <S.Drawer variant="permanent" {...props}>
      <S.Logo to='/'><Logo /></S.Logo>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {dashboardLayoutRoutes.map((category, index) => (
              <React.Fragment key={index}>
                {category.children ? (
                  <React.Fragment key={index}>
                    <SidebarCategory
                      isOpen={menus[index]}
                      isCollapsable={true}
                      name={category.name}
                      button={true}
                      icon={category.icon}
                      className={menus[index] ? 'open' : 'closed'}
                      onClick={() => toggle(index)}
                    />
                    <Collapse
                      in={menus[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {category.children.map((route, index) => (
                        route.name &&
                        <SidebarLink
                          key={index}
                          name={route.name}
                          to={route.path}
                        />
                      ))}
                    </Collapse>
                  </React.Fragment>
                ) : (
                  category.name && <SidebarCategory
                    isCollapsable={false}
                    name={category.name}
                    to={category.path}
                    activeClassName="active"
                    component={NavLink}
                    icon={category.icon}
                    exact
                  />
                )}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>
    </S.Drawer>
  )
}

export default withRouter(DashboardSidebar)