import styled, { keyframes } from 'styled-components'

const animation1 = keyframes`
  0% {
    transform: translateY(-87px);
    opacity: 0;
  }
  50% {
    transform: translateY(-87px);
    opacity: 0;
  }
  55% {
    transform: translateY(0);
    opacity: 1;
  }
  95% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
`;

const animation2 = keyframes`
  0% {
    transform: translateY(-87px);
    opacity: 0;
  }
  5% {
    transform: translateY(-87px);
    opacity: 1;
  }
  50% {
    transform: translateY(-87px);
  }
  55% {
    transform: translateY(0);
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Container = styled.div`

  .message-card {
    width: 300px;
    background-color: #efeef0;
    border-radius: 10px;
    padding: 8px;
    box-shadow: 0 0 10px 3px rgba(23, 39, 68, 0.18);
    margin-bottom: 10px;

    .header {
      display: flex;
      justify-content: space-between;

      > div {
        display: flex;
      }

      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }

      p {
        font-size: 12px;
        color: gray;
        margin: 0;
      }

      span {
        font-size: 11px;
        color: gray;
      }
    }

    .body {
      padding: 5px;
      margin-top: 10px;

      p {
        font-size: 12px;
        margin-bottom: 5px;

        &.bold {
          font-weight: 600;
        }
      }
    }

    &:first-child {
      animation: ${animation1} 7s;
      animation-iteration-count: infinite;
    }

    &:last-child {
      animation: ${animation2} 7s;
      animation-iteration-count: infinite;
    }
  }
`

export { Container }
