import styled from 'styled-components'

const Container = styled.div`
  padding: 50px 0;

  .owl-carousel {
    .owl-item {
      transition: all ease 0.3s;
      transform: scale(0.8);
      opacity: 0.8;

      &.active.center {
        transform: scale(1);
        opacity: 1;
      }
    }

    .owl-dots {
      display: flex;
      justify-content: center;
      margin-top: 50px !important;

      .owl-dot {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        margin: 0 5px;

        span {
          width: 5px;
          height: 5px;
        }

        &.active {
          span {
            background: ${(props) => props.theme.colors.dark};
          }
        }
      }
    }
  }
`

const CarouselItem = styled.div`
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  padding: 30px;
  height: 300px;

  .title {
    font-size: 20px;
    margin-bottom: 15px;
    line-height: 1.3;
  }

  .speech {
    font-size: 14px;
    margin-bottom: 15px;

    ${(props) => props.theme.breakpoints.down('md')} {
      overflow: hidden;
      white-space: pre-wrap;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      display: -moz-box;
      -moz-line-clamp: 5;
      -moz-box-orient: vertical;
    }
  }

  .name {
    font-size: 14px;
    font-weight: bold;
  }
`

export { Container, CarouselItem }
