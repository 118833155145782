import React from 'react'
import { DownOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Box, ClickAwayListener, Fade } from '@material-ui/core'

import { MenuButton, SubMenuContainer } from '../styles'
import * as S from './styles'

interface Props {
  active: boolean
  setActive: (menu: string) => void
}

const menu = [
  {
    title: 'Time tracking',
    description: 'Track time how you want. Get the insights you need.'
  },
  {
    title: 'Online timesheets',
    description: 'Accurate, detailed timesheets that you can easily export.'
  },
  {
    title: 'Geofencing',
    description: 'Automated work tracking based on custom locations.'
  },
  {
    title: 'Payroll software',
    description: 'Automate and send payments in a snap with TimeGo.'
  },
  {
    title: 'Employee monitoring',
    description: 'App and URL tracking, optional screenshots and more.'
  },
  {
    title: 'Productivity measurement',
    description: 'Built-in efficiency tools to help teams work smarter.'
  },
  {
    title: 'GPS tracking',
    description: 'Smarter work tracking for teams on the go.'
  },
  {
    title: 'Online invoicing',
    description: 'Get paid faster with accurate, automatic invoices.'
  },
  {
    title: 'Project budgeting',
    description: 'Add budget limits and get automatic alerts for any project.'
  },
  {
    title: 'Employee scheduling',
    description: 'One smooth process form shift planning to clocking in.'
  },
  {
    title: 'Detailed reporting',
    description: 'Dig into business operations with detailed reports.'
  },
  {
    title: 'Mobile time tracking',
    description: "Track your team's attendance, get accurate time cards, and more."
  },
  {
    title: 'Work orders & jobs',
    description: 'Organize crucial details in one place and assign jobs.'
  },
]

const FeaturesMenu = ({ active, setActive }: Props) => {
  return (
    <Box position='relative'>
      <MenuButton onClick={() => setActive(!active ? 'features' : '')} isOpen={active}>
        <span>Features</span>
        <DownOutlined />
      </MenuButton>
      <Fade in={active}>
        <Box>
          { active &&
            <ClickAwayListener onClickAway={() => setActive('')}>
              <SubMenuContainer left={-40}>
                <S.Body>
                  {
                    menu.map((item) => (
                      <S.MenuItem key={item.title}>
                        <h3>{ item.title }</h3>
                        <p>{ item.description }</p>
                      </S.MenuItem>
                    ))
                  }
                </S.Body>
                <S.Footer>
                  <h3>All Features</h3>
                  <ArrowRightOutlined />
                </S.Footer>
              </SubMenuContainer>
            </ClickAwayListener>
          }
        </Box>
      </Fade>
    </Box>
  )
}

export default FeaturesMenu
