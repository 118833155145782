import * as types from '../actionTypes'

export const setWidgets = (value: object) => {
  return {
    type: types.SET_WIDGETS,
    payload: value
  }
}

export const showTimeTrackerWidget = (value: boolean) => {
  return {
    type: types.SET_SHOW_TIME_TRACKER_WIDGET,
    payload: value
  }
}
