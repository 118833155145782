import React from 'react'
import { Add } from '@material-ui/icons'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'

import { Card } from '../styles'
import * as S from '../styles'
import Button from '../../../../components/Dashboard/Button'

// @ts-ignore
const Container = styled(Card)`
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  transition: all ease 0.3s;

  .add-btn {
    display: none;
  }

  .card-body {
    flex: 1;
  }

  .shift-list {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    height: 100%;

    .shift-item {
      display: flex;
      flex: 1;
      width: 100%;
      padding: 15px 0;
      border-bottom: 2px solid #d8d8d8;

      > div {
        width: 50%;

        .content {
          height: 15px;
          background-color: #c3c5c5;
        }
      }

      .left {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 30px;

        > div {
          width: 20px;
          margin-left: 20%;
        }
      }
    }
  }

  &:hover {
    background-color: white;

    .shift-list {
      .shift-item {
        .right {
          .content {
            background-color: ${props => props.theme.colors.blue50};
          }
        }
      }
    }

    .plus-btn {
      display: none;
    }

    .add-btn {
      display: inline-block;
    }
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0;

  .title {
    font-size: 12px;
    text-transform: uppercase;
    color: ${props => props.theme.palette.dashboard.textLight};
  }

  .sub-title {
    font-size: 10px;
    color: ${props => props.theme.palette.dashboard.textLight};
  }
`

const SortItem = SortableElement(props => <Container {...props} />)
const DragHandle = SortableHandle(props => <Header {...props} />)

const Shifts = (props) => {
  const { index } = props
  return (
    <SortItem index={index}>
      <DragHandle>
        <Box display='flex' flexDirection='column'>
          <span className='title'>Late & Missed Shifts</span>
          <span className='sub-title'>Team members who are late or have missed shifts.</span>
        </Box>
        <Box>
          <S.IconButton className='plus-btn'><Add /></S.IconButton>
          <Button className='add-btn primary-contained'>Schedule shifts</Button>
        </Box>
      </DragHandle>
      <div className='card-body'>
        <div className='shift-list'>
          <div className='shift-item'>
            <div className='left'>
              <Box width='20%' className='content' />
              <Box width='60%' className='content' />
            </div>
            <div className='right'>
              <div className='content' />
              <div className='content' />
            </div>
          </div>
          <div className='shift-item'>
            <div className='left'>
              <Box width='20%' className='content' />
              <Box width='60%' className='content' />
            </div>
            <div className='right'>
              <div className='content' />
              <div className='content' />
            </div>
          </div>
          <div className='shift-item'>
            <div className='left'>
              <Box width='20%' className='content' />
              <Box width='60%' className='content' />
            </div>
            <div className='right'>
              <div className='content' />
              <div className='content' />
            </div>
          </div>
        </div>
      </div>
    </SortItem>
  )
}

export default Shifts