import styled from 'styled-components'
import { Box } from '@material-ui/core'

const Container = styled(Box)`
`

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.gray100};
  padding: 15px;
  font-weight: bold;
  margin-top: 30px;

  > div {
    white-space: nowrap;
  }
`

const NoDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;

  img {
    height: 230px;
    margin-bottom: 20px;
  }

  p {
    font-weight: bold;
    color: ${props => props.theme.palette.dashboard.textLight} !important;
    font-size: 15px;
  }
`

const FilterGroup = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .left {
    display: flex;
    align-items: center;
    padding-top: 20px;
  }

  .right {
    display: flex;
    flex-direction: column;
  }

  ${props => props.theme.breakpoints.down(1430)} {
    flex-direction: column;

    .right {
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 20px;

      > div:last-child {
        margin-left: 20px;
      }
    }
  }

  ${props => props.theme.breakpoints.down(1330)} {
    .right {
      flex-direction: column;

      > div:last-child {
        margin-top: 20px;
        margin-left: 0;
        justify-content: flex-start;
      }
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    .right {
      flex-direction: column;
      width: 100%;

      > div:first-child {
        flex-direction: column;
      }

      > div:last-child {
        justify-content: flex-start;
      }

      .group {
        margin-top: 20px;

        &:first-child {
          margin: 0;
        }

        > div {
          flex: 1;
        }
      }
    }
  }

  ${props => props.theme.breakpoints.down('xs')} {
    .right {
      margin-top: 0;

      > div:last-child {
        margin-top: 0;
      }

      .group {
        flex-direction: column;
        margin: 0;

        > div {
          margin-top: 20px;
          margin-left: 0;
        }
      }
    }
  }

  ${props => props.theme.breakpoints.down(450)} {
    .left {
      width: 100%;
      justify-content: space-between;
      padding-top: 0;

      > div:first-child {
        flex: 1;
        min-width: 150px;

        .show-date {
          width: 100%;
        }
      }

      button {
        padding: 7px 15px !important;
      }
    }
  }
`

export { Container, TableHeader, NoDataContainer, FilterGroup }