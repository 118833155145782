import styled from 'styled-components'

import { AppPage } from '../../../../components/Dashboard/CommonStyled'

// @ts-ignore
const Container = styled(AppPage)`
  .search-wrapper {
    ${props => props.theme.breakpoints.down('xs')} {
      span {
        max-width: 100%;
      }
    }
  }
`

export { Container }