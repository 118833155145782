import styled from 'styled-components'

import Input from '../../../components/Common/Input'

const Container = styled.div`
  display: flex;
  min-height: 100vh;

  .hsds-signup__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;

    .signup__container-inner {
      max-width: 390px;

      h1 {
        font: 46px "Proxima Nova Regular";
        line-height: 48px;
        margin: 0 0 24px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: unset;
      }

      p {
        color: #666e86;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        margin: 0 0 24px;
      }

      .sign-up-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px 0;

        .ant-alert {
          width: 100%;
          border-radius: 5px;
          margin-bottom: 15px;
        }

        .form-control {
          width: 100%;
          margin-bottom: 22px;

          span {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 1.25;
            display: block;
            margin: 0 0 12px;
            text-align: left;
          }
        }

        a {
          font-size: 12px;
          font-weight: 700;
          line-height: 22px;
          color: ${(props) => props.theme.colors.dark};

          &:hover {
            color: ${(props) => props.theme.colors.blue};
          }
        }
      }
    }

    .form-footer {
      //display: flex;
      width: 100%;
      align-items: center;
      color: rgba(37,48,83,.7);
      font-family: lato,sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25;
      display: block;
      margin: 0 0 12px;

      svg {
        width: 18px;
        height: 18px;
      }

      a {
        font-weight: 700;
        color: #0000EE;
        font-size: 14px;
      }
    }
  }

  .hsds-signup__illustration {
    flex: 1;
    background-color: ${(props) => props.theme.colors.blue};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    min-height: unset;

    .hsds-signup__container {
      h1 {
        font-size: 32px !important;
      }
    }

    .hsds-signup__illustration {
      display: none;
    }
  }
`

const FormInput = styled(Input)`
  &.ant-input {
    height: 56px !important;
    background-color: #f4f4f4;
    border-color: #f4f4f4;
    font-size: 16px !important;
    padding: 17px 16px !important;
  }
`

export { Container, FormInput }
