import React, { useState } from 'react'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'

import { SectionTitle, FormControl } from './styles'
import { Tooltip } from 'antd'
import Tab from '../../../components/Dashboard/Tab'

const Container = styled(Box)`
  padding-left: ${props => props.theme.spacing(2)}px;

  ${props => props.theme.breakpoints.down('xs')} {
    padding-left: 0;
  }
`

const workSummaries = [
  {
    id: 'daily',
    label: 'Daily'
  },
  {
    id: 'weekly',
    label: 'Weekly'
  },
  {
    id: 'never',
    label: 'Never'
  },
]

const jobSiteNotifications = [
  {
    id: 'mobile',
    label: 'Mobile'
  },
  {
    id: 'none',
    label: 'None'
  }
]

const betaTesters = [
  {
    id: 'release',
    label: 'Release'
  },
  {
    id: 'beta',
    label: 'Beta'
  },
  {
    id: 'alpha',
    label: 'Alpha'
  },
]

const dateFormats = [
  {
    id: 'm/d/y',
    label: 'm/d/y (e.g 7/31/2017)'
  },
  {
    id: 'd/m/y',
    label: 'd/m/y (e.g 31/7/2017)'
  },
  {
    id: 'y/m/d',
    label: 'y/m/d (e.g 2017/7/31)'
  },
  {
    id: 'y-m-d',
    label: 'y-m-d (e.g 2017-7-31)'
  },
  {
    id: 'pretty',
    label: 'Pretty (e.g Mon, Jul 31. 2017)'
  },
]

const timeFormats = [
  {
    id: '12-hour',
    label: '12-hour (e.g 4:10pm)'
  },
  {
    id: '24-hour',
    label: '24-hour (e.g 16:10)'
  },
  {
    id: '12-hour with seconds',
    label: '12-hour with seconds (e.g 4:10:00 pm)'
  },
  {
    id: '24-hour with seconds',
    label: '24-hour with seconds (e.g 16:10:00)'
  },
]

const durationFormats = [
  {
    id: 'decimal',
    label: 'Decimal (e.g. 1.25)'
  },
  {
    id: 'HH:MM',
    label: 'HH:MM (e.g. 1:15)'
  },
  {
    id: 'HH:MM:SS',
    label: 'HH:MM:SS (e.g. 1:15:00)'
  }
]

const SettingPanel = () => {
  const [workSummary, setWorkSummary] = useState('never')
  const [jobSiteNotification, setJobSiteNotification] = useState('mobile')
  const [betaTester, setBetaTester] = useState('release')
  const [dateFormat, setDateFormat] = useState('pretty')
  const [timeFormat, setTimeFormat] = useState('12-hour')
  const [durationFormat, setDurationFormat] = useState('decimal')

  return (
    <Container pt={3}>
      <SectionTitle>Disabled Notifications</SectionTitle>
      <p>To disable a notification, click the link in the bottom of the email.</p>
      <Box mt={3}>
        <SectionTitle>Other</SectionTitle>
        <FormControl>
          <span className='label'>Send work summaries <Tooltip overlay={'Send work summaries'}><InfoOutlined /></Tooltip></span>
          <Box display='flex' mb={1}>
            <Tab
              tabs={workSummaries}
              activeTabId={workSummary}
              onChangeTab={(id: string) => setWorkSummary(id)}
            />
          </Box>
        </FormControl>
        <FormControl>
          <span className='label'>Job site enter/exit notifications <Tooltip overlay={'Job site enter/exit notifications'}><InfoOutlined /></Tooltip></span>
          <Box display='flex' mb={1}>
            <Tab
              tabs={jobSiteNotifications}
              activeTabId={jobSiteNotification}
              onChangeTab={(id: string) => setJobSiteNotification(id)}
            />
          </Box>
        </FormControl>
        <FormControl>
          <span className='label'>Beta tester <Tooltip overlay={'Beta tester'}><InfoOutlined /></Tooltip></span>
          <Box display='flex' mb={1}>
            <Tab
              tabs={betaTesters}
              activeTabId={betaTester}
              onChangeTab={(id: string) => setBetaTester(id)}
            />
          </Box>
        </FormControl>
        <FormControl>
          <span className='label'>Date format <Tooltip overlay={'Date format'}><InfoOutlined /></Tooltip></span>
          <Box display='flex' mb={1}>
            <Tab
              tabs={dateFormats}
              activeTabId={dateFormat}
              onChangeTab={(id: string) => setDateFormat(id)}
            />
          </Box>
        </FormControl>
        <FormControl>
          <span className='label'>Time format <Tooltip overlay={'Time format'}><InfoOutlined /></Tooltip></span>
          <Box display='flex' mb={1}>
            <Tab
                tabs={timeFormats}
                activeTabId={timeFormat}
                onChangeTab={(id: string) => setTimeFormat(id)}
            />
          </Box>
        </FormControl>
        <FormControl>
          <span className='label'>Duration format <Tooltip overlay={'Duration format'}><InfoOutlined /></Tooltip></span>
          <Box display='flex' mb={1}>
            <Tab
                tabs={durationFormats}
                activeTabId={durationFormat}
                onChangeTab={(id: string) => setDurationFormat(id)}
            />
          </Box>
        </FormControl>
      </Box>
    </Container>
  )
}

export default SettingPanel