import React from 'react'
import { Box } from '@material-ui/core'
import { InfoOutlined, MoreVert } from '@material-ui/icons'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'
import { Dropdown, Menu } from 'antd'

import { SectionTitle, Tooltip, TableHeader } from './styles'
import Button from '../../../components/Dashboard/Button'
import PaymentAccountModal from '../../../components/Dashboard/PaymentAccountModal'
import PaypalIcon from '../../../assets/images/dashboard/paypal.png'
import { IconButton } from '../Default/styles'

const Container = styled(Box)`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${props => props.theme.breakpoints.down(500)}{
      flex-direction: column;
      align-items: flex-start;

      button {
        width: 100%;
        margin-top: 15px;
      }
    }
  }

  .payment-account-item {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 400px;
    margin-right: 20px;
    padding: 20px;
    border: 1px solid ${props => props.theme.colors.gray100};
    border-radius: 10px;

    .icon {
      margin-right: 20px;
    }

    .content {
      .payment-name {
        color: ${props => props.theme.palette.dashboard.textDark};
        font-weight: 600;
      }

      .email {
        color: ${props => props.theme.palette.dashboard.textLight};
      }
    }

    .menu-btn {
      position: absolute;
      right: 20px;
    }
  }
`

const NoDataText = styled.h3 `
  font-weight: bold;
  color: ${props => props.theme.palette.dashboard.textLight};
`

const useStyles = makeStyles(() => ({
  dropdown: {
    '& .ant-dropdown-menu-item': {
      padding: '8px 16px'
    }
  }
}))

const PaymentAccountItem = () => {
  const classes = useStyles()

  return (
    <Box className='payment-account-item'>
      <img className='icon' src={PaypalIcon} alt='Payment Icon' />
      <Box className='content'>
        <p className='payment-name'>PayPal</p>
        <span className='email'>harry1207@gmail.com</span>
      </Box>
      <Dropdown
        overlay={
          <Menu className={classes.dropdown}>
            <Menu.Item>
              Reauthenticate
            </Menu.Item>
            <Menu.Item>
              Delete payment account
            </Menu.Item>
          </Menu>
        }
        placement='bottomRight'
        trigger={['click']}
      >
        <IconButton size='small' className='menu-btn'><MoreVert /></IconButton>
      </Dropdown>
    </Box>
  )
}

const PaymentAccounts = () => {
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Container pt={3}>
      <Box className='header'>
        <Box display='flex' alignItems='center'>
          <SectionTitle className='mb-0'>Default payment accounts</SectionTitle>&nbsp;
          <Tooltip overlay={'Default payment accounts'} placement='bottom'><InfoOutlined fontSize='small'/></Tooltip>
        </Box>
        <Button className='primary-contained' onClick={handleOpen}>Add account</Button>
      </Box>
      <Box className='payment-account' py={2}>
        <PaymentAccountItem />
        {/*<NoDataText>No default payment accounts</NoDataText>*/}
      </Box>
      <Box className='payroll-accounts' py={2}>
        <Box display='flex' alignItems='center' mb={3}>
          <SectionTitle className='mb-0'>Payroll accounts</SectionTitle>&nbsp;
          <Tooltip overlay={'Payroll accounts'} placement='bottom'><InfoOutlined fontSize='small'/></Tooltip>
        </Box>
        <TableHeader>
          <Box width='30%'>Organization</Box>
          <Box width='20%'>Account</Box>
          <Box width='30%'>Period</Box>
          <Box width='20%'>Rate</Box>
        </TableHeader>
        <Box className='payment-account' py={2} pl={2}>
          <NoDataText>No payroll accounts</NoDataText>
        </Box>
      </Box>
      <PaymentAccountModal
        open={open}
        onClose={handleClose}
      />
    </Container>
  )
}

export default PaymentAccounts