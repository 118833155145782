import React, { useState } from 'react'
import { Switch as ASwitch, SwitchProps, Table } from 'antd'
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc'
import { DragIndicator, DeleteOutlined } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

import { TableContainer } from '../../../../../components/Dashboard/CommonStyled'
import Input from '../../../../../components/Dashboard/Input'

const DragHandle = SortableHandle(() => <DragIndicator style={{ cursor: 'grab', color: '#999' }} />);

interface IDataType {
  description: string,
  qty: number,
  unit: string,
  amount: number,
  tax1: boolean,
  tax2: boolean,
  discount: boolean,
  index: number
  key: string
}

const Switch = (props: SwitchProps) => {
  return (
    <ASwitch
      size='small'
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      {...props}
    />
  )
}

const columns = [
  {
    title: '',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    className: 'drag-visible',
    render: () => <Input placeholder='Description' />
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    width: 110,
    render: () => <Input placeholder='Qty' type='number' />
  },
  {
    title: 'Unit Price',
    dataIndex: 'unit',
    width: 110,
    render: () => <Input placeholder='USD' />
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    width: 110,
    render: amount => <span>${amount.toFixed(2)}</span>
  },
  {
    title: 'Tax 1',
    dataIndex: 'tax1',
    width: 110,
    render: tax1 => <Switch defaultChecked={tax1}/>
  },
  {
    title: 'Tax 2',
    dataIndex: 'tax2',
    width: 110,
    render: tax2 => <Switch defaultChecked={tax2}/>
  },
  {
    title: 'Discount',
    dataIndex: 'discount',
    width: 200,
    render: discount => <Switch defaultChecked={discount}/>
  },
  {
    title: '',
    key: 'operation',
    width: 30,
    render: () => <IconButton><DeleteOutlined style={{color: '#ff4c4c'}} /></IconButton>
  }
];

const data: IDataType[] = [
  {
    key: '1',
    description: '',
    qty: 0,
    unit: 'USD',
    amount: 0,
    tax1: true,
    tax2: true,
    discount: true,
    index: 0
  }
];

const SortItem = SortableElement(props => <tr {...props} />);
const SortContainer = SortableContainer(props => <tbody {...props} />);

const CreatInvoiceTable = () => {
  const [dataSource, setDataSource] = useState<IDataType[]>(data)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const emptyArray = [] as IDataType[]
      const newData = arrayMove(emptyArray.concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      setDataSource(newData);
    }
  };

  const DraggableContainer = props => (
    <SortContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortItem index={index} {...restProps} />;
  };

  return (
    <TableContainer>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        scroll={{x: 'max-content'}}
      />
    </TableContainer>
  );
}

export default CreatInvoiceTable