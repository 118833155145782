import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Modal from '../Modal'
import * as S from './styles'
import PaypalIcon from '../../../assets/images/dashboard/paypal-icon.png'
import WiseIcon from '../../../assets/images/dashboard/wise-icon.png'
import Button from '../Button'
import CreatePaypalAccountModal from './CreatePaypalAccountModal'
import CreateWiseAccountModal from './CreateWiseAccountModal'

const PaymentAccountModal = ({ open, onClose }) => {

  const [openCreatePaypalAccountModal, setOpenCreatePaypalAccountModal] = useState(false)
  const [openCreateWiseAccountModal, setOpenCreateWiseAccountModal] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title='Select Payment Account'
      >
        <S.SelectContainer>
          <Box className='account-item'>
            <Box minWidth={isMobile ? 0 : 430}>
              <img src={PaypalIcon} alt='Paypal' />
              <p>Activation and maintenance fees charged by PayPal. We do not charge any fees for procession payments.</p>
            </Box>
            <Button
              className='primary-contained'
              onClick={() => setOpenCreatePaypalAccountModal(true)}
            >
              Set up
            </Button>
          </Box>
          <S.Divider />
          <Box className='account-item'>
            <Box minWidth={isMobile ? 0 : 450}>
              <img src={WiseIcon} alt='Wise' />
              <p>Accepts: Back transfer to Wise that will wire the money to the specified receivers.</p>
            </Box>
            <Button
              className='primary-contained'
              onClick={() => setOpenCreateWiseAccountModal(true)}
            >
              Set up
            </Button>
          </Box>
          <Box className='footer'>
            <Button className='outlined' onClick={onClose}>Cancel</Button>
          </Box>
        </S.SelectContainer>
      </Modal>
      <CreatePaypalAccountModal
        open={openCreatePaypalAccountModal}
        onClose={() => setOpenCreatePaypalAccountModal(false)}
      />
      <CreateWiseAccountModal
        open={openCreateWiseAccountModal}
        onClose={() => setOpenCreateWiseAccountModal(false)}
      />
    </>
  )
}

export default PaymentAccountModal