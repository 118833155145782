import React,  { useState } from 'react'
import { Box, IconButton } from '@material-ui/core'
import { Close, VerticalAlignBottomOutlined, ViewColumnOutlined } from '@material-ui/icons'
import moment from 'moment-timezone'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import DateRange from '../../../../components/Dashboard/DateRange'
import Tab from '../../../../components/Dashboard/Tab'
import Button from '../../../../components/Dashboard/Button'
import Select, { Option } from '../../../../components/Dashboard/Select'
import TimeLine from '../../../../components/Dashboard/TimeLine'
import WeeklyTimesheet from './WeeklyTimesheet'
import CalendarTimesheet from './CalendarTimesheet'
import { AppPage, TabContainer, Tabs } from '../../../../components/Dashboard/CommonStyled'
import * as S from './styles'
import Input from '../../../../components/Dashboard/Input'

const { TabPane } = Tabs

const tabs = [
  {
    id: '1',
    label: 'Daily'
  },
  {
    id: '2',
    label: 'Weekly'
  },
  {
    id: '3',
    label: 'Calendar'
  },
]

const ViewAndEdit = () => {
  const theme = useTheme()
  const xxsDown = useMediaQuery(theme.breakpoints.down(450))
  const [activeTabId, setActiveTabId] = useState('1')
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate()
  })
  const [filterOpen, setFilterOpen] = useState(false)

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  return (
    <AppPage>
      <Box>
        <h1>View & edit timesheets</h1>
      </Box>
      <TabContainer>
        <Tab
          tabs={tabs}
          activeTabId={activeTabId}
          onChangeTab={(id: string) => setActiveTabId(id)}
        />
      </TabContainer>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
        flexDirection={['column', 'column', 'row']}
      >
        <Box
          display='flex'
          alignItems={xxsDown ? 'flex-start' : 'center'}
          pt='20px'
          flexDirection={xxsDown ? 'column' : 'row'}
          width={xxsDown ? '100%' : 'fit-content'}
        >
          <DateRange
            showActionButtons
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
          <Box ml={xxsDown ? 0 : 2} mt={xxsDown ? 2 : 0}>
            <Select defaultValue='cest' onChange={handleChange} bordered={false}>
              <Option value='cest'>CEST</Option>
            </Select>
          </Box>
        </Box>
        <Box
          display='flex'
          alignItems='flex-end'
          mt={['20px', '20px', 0]}
          flexDirection={['column', 'row', 'column']}
          width='100%'
          justifyContent='space-between'
        >
          <Box
            display='flex'
            justifyContent='flex-end'
            alignItems='flex-end'
            width={xxsDown ? '100%' : 'fit-content'}
          >
            <Box mr={2} flex={1}>
              <Select
                label='Member'
                defaultValue='nikita-alexandar'
                onChange={handleChange}
              >
                <Option value='nikita-alexandar'>Nikita Alexandar</Option>
              </Select>
            </Box>
            <Button
              className='primary-outlined'
              onClick={() => setFilterOpen(true)}
            >
              Filters
            </Button>
          </Box>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            mt={1}
            ml={[0, '20px', 0]}
          >
            {
              activeTabId === '1' &&
              <Box mr={1}>
                <S.IconButton size='small'><ViewColumnOutlined /></S.IconButton>
              </Box>
            }
            <Box mr={1}>
              <S.IconButton size='small'><VerticalAlignBottomOutlined /></S.IconButton>
            </Box>
            <Button className='primary-contained'>Add time</Button>
          </Box>
        </Box>
      </Box>
      <Box mt={2} overflow='auto'>
        <S.TimeLabel>Today: <span>0.00</span></S.TimeLabel>
        {
          activeTabId === '1' &&
            <TimeLine />
        }
        {
          activeTabId === '2' &&
            <WeeklyTimesheet
              dateRange={dateRange}
            />
        }
        {
          activeTabId === '3' &&
            <CalendarTimesheet
              dateRange={dateRange}
            />
        }
      </Box>
      <S.Drawer
        variant='temporary'
        anchor='right'
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        style={{ zIndex: 99 }}
      >
        <S.DrawerContainer>
          <Tabs defaultActiveKey='1'>
            <TabPane tab='Filters' key='1'>
              <Box mt={2}>
                <Select label='Member' defaultValue='nikita'>
                  <Option value='nikita'>Nikita Alexandar</Option>
                </Select>
              </Box>
              <Box mt={2}>
                <Input
                  label='Project'
                  placeholder='All projects'
                />
              </Box>
              <Box mt={2}>
                <Input
                  label='Time Type'
                  placeholder='All types'
                />
              </Box>
              <Box mt={2}>
                <Input
                  label='Source'
                  placeholder='All sources'
                />
              </Box>
              <Box mt={2}>
                <Select label='Activity level' defaultValue=''>
                  <Option value=''>All levels</Option>
                </Select>
              </Box>
              <Box mt={2}>
                <Button className='full-width'>Clear filters</Button>
              </Box>
            </TabPane>
          </Tabs>
          <IconButton
            className='close-icon'
            size='small'
            onClick={() => setFilterOpen(false)}
          >
            <Close />
          </IconButton>
        </S.DrawerContainer>
      </S.Drawer>
    </AppPage>
  )
}

export default ViewAndEdit