import React from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div`
  display: inline-flex;
  border-radius: 30px;
  background-color: #e9eef5;
  text-align: center;

  ${(props) => props.theme.breakpoints.down('xs')} {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const TabButton = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  border-radius: 30px;
  background-color: transparent;
  transition: all ease 0.3s;
  cursor: pointer;
  font: 14px 'Proxima Nova Bold';
  color: ${props => props.theme.palette.dashboard.textPrimary};
  user-select: none;

  &:hover {
    color: ${props => props.theme.colors.blue50};
  }

  ${(props) => props.active && css`
    background: white;
    color: ${props => props.theme.palette.dashboard.textDark};
    box-shadow: 0 0 7px 2px rgba(47,83,151,0.1);
  `}

  ${(props) => props.theme.breakpoints.down('sm')} {
    white-space: nowrap;
  }
`

interface ITab {
  id: string
  label: string
}

interface Props {
  tabs: ITab[]
  activeTabId: string
  onChangeTab: (id: string) => void
}

const Tab = ({ tabs, onChangeTab, activeTabId }: Props) => {
  return (
    <Container>
      {
        tabs.map((tab) => (
          <TabButton
            key={tab.id}
            active={activeTabId === tab.id}
            onClick={() => onChangeTab(tab.id)}
          >
            {tab.label}
          </TabButton>
        ))
      }
    </Container>
  )
}

export default Tab