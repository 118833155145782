import React from 'react'
import { Table } from 'antd'
import { Box } from '@material-ui/core'
import { TableContainer } from '../../../../components/Dashboard/CommonStyled'

interface DataType {
  name: string,
  invoice: string,
  issue_date: Date,
  days_old: number,
  total: number,
  paid: number,
  status: string
}

const columns = [
  {
    title: 'Invoice',
    dataIndex: 'invoice'
  },
  {
    title: 'Issue date',
    dataIndex: 'issue_date'
  },
  {
    title: 'Days old',
    dataIndex: 'days_old'
  },
  {
    title: 'Total',
    dataIndex: 'total'
  },
  {
    title: 'Paid',
    dataIndex: 'paid'
  },
  {
    title: 'Status',
    dataIndex: 'status'
  }
]

const data: DataType[] = []

const InvoiceTable = () => {
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({
      name: record.name,
    }),
  };

  return (
    <TableContainer>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{x: 'max-content'}}
      />
      <Box mt={2}>
        <p>0 invoices</p>
      </Box>
    </TableContainer>
  )
}

export default InvoiceTable