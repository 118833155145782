import styled from 'styled-components'

const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  padding: 20px 20px 0 20px;
  width: 600px;
`

const MenuItem = styled.div`
  padding-bottom: 10px;
  cursor: pointer;
  user-select: none;

  h3, p {
    transition: all ease 0.3s;
  }

  &:hover {
    h3, p {
      color: ${(props) => props.theme.colors.blue};
    }
  }
`

const Footer = styled.div`
  padding: 15px 30px;
  background-color: ${(props) => props.theme.colors.blue100};

  h3 {
    margin: 0;
    transition: all ease 0.3s;
  }

  p {
    margin: 0;
  }

  svg {
    margin-left: 10px;
    color: ${(props) => props.theme.colors.blue};
  }
`

export { Body, MenuItem, Footer }
