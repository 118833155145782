import styled from 'styled-components'

import { AppPage } from '../../../../components/Dashboard/CommonStyled'

// @ts-ignore
const Container = styled(AppPage)`
  width: 100%;
  min-height: 100%;
  background-color: #f7f7f7;

  .sub-title {
    font-size: 16px;
    line-height: 18px;

    img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }

  .tool-bar {
    ${props => props.theme.breakpoints.down('xs')} {
      .right {
        > div {
          flex: 1;

          button {
            width: 100%;
          }
        }
      }
    }

    ${props => props.theme.breakpoints.down(1300)} {
      flex-direction: column;

      .left {
        > div {
          flex: 1;

          &:first-child {
            > div {
              min-width: unset;
            }
          }
        }
      }
    }

    ${props => props.theme.breakpoints.down(500)} {
      .left, .right {
        flex-direction: column;

        > div:first-child {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }

  .search-wrapper {
    ${props => props.theme.breakpoints.down('xs')} {
      flex-direction: column;

      > div:last-child {
        width: 100%;
        margin-top: 10px

        > span {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
`

const NoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;

  img {
    width: 250px;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 20px !important;
    color: ${props => props.theme.palette.dashboard.textDark} !important;
    font-weight: bold;
  }

  p {
    margin-bottom: 30px !important;
    text-align: center;
  }

  .hubstaff-tasks {
    width: 120px;
  }

  ${props => props.theme.breakpoints.down(500)} {
    button {
      width: 100%;
    }
  }
`

export { Container, NoContent }