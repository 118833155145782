import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { Input, Tooltip as ATooltip } from 'antd'

const { TextArea } = Input

const Container = styled(Box)`
`

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.gray100};
  color: ${props => props.theme.palette.dashboard.textPrimary};
  padding: 15px;
  font-weight: bold;
`

const SectionTitle = styled.h3`
  font-weight: bold;
  color: ${props => props.theme.palette.dashboard.textDark} !important;
  margin-bottom: 10px !important;

  &.mb-0 {
    margin-bottom: 0 !important;
  }
`

const FormControl = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .label {
    display: flex;
    align-items: center;
    color: ${props => props.theme.palette.dashboard.textLight};
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 5px;

    svg {
      width: 16px;
      height: 16px;
      margin-left: 5px;
      color: ${props => props.theme.palette.dashboard.textDark};
    }
  }
`

const StyledTextArea = styled(TextArea)`
  border: 1px solid ${props => props.theme.colors.gray100} !important;
  border-radius: 5px !important;
  padding: 7px 11px !important;
`

const Tooltip = styled(ATooltip)`
  .ant-tooltip-inner {
    text-align: center;
  }
`

export { Container, TableHeader, SectionTitle, FormControl, StyledTextArea, Tooltip }