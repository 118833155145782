import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { ACCESS_TOKEN_KEY } from '../constants'

function ProtectedRoute({ component: Component, Guard, Layout, ...restOfProps }) {
  const isAuthenticated = localStorage.getItem(ACCESS_TOKEN_KEY)
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? (
          <Layout>
            <Guard>
              <Component {...props} />
            </Guard>
          </Layout>
        ) : <Redirect to="/auth/sign-in" />
      }
    />
  )
}

export default ProtectedRoute;
