import styled from 'styled-components'
import { Box } from '@material-ui/core'

const Container = styled(Box)`
  .date-range {
    > div {
      width: 360px;
    }

    ${props => props.theme.breakpoints.down(500)} {
      width: 100%;

      > div {
        width: 100%;

        .show-date {
          width: 100%;
        }
      }
    }
  }

  .member-select {
    > div {
      width: 360px;
    }

    ${props => props.theme.breakpoints.down(500)} {
      width: 100%;

      > div {
        flex: 1;
      }
    }
  }
`

export { Container }