import React, { useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { spacing } from '@material-ui/system'
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper
} from '@material-ui/core'

import Sidebar from '../../components/Dashboard/Sidebar'
import Header from '../../components/Dashboard/Header'

const drawerWidth = 260

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: white;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`

const Root = styled.div`
	display: flex;
	min-height: 100vh;
`

const Drawer = styled.div<{showSideMenu: boolean}>`
	${(props) => props.theme.breakpoints.up("md")} {
		width: ${drawerWidth}px;
		flex-shrink: 0;
    transition: all ease 0.4s;

    .MuiDrawer-paper {
      transition: all ease 0.4s;
    }

    ${(props) => !props.showSideMenu && `
      margin-left: -${drawerWidth}px;

      .MuiDrawer-paper {
        transition: all ease 0.4s;
        left: -${drawerWidth}px;
      }
    `}
	}
`

const AppContent = styled.div<{showSideMenu: boolean}>`
  position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	max-width: calc(100vw - ${props => props.showSideMenu ? drawerWidth : 0}px);
  height: 100vh;

  h1, h2, h3, h4, h5, h6 {
    font-family: Lato-Regular, sans-serif;
    color: #617083;
    margin: 0;
  }

  p {
    color: #617083;
    margin: 0;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    max-width: 100vw;
  }
`

const Paper = styled(MuiPaper)(spacing)

// @ts-ignore
const MainContent = styled(Paper)`
	flex: 1;
	background: ${(props) => props.theme.palette.background.default};
  overflow-y: auto;

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		flex: none;
	}

	.MuiPaper-root .MuiPaper-root {
		box-shadow: none;
	}
`;

const Dashboard = ({ children, routes }) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [showSideMenu, setShowSideMenu] = useState(true)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer showSideMenu={showSideMenu}>
        <Hidden mdUp implementation="js">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
          />
        </Hidden>
      </Drawer>
      <AppContent showSideMenu={showSideMenu}>
        <Header
          showSideMenu={showSideMenu}
          toggleDrawer={handleDrawerToggle}
          toggleSidebar={() => setShowSideMenu(!showSideMenu)}
        />
        <MainContent>{children}</MainContent>
      </AppContent>
    </Root>
  );
};

export default Dashboard;
