import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

import DateRange from '../../../../components/Dashboard/DateRange'
import Input from '../../../../components/Dashboard/Input'
import Button from '../../../../components/Dashboard/Button'
import ApprovalTable from './ApprovalTable'
import { AppPage, Tabs } from '../../../../components/Dashboard/CommonStyled'
import { Container } from './styles'

const { TabPane } = Tabs

const useStyles = makeStyles(() => ({
  input: {
    minWidth: 360
  },
  dateRange: {
    '& .show-date': {
      width: 360
    }
  },
  button: {
    padding: '7px 22px !important',
    borderColor: '#18d06b !important',
    backgroundColor: '#18d06b !important',
    marginLeft: 10,
  }
}));

const Approvals = () => {
  const classes = useStyles()
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate()
  })

  return (
    <AppPage>
      <Container>
        <Box mb={2}>
          <h1>Timesheet approvals</h1>
        </Box>
        <Box className='date-range'>
          <DateRange
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </Box>
        <Box
          display='flex'
          mt={2}
          alignItems='flex-end'
          className='member-select'
        >
          <Input label='All Members' />
          <Button className={clsx(classes.button, 'primary-contained')}>Apply</Button>
        </Box>
        <Box mt={4}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="OPEN (6)" key="1">
              <Box pt={3}>
                <ApprovalTable />
              </Box>
            </TabPane>
            <TabPane tab="SUBMITTED (0)" key="2">

            </TabPane>
            <TabPane tab="APPROVED (0)" key="3">

            </TabPane>
            <TabPane tab="DENIED (0)" key="4">

            </TabPane>
            <TabPane tab="ALL (0)" key="5">

            </TabPane>
          </Tabs>
        </Box>
      </Container>
    </AppPage>
  )
}

export default Approvals