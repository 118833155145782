import { Button as AButton } from 'antd'
import styled from 'styled-components'

export const Button = styled(AButton)`
  width: 100%;
  background-color: ${props => props.theme.colors.blue} !important;
  color: ${props => props.theme.colors.white} !important;
  height: 56px !important;
  padding: 10px 20px !important;
  border-radius: 7px !important;
  transition: all ease 0.3s;
  border: none !important;

  span {
    font: 16px "Proxima Nova Bold";
    font-weight: 600;
    line-height: 1.5;
  }

  &:hover {
    opacity: 0.8;
  }
`

export const OutlinedButton = styled(AButton)`
  width: 100%;
  background-color: transparent !important;
  color: ${props => props.theme.colors.blue} !important;
  height: 56px !important;
  padding: 10px 20px !important;
  border-radius: 7px !important;
  border: 2px solid ${props => props.theme.colors.blue} !important;
  transition: all ease 0.3s;

  span {
    font: 16px "Proxima Nova Bold";
    font-weight: 600;
    line-height: 1.5;
  }

  &:hover {
    opacity: 0.8;
  }
`
