import React from 'react'
import { Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { PlayArrow } from '@material-ui/icons'

import * as S from './styles'
import Input from '../../../components/Common/Input'
import Checkbox from '../../../components/Common/Checkbox'
import { Button } from '../../../components/Common/Button'
import { GrouponLogo, InstacartLogo, KellerWilliamsLogo, ClippersLogo, CardImage2 } from '../../../components/Common/Images'

const TopSection = () => {
  return (
    <S.Container>
      <div className='container'>
        <S.VideoContainer>
          <img src={CardImage2} />
          <S.PlayButton><PlayArrow /></S.PlayButton>
        </S.VideoContainer>
        <S.TopSection>
          <div className='left-container'>
            <div className='message-content'>
              <h1>Spend less time tracking and more time <span>growing.</span></h1>
              <p>The all-in-one work time tracker for managing field or remote teams.</p>
              <Input placeholder='Name' />
              <Input placeholder='E-mail' />
              <Input placeholder='Password' type='password'/>
              <Box display='flex' alignItems='center'>
                <Checkbox>I agree to the</Checkbox>
                <Link to='#'>Terms</Link>,&nbsp;
                <Link to='#'>Privacy Policy</Link>&nbsp;and&nbsp;
                <Link to='#'>DPA</Link>
              </Box>
              <Box my="24px"><Button>Create account</Button></Box>
              <div className='no-required-text'>
                <span>No credit card required</span>
              </div>
            </div>
          </div>
          <div className='logos-container'>
            <p>Trusted by 40,000+ businesses.</p>
            <div className='logos'>
              <div className='logo-item'>
                <img src={GrouponLogo} alt='Groupon Logo' />
              </div>
              <div className='logo-item'>
                <img src={KellerWilliamsLogo} alt='Keller Williams Logo' />
              </div>
              <div className='logo-item'>
                <img src={ClippersLogo} alt='Clippers Logo' />
              </div>
              <div className='logo-item'>
                <img src={InstacartLogo} alt='Instacart Logo' style={{ maxWidth: 120 }}/>
              </div>
            </div>
          </div>
        </S.TopSection>
      </div>
    </S.Container>
  )
}

export default TopSection
