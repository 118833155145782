import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Search } from '@material-ui/icons'

import ProjectTable from './ProjectTable'
import Input from '../../../../components/Dashboard/Input'
import { Tabs } from '../../../../components/Dashboard/CommonStyled'
import { Container } from './styles'

const { TabPane } = Tabs

const useStyles = makeStyles(() => ({
  search: {
    maxWidth: 400,
    borderRadius: '30px !important'
  }
}));

const Projects = () => {
  const classes = useStyles()

  return (
    <Container>
      <Box mb={2}>
        <h1>Projects</h1>
      </Box>
      <Box mt={4}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="ARCHIVE (5)" key="1">
            <Box pt={3} pb={5} className='search-wrapper'>
              <Input
                className={classes.search}
                prefix={<Search />}
                placeholder='Search projects'
              />
            </Box>
            <Box pt={3}>
              <ProjectTable />
            </Box>
          </TabPane>
          <TabPane tab="ARCHIVED (0)" key="2">

          </TabPane>
        </Tabs>
      </Box>
    </Container>
  )
}

export default Projects