import styled from 'styled-components'
import { Box, Drawer as MuiDrawer } from '@material-ui/core'

const Container = styled(Box)`
  position: relative;
`

const InsightsWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 20px;
  border: 1px solid ${props => props.theme.colors.gray100};
  border-radius: 5px;
  margin-top: 10px;

  .insights-logo {
    position: absolute;
    top: -13px;
    right: 20px;
    display: flex;
    align-items: center;
    padding: 3px 5px;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.colors.gray100};
    background: white;

    img {
      width: 125px;
    }
  }

  .insights-item {
    padding-right: 20px;
    border-right: 1px solid ${props => props.theme.colors.gray100};

    &:last-child {
      border-right: none;
    }

    .label {
      text-transform: uppercase;
      font-size: 12px;
      color: ${props => props.theme.palette.dashboard.textPrimary};
    }

    .info-list {
      display: flex;
      margin-top: 10px;

      .info-item {
        margin-right: 20px;

        span {
          font-size: 12px;
          text-transform: uppercase;
          color: ${props => props.theme.palette.dashboard.textLight};
        }
      }
    }
  }

  ${props => props.theme.breakpoints.down('xs')} {
    grid-template-columns: 1fr;

    .insights-item {
      border-right: 0;
      padding-bottom: 20px;
      border-bottom: 1px solid ${props => props.theme.colors.gray100};

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
`

const NoDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;

  img {
    max-width: 400px;
    margin-bottom: 20px;
  }

  p {
    font-weight: bold;
    font-size: 16px;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    p {
      text-align: center;
    }
  }
`

const Drawer = styled(MuiDrawer)`
  width: 345px;
  flex-shrink: 0;

  .MuiDrawer-paper {
    width: 345px;
    margin-top: 45px;
    overflow: visible;
    box-shadow: -3px -5px 15px 2px rgba(103, 99, 99, 0.2);
  }

  ${props => props.theme.breakpoints.down(500)} {
    width: 70%;

    .MuiDrawer-paper {
      width: 70%;
    }
  }
`

const DrawerContainer = styled.div`
  position: relative;
  padding: 25px 30px;
  background: white;

  .ant-tabs {
    overflow: visible;
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;

    svg {
      font-size: 16px;
    }
  }

  ${props => props.theme.breakpoints.down(500)} {
    padding: 15px;
  }
`

export { Container, InsightsWrapper, NoDataContainer, Drawer, DrawerContainer }