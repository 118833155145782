import React from 'react'
import { Dropdown, Menu } from 'antd'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import { User } from 'react-feather'
import styled from 'styled-components'
import { useHistory } from 'react-router'

import * as S from './styles'
import { ACCESS_TOKEN_KEY } from '../../../constants'

const useStyles = makeStyles(() => ({
  dropdown: {
    "marginTop": 10,
    "marginRight": -10,
    '& .ant-dropdown-menu-item': {
      padding: '8px 16px'
    }
  }
}));

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 5px 0;
  background-color: ${props => props.theme.colors.gray100};
`

const UserMenu = () => {
  const classes = useStyles()
  const history = useHistory()

  const signOut = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
    history.push('/')
  }

  return (
    <Dropdown
      overlay={
        <Menu className={classes.dropdown}>
          <Menu.Item>
            My Account
          </Menu.Item>
          <Menu.Item>
            <Link to='/dashboard/settings'>User settings & billing</Link>
          </Menu.Item>
          <Divider />
          <Menu.Item>
            Payment accounts
          </Menu.Item>
          <Menu.Item>
            Integrations
          </Menu.Item>
          <Divider />
          <Menu.Item>
            Support
          </Menu.Item>
          <Menu.Item>
            Download
          </Menu.Item>
          <Menu.Item>
            Developer API
          </Menu.Item>
          <Divider />
          <Menu.Item onClick={signOut}>
            Sign out
          </Menu.Item>
        </Menu>
      }
      placement='bottomRight'
      trigger={['click']}
    >
      <S.MenuButton className='profile-menu'>
        <User />
      </S.MenuButton>
    </Dropdown>
  )
}

export default UserMenu