import React from 'react'
import { Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useHistory } from 'react-router'
import { Alert } from 'antd';

import * as S from './styles'
import Input from '../../../components/Common/Input'
import * as AuthApi from '../../../apis/auth.api'
import { Logo } from '../../../components/Common/Icons'
import { AgileImg } from '../../../components/Common/Images'
import { ACCESS_TOKEN_KEY } from '../../../constants'

const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is not valid')
    .required('Email is required.'),
  password: Yup.string().required('Password is required.')
});

const SignIn = () => {
  const history = useHistory()

  const handleSubmit = (values, { setErrors, setStatus, setSubmitting }) => {
    const { email, password } = values
    AuthApi.login(email, password)
      .then((data: any) => {
        if (data.accessToken) {
          localStorage.setItem(ACCESS_TOKEN_KEY, data.accessToken)
        }
        history.push('/dashboard')
      })
      .catch((error) => {
        const message = error.message || 'Something went wrong'
        setStatus({ success: false })
        setErrors({ submit: message })
        setSubmitting(false)
      });
  };

  return (
    <S.Container>
      <div className='login-main-container'>
        <div className='form-container'>
          <div className='inner-content'>
            <div className='inner-form'>
              <h2>Sign in to TimeGo</h2>
              <Formik
                initialValues={{ email: '', password: '', submit: false }}
                validationSchema={loginValidationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
                  <Form className="login-form" onSubmit={handleSubmit}>
                    <Box minHeight='45px' width='100%'>
                      {errors.submit && (
                        <Alert message={errors.submit} type="error" showIcon />
                      )}
                    </Box>
                    <div className='form-control'>
                      <span>Email</span>
                      <Input
                        name='email'
                        type='email'
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email && touched.email ? errors.email : ''}
                        placeholder='name@company.com'
                      />
                    </div>
                    <div className='form-control'>
                      <span>Password</span>
                      <Input
                        name='password'
                        type='password'
                        value={values.password}
                        onChange={handleChange}
                        error={
                          errors.password && touched.password ? errors.password : ''
                        }
                      />
                    </div>
                    <S.LoginButton htmlType='submit' loading={isSubmitting}>Log in</S.LoginButton>
                  </Form>
                )}
              </Formik>
              <Box textAlign='center'>
                <Link to='/forgot-password'>Forgot your password?</Link>
              </Box>
            </div>
            <div className='form-footer'>
              Need an account? <Link to='/auth/sign-up'>Join TimeGo for free</Link>
            </div>
          </div>
        </div>
        <div className='logo'>
          <Link to='/'><Logo width={147} height={31} /></Link>
        </div>
      </div>
      <div className='promo-content-wrapper'>
        <div className='demo-promo-inner'>
          <h1 className='page-title'>Have you heard about<br/> TimeGo Tasks?</h1>
          <p className='section-text'>
            The Agile, visual project management tool is changing<br/>
            the way teams work. Collaborate better and get more<br/>
            done with focused sprints and detailed project boards.
          </p>
          <S.TryOutButton>Try it out</S.TryOutButton>
        </div>
        <img className='promo-image' src={AgileImg} alt='Agile' />
      </div>
    </S.Container>
  )
}

export default SignIn
