import React from 'react'
import { Box } from '@material-ui/core'

import TopSection from './TopSection'
import TabSection from './TabSection'
import ProductivitySection from './ProductivitySection'
import BusinessSection from './BusinessSection'
import IntegrationsSection from './IntegrationsSection'
import CustomerSection from './CustomerSection'
import FreeTrialSection from './FreeTrialSection'
import Header from '../../components/Home/Header'
import Footer from '../../components/Home/Footer'

const HomePage = () => {
  return (
    <React.Fragment>
      <Header />
      <Box mt='85px'>
        <TopSection />
        <TabSection />
        <ProductivitySection />
        <BusinessSection />
        <IntegrationsSection />
        <CustomerSection />
        <FreeTrialSection />
      </Box>
      <Footer />
    </React.Fragment>
  )
}

export default HomePage
