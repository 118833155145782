import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import styled from 'styled-components'
import { InfoOutlined } from '@material-ui/icons'

import { SectionTitle, FormControl, StyledTextArea as TextArea, Tooltip } from './styles'
import Input from '../../../components/Dashboard/Input'

const Container = styled(Box)`
  max-width: 550px;
  padding-left: ${props => props.theme.spacing(2)}px;

  .logo {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50px;
      border: 1px solid ${props => props.theme.colors.gray100};
    }

    span {
      font-size: 13px;
      color: ${props => props.theme.palette.dashboard.textPrimary};
      margin-top: 3px;
    }
  }

  .input-group {
    position: relative;
    max-width: 200px;

    .addon-after {
      position: absolute;
      right: 1px;
      top: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: calc(100% - 2px);
      background: #eeeeee;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      color: ${props => props.theme.palette.dashboard.textPrimary};
    }
  }

  ${props => props.theme.breakpoints.down('xs')}{
    padding-left: 0;
    max-width: 100%;

    .input-group {
      max-width: unset;
    }
  }
`

const Invoicing = () => {
  const [imgFile, setImgFile] = useState(null)
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null)

  const handleFileChange = (e) => {
    const file =  e.target.files.length ? e.target.files[0] : null;
    if (file) {
      setImgFile(file);
    }
  }

  useEffect(() => {
    if (imgFile) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setPreview(reader.result);
      }, false);

      // @ts-ignore
      reader.readAsDataURL(imgFile);
    }
  }, [imgFile]);

  return (
    <Container pt={3}>
      <SectionTitle>Organization</SectionTitle>
      <FormControl>
        <span className='label'>Address</span>
        <TextArea
          rows={4}
          placeholder='Enter your address for the team invoice'
        />
      </FormControl>
      <FormControl>
        <span className='label'>Logo</span>
        <div className='logo'>
          {
            preview &&
            <img src={preview as string} alt='logo' />
          }
          <input type='file' onChange={handleFileChange} />
          <span>File size limit: 1MB</span>
        </div>
      </FormControl>
      <SectionTitle>Taxes</SectionTitle>
      <FormControl>
        <span className='label'>Tax ID <Tooltip overlay={'Used to show your tax id for team invoices'} placement='bottom'><InfoOutlined /></Tooltip></span>
        <Input />
      </FormControl>
      <FormControl>
        <span className='label'>Tax Rate <Tooltip overlay={'Used as your default tax rate for team invoices'} placement='bottom'><InfoOutlined /></Tooltip></span>
        <div className='input-group'>
          <Input />
          <div className='addon-after'>%</div>
        </div>
      </FormControl>
      <SectionTitle>Payment Terms</SectionTitle>
      <FormControl>
        <span className='label'>Notes <Tooltip overlay={'Used as your default note for team invoices'} placement='bottom'><InfoOutlined /></Tooltip></span>
        <TextArea
          rows={4}
          placeholder='Enter notes for the term invoice'
        />
      </FormControl>
      <FormControl>
        <span className='label'>Net Terms <Tooltip overlay={'Used as your default net terms for team invoices'} placement='bottom'><InfoOutlined /></Tooltip></span>
        <div className='input-group'>
          <Input type='number' />
          <div className='addon-after'>days</div>
        </div>
      </FormControl>
    </Container>
  )
}

export default Invoicing