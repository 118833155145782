import React from 'react'
import { addDays } from 'date-fns'
import moment from 'moment-timezone'

import * as S from './styles'
import { IDateRange } from '../../../../components/Dashboard/DateRange'

interface Props {
  dateRange: IDateRange
}

const WeekSchedule = (props: Props) => {
  const { dateRange } = props

  return (
    <S.WeekScheduleContainer>
      <div className='column name'>
        <div className='item' />
        <div className='item time' />
        {
          new Array(23).fill(0).map((_, index) => (
            <div className='item time' key={index}>
              <span>{index + 1}{index > 11 ? 'pm' : 'am'}</span>
            </div>
          ))
        }
      </div>
      {
        new Array(7).fill(0).map((_, index) => {
          const date = addDays(dateRange.startDate, index)
          const isToday = moment().isSame(date, 'day') ? 'today' : ''
          return (
            <React.Fragment key={index}>
              <div className={`column date ${isToday}`}>
                <div className='item date-label'>
                  <h3>{moment(date).format('DD')}</h3>
                  <div>
                    <span className='day'>{moment(date).format('ddd')}</span>
                    <span className='month'>{moment(date).format('MMM')}</span>
                  </div>
                </div>
                {
                  new Array(24).fill(0).map((_, index) => (
                    <div className='item' key={index} />
                  ))
                }
              </div>
            </React.Fragment>
          )
        })
      }
    </S.WeekScheduleContainer>
  )
}

export default WeekSchedule