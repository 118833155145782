import styled from 'styled-components'
import { Box } from '@material-ui/core'

import { AppPage } from '../../../../components/Dashboard/CommonStyled'

// @ts-ignore
const Container = styled(AppPage)`
  background-color: #f7f7f7;
`

const Block = styled(Box)`
  margin-bottom: 40px;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .icon {
      display: flex;
      align-items: center;
      width: 30px;
      height: 25px;

      img {
        width: 16px;
      }
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .item-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 30px;

    ${props => props.theme.breakpoints.down('lg')} {
      grid-template-columns: 1fr 1fr 1fr;
    }

    ${props => props.theme.breakpoints.down('sm')} {
      grid-template-columns: 1fr 1fr;
    }

    ${props => props.theme.breakpoints.down(500)} {
      grid-template-columns: 1fr;
    }
  }
`

const Item = styled.div`
  padding: 20px;
  border: 1px solid ${props => props.theme.colors.gray100};
  border-radius: 5px;
  background-color: white;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    svg {
      color: #fac34e;
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
    }

    p {
      font-size: 12px;
      color: ${props => props.theme.palette.dashboard.textLight};
    }
  }
`

export {
  Container,
  Block,
  Item
}