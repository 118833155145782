import React from "react";
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import {
  createTheme,
  ThemeProvider as MaterialUIThemeProvider,
} from '@material-ui/core/styles'
import moment from 'moment-timezone'

import theme from './theme'
import Routes from './routes'

const App = () => {
  const muiTheme = createTheme({
    ...theme,
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: 1440
      },
    },
  })

  moment.updateLocale('en', {
    week: {
      dow: 1,
      doy: 4,
    }
  })

  return (
    <MaterialUIThemeProvider theme={muiTheme}>
      <StyledComponentsThemeProvider theme={muiTheme}>
        <Routes />
      </StyledComponentsThemeProvider>
    </MaterialUIThemeProvider>
  );
};

export default App;
