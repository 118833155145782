import styled from 'styled-components'
import {Button} from '../../../components/Common/Button'

const Container = styled.div`
  height: 100%;
  padding: 0;
  display: flex;

  .login-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2rem;
    min-width: 254px;
    margin: 0 72px;
    width: 254px;

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .inner-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        width: 100%;
        margin-top: 60px;

        .inner-form {
          max-height: 330px;

          h2 {
            font-size: 24px;
            margin-bottom: 0;
            text-align: center;
            font-weight: 300;
            line-height: 40px;
            position: relative;
            max-width: 100%;
          }

          a {
            text-align: center;
            font-size: 12px;
            color: #2b2f33;
          }

          .login-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 0 30px;

            .ant-alert {
              width: 100%;
              border-radius: 5px;
              margin-bottom: 5px;
            }

            .form-control {
              width: 100%;
              margin-bottom: 22px;

              span {
                font-size: 12px;
              }
            }
          }
        }

        .form-footer {
          text-align: center;
          padding-top: 30px;
          margin: 130px -18px 20px;
          border-top: 1px solid #979797;
          font-size: 14px;
          color: #2b2f33;

          a {
            font-weight: bold;
          }
        }
      }
    }

    .logo {
      display: flex;
      justify-content: center;
      margin: 30px 0 15px;
    }
  }

  .promo-content-wrapper {
    flex: 1;
    background: linear-gradient(316.73deg,#1CF1CF 0%,#0FCF9B 90%);
    height: 100%;
    padding: 95px 75px 0;
    width: 100%;

    .page-title {
      font: 38px/48px Lato-Regular;
      color: white;
    }

    .section-text {
      margin: 20px 0;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: white;
    }

    .promo-image {
      height: 50%;
      margin-left: 150px;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
      display: none;
    }
  }
`

// @ts-ignore
const LoginButton = styled(Button)`
  width: fit-content;
  min-width: 78px !important;
  height: 40px !important;
  background-color: #0cdfa3 !important;
  border-radius: 0 !important;

  span {
    font-size: 14px !important;
  }
`

const TryOutButton = styled(Button)`
  width: 108px !important;
  height: 46px !important;
  background-color: #263f54 !important;
  border-radius: 0 !important;

  span {
    font-size: 16px;
  }
`

export { Container, LoginButton, TryOutButton }
