import React, { ReactNode, useRef } from 'react'
import OwlCarousel from 'react-owl-carousel3'
import { ArrowForward, ArrowBack, PlayArrow } from '@material-ui/icons'

import * as S from './styles'
import { OutlinedButton } from '../../Common/Button'
import { QuoteMark } from '../../Common/Images'

interface ICarouselData {
  id: number,
  mediaType: string
  mediaSource: string
  quote: ReactNode
  logo: string
  author: string
  job: string
}

interface Props {
  data: ICarouselData[]
}

const options = {
  items: 1,
  loop: true,
  nav: false,
  dots: true,
  autoplayHoverPause: true,
  autoplay: false,
  slideBy: 'page'
};

const BlockQuoteCarousel = ({data}: Props) => {
  const carousel = useRef(null)

  const onPrev = () => {
    if (carousel && carousel.current) {
      // @ts-ignore
      carousel.current.prev();
    }
  }

  const onNext = () => {
    if (carousel && carousel.current) {
      // @ts-ignore
      carousel.current.next();
    }
  }

  return (
    <S.Container>
      <OwlCarousel
        className='owl-carousel owl-theme'
        ref={carousel}
        {...options}
      >
        {
          data.map((item) => (
            <S.CarouselItem key={item.id}>
              <div className='media-container' style={{ background: `url(${item.mediaSource})` }}>
                { item.mediaType === 'video' && <S.PlayButton><PlayArrow /></S.PlayButton> }
              </div>
              <div className='content'>
                <img className='quote-mark' src={QuoteMark} alt='Quote Mark' />
                { item.quote }
                <div className='content-footer'>
                  <div>
                    <div className='author-info'>
                      <span className='author-name'>{item.author} </span>
                      <span className='author-job'>/ {item.job}</span>
                    </div>
                    <img className='logo-img' src={item.logo} alt='logo' />
                  </div>
                  <OutlinedButton>Read case study</OutlinedButton>
                </div>
              </div>
            </S.CarouselItem>
          ))
        }
      </OwlCarousel>
      <div className='background' />
      <div className='carousel-footer'>
        <div className='tool-bar'>
          <ArrowBack className='prev-button' onClick={onPrev} />
          <ArrowForward className='next-button' onClick={onNext}/>
        </div>
        <div className='view-all-case'>
          <p>View all case studies</p>
          <ArrowForward />
        </div>
      </div>
    </S.Container>
  )
}

export default BlockQuoteCarousel
