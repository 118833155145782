import React, { useState, useRef } from 'react'
import { Close, PlayArrowRounded, Minimize, ExpandLess, ExpandMore } from '@material-ui/icons'
import { IconButton, Box } from '@material-ui/core'
import Draggable from 'react-draggable'
import { useDispatch } from 'react-redux'

import { Container, PlayButton } from './styles'
import Select from '../Select'
import { showTimeTrackerWidget } from '../../../store/actions/dashboard.actions'

const TimeTrackerWidget = () => {
  const dispatch = useDispatch()
  const [isCollapsed, setCollapsed] = useState(true)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })

  const draggleRef = useRef(null);

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    // @ts-ignore
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    })
  };

  const onClose = () => {
    dispatch(showTimeTrackerWidget(false))
  }

  return (
    <Draggable
      bounds={bounds}
      onStart={(event, uiData) => onStart(event, uiData)}
    >
      <Container ref={draggleRef}>
        <div className='header'>
          <IconButton
            className='close-btn'
            size='small'
            onClick={onClose}
          >
            <Minimize />
          </IconButton>
          <IconButton
            className='close-btn'
            size='small'
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </div>
        <Box display='flex' flexDirection='column' px={3}>
          <div className='main-content'>
            <Box mr={1} display='flex' alignItems='center'>
              <PlayButton>
                <PlayArrowRounded />
              </PlayButton>
              <Box ml={2}>
                <h2>_</h2>
                <span>No limit</span>
              </Box>
            </Box>
            <Box mr={1}>
              <h1>0:00:00</h1>
              <span>Today 0:00:00</span>
            </Box>
          </div>
          {
            !isCollapsed &&
            <Box>
                <div className='divider' />
                <Box mb={2}>
                    <Select
                        label='Project'
                        placeholder='Select a project'
                    >
                    </Select>
                </Box>
                <Box mb={2}>
                    <Select
                        label='To-do'
                        placeholder='Select a to-do'
                    >
                    </Select>
                </Box>
            </Box>
          }
          <Box
            className='collapse-tag'
            onClick={() => setCollapsed(!isCollapsed)}
          >
            { !isCollapsed ? <ExpandLess /> : <ExpandMore /> }
          </Box>
        </Box>
      </Container>
    </Draggable>
  )
}

export default TimeTrackerWidget