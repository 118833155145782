import React from 'react'
import styled from 'styled-components'
import { Drawer as MuiDrawer, Box, IconButton } from '@material-ui/core'
import { Close, NotificationsNone, Room, TrendingUp } from '@material-ui/icons'
import { Link } from 'react-router-dom'

const Drawer = styled(MuiDrawer)`
  width: 485px;
  flex-shrink: 0;

  .MuiDrawer-paper {
    width: 485px;
    margin-top: 45px;
    overflow: visible;
    box-shadow: -3px -5px 15px 2px rgba(103, 99, 99, 0.2);
  }

  ${props => props.theme.breakpoints.down(500)} {
    width: 100%;

    .MuiDrawer-paper {
      width: 100%;
    }
  }
`

const Container = styled.div`
  position: relative;
  padding: 30px 25px;

  h3 {
    margin: 0;
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;

    svg {
      font-size: 16px;
    }
  }

  ${props => props.theme.breakpoints.down(500)} {
    padding: 15px;
  }

`

const NewsItem = styled.div`
  display: flex;
  padding: 20px 0 0;

  .icon {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-right: 7px;

    svg {
      width: 20px;
    }
  }

  .content {
    padding-bottom: 20px;
    border-bottom: 1px solid ${props => props.theme.colors.gray100};

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      height: 30px;

      h4 {
        margin: 0;
      }

      .date {
        font-size: 12px;
        color: ${props => props.theme.palette.dashboard.textLight};
      }
    }

    p {
      font-size: 12px;
      margin: 0;
    }

    a {
      color: ${props => props.theme.palette.dashboard.primary};
      margin-top: 10px;
      font-size: 12px;
    }
  }
`

interface Props {
  open: boolean
  setOpen: (value: boolean) => void
}

const News = (props: Props) => {
  const { open, setOpen } = props

  return (
    <>
      <Drawer
        variant='temporary'
        anchor='right'
        open={open}
        onClose={() => setOpen(false)}
        style={{ zIndex: 99 }}
      >
        <Container>
          <h3>What's new?</h3>
          <NewsItem>
            <div className='icon'>
              <NotificationsNone style={{ color: '#18d06b' }}/>
            </div>
            <div className='content'>
              <div className='header'>
                <h4>Join our affiliate program!</h4>
                <span className='date'>Mon, Nov 15, 2021</span>
              </div>
              <p>Are you interested in earning 30% commissions when you refer TimeGo? We've relaunched our affiliate program and we are taking new applications. Join us today and starting earning cash right away!</p>
            </div>
          </NewsItem>
          <NewsItem>
            <div className='icon'>
              <TrendingUp style={{ color: '#5a66ff' }}/>
            </div>
            <div className='content'>
              <div className='header'>
                <h4>How does your activity stack up?</h4>
                <span className='date'>Mon, Nov 15, 2021</span>
              </div>
              <p>We've added a resource for you and your team to learn about how activity works, and compare your organization's activity to others within the same industry.</p>
            </div>
          </NewsItem>
          <NewsItem>
            <div className='icon'>
              <Room style={{ color: '#ff5b77' }}/>
            </div>
            <div className='content'>
              <div className='header'>
                <h4>Removing the 0% activity default</h4>
                <span className='date'>Mon, Nov 15, 2021</span>
              </div>
              <p>Using a timer that doesn't monitor activity? Here's some good news. From now on, we won't be showing this time as 0% activity. We're removing the activity percentage altogether to avoid confusion. This includes the web timer, mobile app, break time, and time added manually.</p>
              <Box mt={1}><Link to='#'>Learn more</Link></Box>
            </div>
          </NewsItem>
          <IconButton
            className='close-icon'
            size='small'
            onClick={() => setOpen(false)}
          >
            <Close />
          </IconButton>
        </Container>
      </Drawer>
    </>
  )
}

export default News