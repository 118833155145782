import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { ArrowBackIos, Sort, Settings } from '@material-ui/icons'
import { Select } from 'antd'

import { BackButton, IconButton, ItemWrapper, SubPanel, SettingButton, FilterGroup } from '../styles'
import DatePicker from '../../../../components/Dashboard/DatePicker'
import Button from '../../../../components/Dashboard/Button'
import { AppPage } from '../../../../components/Dashboard/CommonStyled'
import ClockIcon from '../../../../assets/images/dashboard/clock-icon.png'
import CollapseIcon from '../../../../assets/images/dashboard/collapse-icon.png'
import SendIcon from '../../../../assets/images/dashboard/send-icon.png'
import ExportIcon from '../../../../assets/images/dashboard/export-icon.png'
import NoDataImg from '../../../../assets/images/dashboard/nothing.png'
import FilterSideMenu from '../FilterSideMenu'

const { Option } = Select

const TimeActivity = () => {
  const [date, setDate] = useState(new Date())
  const [filterOpen, setFilterOpen] = useState(false)

  return (
    <AppPage>
      <Box mb={3}>
        <BackButton to='#'><ArrowBackIos /> Reports</BackButton>
      </Box>
      <Box mb={2} display='flex' justifyContent='space-between' alignItems='flex-end' flexWrap='wrap'>
        <h1>Time & activity report</h1>
        <IconButton>
          <img src={ClockIcon} alt='Clock' />
          Scheduled reports
        </IconButton>
      </Box>
      <Box display='flex' justifyContent='flex-end'>
        <FilterGroup>
          <DatePicker
            date={date}
            setDate={setDate}
          />
          <Box ml={2}>
            <Button
              className='primary-contained'
              onClick={() => setFilterOpen(true)}
            >
              Filters
            </Button>
          </Box>
        </FilterGroup>
      </Box>
      <ItemWrapper gridTemplateColumns='1fr 1fr 1fr'>
        <div className='item'>
          <span className='label'>Time</span>
          <h1>-</h1>
        </div>
        <div className='item'>
          <span className='label'>Avg.Activity</span>
          <h1>-</h1>
        </div>
        <div className='item'>
          <span className='label'>Earned</span>
          <h1>-</h1>
        </div>
      </ItemWrapper>
      <SubPanel>
        <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
          <Box display='flex' alignItems='flex-end' className='title'>
            <h2>Estey-Hoover, Inc</h2>
            <span>America - New York</span>
          </Box>
          <Box display='flex' className='actions'>
            <IconButton>
              <img src={CollapseIcon} alt='Collapse' />
              Collapse
            </IconButton>
            <IconButton>
              <img src={SendIcon} alt='Send' />
              Send
            </IconButton>
            <IconButton>
              <img src={ClockIcon} alt='Schedule' />
              Schedule
            </IconButton>
            <IconButton>
              <img src={ExportIcon} alt='Export' />
              Export
            </IconButton>
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between' mt={2}>
          <div className='group-by-select'>
            <div className='label'><Sort /> Group by:</div>
            <Select defaultValue="date" bordered={false}>
              <Option value="date">Date</Option>
            </Select>
          </div>
          <SettingButton>
            <Settings />
          </SettingButton>
        </Box>
        <div className='no-data-container'>
          <img src={NoDataImg} alt='Nothing' />
          <h3>Nothing to report</h3>
          <p>Expecting to see something? Try adjusting the report.</p>
        </div>
      </SubPanel>
      <FilterSideMenu
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
      />
    </AppPage>
  )
}

export default TimeActivity