import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  left: 50px;
  top: 50px;
  width: 500px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 12px 6px rgba(47,83,151,0.1);
  z-index: 9999;

  .header {
    display: flex;
    justify-content: flex-end;

    svg {
      color: ${props => props.theme.palette.dashboard.textLight};
    }
  }

  .main-content {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.gray100};
    margin: 30px 0 20px;
  }

  .collapse-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    width: calc(100% - 20px);
    left: 10px;
  }
`

const PlayButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #7fcaff;
  cursor: pointer;

  svg {
    color: white;
    font-size: 32px;
  }
`

export { Container, PlayButton }