import React from 'react'

import Card from './Card'
import ManualTimeImg from '../../../../assets/images/dashboard/manual-time.png'

const ManualTime = (props) => {
  const { index } = props
  return (
    <Card
      title='Manual Time'
      index={index}
    >
      <div className='card-body'>
        <img src={ManualTimeImg} alt='Recent Activity' />
        <p>No manual time entries for this week yet.</p>
      </div>
    </Card>
  )
}

export default ManualTime