import styled from 'styled-components'

const Container = styled.section`
  .container {
    padding-top: 130px;

    .title {
      text-align: center;

      span {
        font-size: 16px;
        color: ${(props) => props.theme.colors.blue};
      }

      h1 {
        font-size: 45px;
        line-height: 1;
        margin: 20px 0 30px;
      }
    }

    ${(props) => props.theme.breakpoints.down('xs')} {
      padding-top: 50px;

      .title {
        span {
          font-size: 13px;
        }

        h1 {
          font-size: 35px;
        }

      }
    }

    .help-way-list {

      .help-way-item {
        display: flex;
        padding: 90px 0;

        &.row-reverse {
          flex-direction: row-reverse;
        }

        .content {
          max-width: 465px;

          .icon {
            width: 50px;
            height: 50px;
          }

          h1 {
            font-size: 45px;
            margin-top: 20px;
            margin-bottom: 25px;
            line-height: 1;
          }

          h4 {
            font-size: 20px;
            margin-bottom: 15px;
          }

          > p {
            font-size: 18px;
            margin-bottom: 25px;
            color: ${(props) => props.theme.colors.gray};
          }

          .divider {
            height: 1px;
            width: 100%;
            background-color: ${props => props.theme.colors.gray100};
            margin: 25px 0;
          }

          a {
            display: inline-flex;
            align-items: center;
            font: 16px "Proxima Nova Bold";
            font-weight: 500;
            color: ${(props) => props.theme.colors.blue};

            svg {
              margin-left: 10px;
              transition: all ease 0.3s;
            }

            &:hover {
              color: ${(props) => props.theme.colors.dark};

              svg {
                color: ${(props) => props.theme.colors.blue};
                margin-left: 20px;
              }
            }
          }

          ${(props) => props.theme.breakpoints.down('xs')} {
            h1 {
              font-size: 35px;
            }

            > p {
              font-size: 16px;
            }
          }
        }

        .img-container {
          position: relative;
          flex: 1;
          margin-left: 120px;

          .brand-image {
            width: 500px;
            height: 650px;
            background-size: cover !important;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }
        }

        &.productivity {
          .chart-container {
            position: absolute;
            bottom: -20px;
            left: -100px;

            ${(props) => props.theme.breakpoints.down('sm')} {
              bottom: 20px;
              left: 0;
              width: 100%;
            }
          }
        }

        &.team-management {
          .message-container {
            position: absolute;
            top: 50px;
            left: 100px;

            ${(props) => props.theme.breakpoints.down(500)} {
              left: 50px;
            }
          }
        }

        &.find-and-fix {
          .chart-container {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;

            ${(props) => props.theme.breakpoints.down('sm')} {
              position: relative;
            }
          }
        }

        &.industry {
          flex-direction: row-reverse;

          .img-container {
            margin-left: 0 !important;

            .brand-image {
              height: 900px;
              width: 700px;

              ${(props) => props.theme.breakpoints.down('md')} {
                width: 100%;
                height: 700px;
                margin-top: 50px;
              }

              ${(props) => props.theme.breakpoints.down(550)} {
                height: 550px;
              }
            }
          }

          .link {
            padding-left: 20px;
            margin-top: 30px;
            text-align: left;
          }
        }

        ${(props) => props.theme.breakpoints.down('md')} {
          .content {
            max-width: 400px;
          }

          .img-container {
            margin-left: 50px;
          }
        }

        ${(props) => props.theme.breakpoints.down('sm')} {
          flex-direction: column !important;
          padding: 40px 0;
          max-width: 500px;
          margin: 0 auto;

          .content {
            width: 100%;
            text-align: center;
            max-width: unset;
          }

          .img-container {
            margin-top: 50px;
            width: 100%;
            margin-left: 0;

            .brand-image {
              width: 100%;
              height: 520px;
              margin: 0 auto;
              border-radius: 20px;
            }
          }
        }

        ${(props) => props.theme.breakpoints.down(550)} {
          .brand-image {
            min-height: 500px !important;
            max-width: 400px !important;
            background-position: 50% 50%;

            &.management {
              height: 600px !important;
            }
          }
        }
      }
    }
  }
`

const IndustryMenu = styled.div`

  .menu-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 28px 17px;
    cursor: pointer;
    border: 1px solid transparent;
    border-bottom: 1px solid ${props => props.theme.colors.gray100};
    transition: all ease 0.3s;
    border-radius: 3px;
    text-align: left !important;

    &:hover {
      border-color: ${(props) => props.theme.colors.blue};
      box-shadow: 0 0 10px 5px rgba(47,83,151,0.1);
    }

    .menu-icon {
      margin-right: 12px;
    }

    .label {
      flex: 1;
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      color: ${(props) => props.theme.colors.dark};
    }

    svg {
      color: ${(props) => props.theme.colors.blue};
    }
  }
`

export { Container, IndustryMenu }
