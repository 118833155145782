import React from 'react'
import OwlCarousel from 'react-owl-carousel3'

import * as S from './styles'

interface ICarouselData {
  id: number,
  title: string
  speech: string
  name: string
}

interface Props {
  data: ICarouselData[]
}

const options = {
  center: true,
  loop: true,
  nav: false,
  dots: true,
  autoplayHoverPause: true,
  autoplay: false,
  slideBy: 1,
  margin: 20,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    992: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};

const CustomerSayCarousel = ({data}: Props) => {
  return (
    <S.Container>
      <OwlCarousel
        className='owl-carousel owl-theme'
        {...options}
      >
        {
          data.map((item) => (
            <S.CarouselItem key={item.id}>
              <h4 className='title'>{item.title}</h4>
              <p className='speech'>{item.speech}</p>
              <span className='name'>{item.name}</span>
            </S.CarouselItem>
          ))
        }
      </OwlCarousel>
    </S.Container>
  )
}

export default CustomerSayCarousel
