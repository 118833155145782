import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Button } from '../../Common/Button'

const Container = styled.div`
  .footer-top {
    display: flex;
    background-color: ${(props) => props.theme.colors.blue500};
    padding: 100px 0;

    .container {
      display: flex;

      .left-menu {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 30px;

        .menu-group {
          display: flex;
          flex-direction: column;

          h4 {
            color: white;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 30px;
            cursor: pointer;
          }
        }

        ${(props) => props.theme.breakpoints.down('sm')} {
          grid-template-columns: 1fr 1fr;
          grid-row-gap: 40px;
        }

        ${(props) => props.theme.breakpoints.down('xs')} {
          grid-template-columns: 1fr;
          grid-row-gap: 40px;
        }
      }

      .right {
        width: fit-content;
        margin-right: 30px;

        h2 {
          color: white;
          margin-bottom: 30px;
          line-height: 1;
        }

        h3 {
          color: white;
          font-size: 16px;
          margin-bottom: 35px;
        }

        .social-links {
          a {
            margin-right: 20px;
          }
        }
      }

      ${(props) => props.theme.breakpoints.down('xs')} {
        flex-direction: column;
        max-width: 300px;

        .right {
          margin-top: 40px;
        }
      }

      ${(props) => props.theme.breakpoints.down(500)} {
        max-width: 90%;

        .right {
          margin-right: 0;
          width: 100%;

          button {
            width: 100%;
          }

          .social-links {
            display: flex;
            justify-content: space-between;

            a {
              margin: 0;
            }
          }
        }
      }
    }

    ${(props) => props.theme.breakpoints.down('xs')} {
      padding: 50px 0;
    }
  }

  .footer-bottom {
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors.blue600};
    height: 120px;

    .container {
      display: flex;

      .copyright {
        display: flex;
        align-items: center;
        color: ${props => props.theme.colors.gray100};
        margin-right: 10px;
      }

      ${(props) => props.theme.breakpoints.down('xs')} {
        flex-direction: column;
        max-width: 300px;

        > div {
          margin-top: 30px;
          margin-left: 0;
          padding: 0;

          &:last-child {
            border-left: none;
            justify-content: space-between;

            > div {
              margin: 0;
            }
          }
        }
      }

      ${(props) => props.theme.breakpoints.down(500)} {
        max-width: 90%;
      }
    }

    ${(props) => props.theme.breakpoints.down('xs')} {
      height: unset;
      padding: 30px 0;
    }
  }
`

const MenuLink = styled(Link)`
  width: fit-content;
  font-size: 14px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.white};

  &:hover {
    color: ${(props) => props.theme.colors.blue200};
  }
`

const SignUpButton = styled(Button)`
  width: 145px;
`

const Logo = styled.img`
  width: 120px;
  height: 25px;
`

export { Container, MenuLink, SignUpButton, Logo }
