import React from 'react'
import { Box } from '@material-ui/core'

import { AppPage, Tabs } from '../../../components/Dashboard/CommonStyled'
import Billing from './Billing'
import Integrations from './Integrations'
import Invoicing from './Invoicing'
import SettingPanel from './Settings'
import PaymentAccounts from './PaymentAccounts'

const { TabPane } = Tabs

const Settings = () => {
  return (
    <AppPage>
      <h1>Member settings</h1>
      <Box mt={3}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="BILLING" key="1">
            <Billing />
          </TabPane>
          <TabPane tab="PAYMENT ACCOUNTS" key="2">
            <PaymentAccounts />
          </TabPane>
          <TabPane tab="INTEGRATIONS" key="3">
            <Integrations />
          </TabPane>
          <TabPane tab="INVOICING" key="4">
            <Invoicing />
          </TabPane>
          <TabPane tab="SETTINGS" key="5">
            <SettingPanel />
          </TabPane>
        </Tabs>
      </Box>
    </AppPage>
  )
}

export default Settings