import React from 'react'

import Card from './Card'
import PaymentImg from '../../../../assets/images/dashboard/payments.png'

const Payments = (props) => {
  const { index } = props
  return (
    <Card
      title='Payments'
      index={index}
    >
      <div className='card-body'>
        <img src={PaymentImg} alt='Payments' />
        <p>No recent payments.</p>
      </div>
    </Card>
  )
}

export default Payments